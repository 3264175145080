import React, { useEffect, useMemo, useState } from "react";
import { Button, FormControl, FormGroup, InputGroup, Modal } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { RequestType, apiRequest, deleteTug } from "../libs/databaseAccess";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { useAppContext } from "../libs/contextLib";
import "./EditModal.css"


export default function EditTugModal({showModal, closeModal, tug, refreshTugList}) {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const {club} = useAppContext()

  const [tugID, setTugID] = useState("");
  const [tugType, setTugType] = useState("");
  const [tugPlaces, setTugPlaces] = useState(0);
  const [tugStatus, setTugStatus] = useState("");
  const [tugRegistration, setTugRegistration] = useState("");
  const [isDeleting, setIsDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const modalTitle = useMemo(() => {
    if(!tug) return "Edit Tug";
    if(tug.SK === "new") return "Create New Tug"
    return `Edit ${tug.SK}`
  }, [tug])

  const confirmDelete = async () => {
    const result = await deleteTug(club, tugID);

    if(result.statusCode !== 200) {
      await refreshTugList()
      sendToast({
        status: NotificationStatus.Success,
        message: "Tug deleted successfully"
      })
      closeModal()
    } else {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not delete tug"
      })
    }

    setIsDeleting(false); 
  }

  async function deleteRecord() {
    setIsDeleting(true);     // Start button spinner
    sendConfirmModal({
      heading: "Delete Tug?",
      body: "Last chance - Are you sure you want to delete this record?",
      confirmText: "Delete",
      rejectText: "Cancel",
      confirmColor: "danger",
      confirm: confirmDelete,
      reject: () => setIsDeleting(false)
    })
  }

  async function updateTugRecord() {
    setIsLoading(true);
    // Build up body for API Call
    let data = {}
    if (tugPlaces !== tug.places)  {
      data.places = tugPlaces;
    }
    if (tugStatus !== tug.status) {
      data.status = tugStatus;
    }
    if (tugType !== tug.aircraftType) {
      data.aircraftType = tugType;
    }
    const body = {
      club: club,
      type: "TUG",
      id: (tugID !== "new" ? tugID : tugRegistration),
      data: data,
    };

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {"body": body}}
    )
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not update tug"
      })
    } else {
      await refreshTugList()
      sendToast({
        status: NotificationStatus.Success,
        message: tugID !== "new" ? "Tug updated successfully" : "Tug created successfully"
      })
      closeModal()
    }
  }

  useEffect(() => {
    if(!tug || !tug.SK) return;

    setTugID(tug.SK)
    if (tug.SK !== "new") {
      setTugType(tug.aircraftType);
      setTugPlaces(tug.places);
      setTugStatus(tug.status);
    } else {
      setTugType("");
      setTugPlaces(1);
      setTugStatus("Not ready");
    }

    setIsLoading(false);
    setIsDeleting(false);
  }, [tug])

  const tugHasChanged = useMemo(() => {
    return (tugPlaces !== tug.places) ||
            (tugStatus !== tug.status) ||
            (tugType !== tug.aircraftType)
  }, [ tugPlaces, tugStatus, tugType, tug]);


  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormGroup className="d-flex flex-column gap-3" controlId="tugInfo" size="lg">
          { tugID === "new" && 
            <>
            <span className="fs-5 fw-semibold"><b>WARNING:</b> Registration cannot be changed once entered</span>
            <InputGroup>
            <InputGroup.Text>Registration</InputGroup.Text>
            <FormControl
              type="text"
              value={tugRegistration}
              onChange={e => setTugRegistration(e.target.value)}
            />
            </InputGroup>
            </>
          }
          <InputGroup>
            <InputGroup.Text>Aircraft type: </InputGroup.Text>
            <FormControl
              type="text"
              value={tugType}
              onChange={e => setTugType(e.target.value)}
            />
          </InputGroup>
          <FormGroup onChange={(e) => setTugPlaces(parseFloat(e.target.value))}>
            <div className="d-flex gap-2">
              <span>Seats:</span> 
              <input type="radio" id="places1" value="1" name="tugSeatGroup" onChange={setTugPlaces} checked={tugPlaces===1} />
              <label htmlFor="places1">1</label>
              <input type="radio" id="places2" value="2" name="tugSeatGroup" onChange={setTugPlaces} checked={tugPlaces===2} />
              <label htmlFor="places2">2</label>
              <input type="radio" id="places3" value="3" name="tugSeatGroup" onChange={setTugPlaces} checked={tugPlaces===3} />
              <label htmlFor="places3">3</label>
              <input type="radio" id="places4" value="4" name="tugSeatGroup" onChange={setTugPlaces} checked={tugPlaces===4} />
              <label htmlFor="places4">4</label>
            </div>
          </FormGroup>
          <span className="fs-5 fw-semibold">Current status: {tug.status}</span>
          <FormGroup className="d-flex gap-2" onChange={(e) => setTugStatus(e.target.value)}>
            <span>Reset to:</span>
            <input type="radio" name="statusGroup" id="statusNotReady" onChange={setTugStatus} checked={tugStatus==="Not ready"} value="Not ready"/>
            <label htmlFor="statusNotReady">Not ready</label>
            <input type="radio" name="statusGroup" id="statusReady" onChange={setTugStatus} checked={tugStatus==="Ready"} value="Ready"/>
            <label htmlFor="statusReady">Ready</label>
          </FormGroup>
        </FormGroup> 
        {tugHasChanged && 
          <>
            <hr></hr>
            <div className="fs-5 mb-2 fw-semibold">Confirm the following changes to tug {tugID}</div>
            <ol className="mb-0">
              {(tugPlaces !== tug.places) && <li>Changed number of places to {tugPlaces}</li>}
              {(tugStatus !== tug.status) && <li>Status changed to {tugStatus}</li>}
              {(tugType !== tug.aircraftType) && <li>Aircraft type changed to {tugType}</li>}
            </ol>
          </>
        }
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-row justify-content-between w-100">
          <div>
            { (tugID !== "new") &&
              <LoaderButton type="button" variant="danger" isLoading={isDeleting} onClick={deleteRecord}>
                Delete Tug
              </LoaderButton>
            }
          </div>
          <div className="d-flex flex-row gap-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <LoaderButton type="button" variant="primary" isLoading={isLoading} onClick={updateTugRecord}>
              Save Changes
            </LoaderButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}