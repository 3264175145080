import React, { useState} from "react";
import { signUp, signIn, resendSignUpCode, confirmSignUp, getCurrentUser } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { FormText, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import { RequestType, apiRequest } from "../libs/databaseAccess";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    club: "None",                 // Cannot retrieve club names until after an account is created
    userID: "",
    email: "",
    password: "",
    first: "",
    last: "",
    isInstructor: false,
    isTowPilot: false,
    isAdmin: false,
    confirmPassword: "",
    confirmationCode: "",
  });
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState(true);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.first.length > 0 &&
      fields.last.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  function createBody() {
    const userInfo = {...fields}; // Make an independent copy
    delete userInfo.password;
    delete userInfo.confirmPassword;
    delete userInfo.email;
    delete userInfo.confirmationCode; // Remove the stuff we don't want to save to DynamoDB
    return userInfo
  }

  function createPilot() {
    const bodyContent = createBody();
    return apiRequest(RequestType.POST, {apiName: "flightline", path: "/pilot", options: {
      "body": bodyContent
    }});
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await signUp({
        username: fields.email,
        password: fields.password,
        userAttributes: {
          'custom:club': fields.club
        },
      });
      setIsLoading(false);
      setNewUser(false);
    } catch (e) {
      if (e.name === 'UsernameExistsException'){
        await resendSignUpCode({username: fields.email});
        setNewUser(false);
      } else{
        onError(e);
      }
      setIsLoading(false);
    }
  }
  
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await confirmSignUp({username: fields.email, confirmationCode: fields.confirmationCode});
      await signIn({username: fields.email, password: fields.password});
      const userInfo = await getCurrentUser();
      fields.userID = userInfo.username
      await createPilot();

      userHasAuthenticated(true);
      navigate("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form className="d-flex flex-column gap-3" onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" size="lg">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <FormText>Please check your email for the code.</FormText>
        </FormGroup>
        <LoaderButton
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  }

  function renderForm() {
    return (
      <>
      <h2 className = "centerHeader mb-3">Signup form</h2>
      <form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
        <FormGroup controlId="email" size="lg">
          <FormLabel>Email - This will be your sign-in name</FormLabel>
            <FormControl
              autoFocus
              required
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
        </FormGroup>
        <FormGroup controlId="first" size="lg">
          <FormLabel>First Name</FormLabel>
          <FormControl
            required
            type="text"
            placeholder="First name"
            value={fields.first}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="last" size="lg">
          <FormLabel>Last Name</FormLabel>
          <FormControl
            required
            type="text"
            placeholder="Last name"
            value={fields.last}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" size="lg">
          <FormLabel>Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
            autoComplete="on"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" size="lg">
          <FormLabel>Confirm Password</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
            autoComplete="on"
          />
        </FormGroup>
        <LoaderButton
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
        <p>You will receive an email shortly with a confirmation code</p>
      </form>
      </>
    );
  }

  return (
    <div className="Signup">
      {newUser ? renderForm() : renderConfirmationForm()}

    </div>
  );
}