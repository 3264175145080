
import React, { useState, useEffect} from "react";
import "./Accounting.css";
import { Form, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./Accounting.css";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import {loadPilotTransactions } from "../libs/databaseAccess";
import accountsMemberSchema from "./accounts member schema.json"
import Table from "../components/Table"
import { DateTime } from "luxon";


export default function UserAccount() {
  const {userID, club, isAuthenticated, clubTimeZone } = useAppContext();
  const [startDate, setStartDate] = useState("2000-01-01");
  const [endDate, setEndDate] = useState("2000-01-01");
  const [pilotTransactions, setPilotTransactions] = useState([]);

  useEffect(() => {
    if (!isAuthenticated || club === "" || userID === "") {
      return;
    }

    async function onLoad() {
      try {
        const dateStr = DateTime.fromObject({}, {zone: clubTimeZone}).toISODate()
        // Initialize search to the current date
        setStartDate(dateStr);
        setEndDate(dateStr);
      } catch (e) {
        onError(e);        
      }
    }
    onLoad();
  }, [club, isAuthenticated, clubTimeZone, userID]);
  
  useEffect(() => {
    if ((startDate === "2000-01-01") || (endDate === "2000-01-01")) {
      // If still starting up, exit
      return;
    }

    async function onDateChange() {
      // Convert local time to zulu time
      const startDateVal = DateTime.fromISO(startDate).startOf("day").toMillis();
      const endDateVal = DateTime.fromISO(endDate).endOf("day").toMillis();
      const transactionRecords = await loadPilotTransactions(club, userID, startDateVal, endDateVal);

      const transactions = transactionRecords.map((transaction) => {
        const amount = transaction.amount || 0;
        const balance = transaction.balance || 0;
        const date = DateTime.fromMillis(transaction.date, {zone: clubTimeZone})

        return({
          "id": transaction.id,
          "date": `${date.toISODate()} ${date.toLocaleString(DateTime.TIME_SIMPLE)}`,
          "description": transaction.description,
          "amount": amount.toFixed(2),
          "balance": balance.toFixed(2),
        })
      });
      
      setPilotTransactions(transactions);
    }

    onDateChange();
  }, [club, userID, startDate, endDate, clubTimeZone]);

  function updateStart(date){
    setStartDate(date);     // Will force an update through useEffect     
    setPilotTransactions([]);
  }

  function updateEnd(date){
    setEndDate(date);
    setPilotTransactions([]);
  }

return (
  <>
  <div className="row">
    <Form>
      <div className="col-sm-4">
        <FormGroup controlId=" " className="pull-left" size="lg">
          <FormLabel>Start Date</FormLabel>
          <FormControl
            autoFocus
            type="date"
            value={startDate}
            disabled={startDate === "2000-01-01"}
            onChange={e => updateStart(e.target.value)}
          />
        </FormGroup>
      </div>
      <div className="col-sm-4">
        <h2> </h2>
      </div>
      <div className="col-sm-4">
        <FormGroup controlId=" " className="pull-right" size="lg">
          <FormLabel>End Date</FormLabel>
          <FormControl
            type="date"
            value={endDate}
            disabled={endDate === "2000-01-01"}
            onChange={e => updateEnd(e.target.value)}
          />
        </FormGroup>
      </div>
    </Form>
  </div>
  <hr></hr> 
  <div className="container p-2">
    <div className="row">
      <div className="col">
        <h1>Summary</h1>
        <Table  headers={Object.values(accountsMemberSchema)} items={Object.keys(accountsMemberSchema)} rows={pilotTransactions} />
      </div>
    </div>
  </div>
  </>
);

}