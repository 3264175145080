import React, { useState, useEffect, useCallback} from "react";
import { Form, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { loadDepositTransactions, loadTaxTransactions, loadTransactions, loadUserTransactions } from "../libs/databaseAccess";
import LoaderButton from "../components/LoaderButton";
import "./Accounting.css";
import AccountingSummaryTable, { AccountingTableTypes } from "../components/AccountingSummaryTable.js";
import { DateTime } from "luxon";
import { debounce } from "lodash"
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib.js";

export default function Logs() {
  const {userID, club, clubName, isAuthenticated, clubTimeZone } = useAppContext();
  const { sendAlert } = useNotificationContext();

  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState("2000-01-01");
  const [endDate, setEndDate] = useState("2000-01-01");
  const [title, setTitle] = useState("");
  const [summaries, setSummaries] = useState({})
  const [currentTransactions, setCurrentTransactions] = useState(null)
  const [transactionsCache, setTransactionsCache] = useState({})
  const [currentTable, setCurrentTable] = useState(AccountingTableTypes.Overview)
  const [feeList, setFeeList] = useState([])
  
  const [breakdowns, setBreakdowns] = useState({
    membersPayable: [],
    cashIn: [],
    introsPayable: [],
    taxesPayable: [],
    towRevenue: [],
    rentalRevenue: [],
    introRevenue: [],
    otherRevenue: []
  })
  const [transactions, setTransactions] = useState({
    membersPayable: {},
    cashIn: {},
    introsPayable: {},
    taxesPayable: {},
    towRevenue: {},
    rentalRevenue: {},
    introRevenue: {},
    otherRevenue: {}
  })

  const formatSectionDates = (section, timeZone, whereFormat) => {
    for(let i = 0; i < section[whereFormat].length; i++) {
      const date = DateTime.fromMillis(section[whereFormat][i].timestamp, {zone: timeZone})
      section[whereFormat][i].dateStr = `${date.toISODate()} ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
    }
  }

  const getRecords = useCallback(async () =>  {
    if ((startDate === "2000-01-01") || (endDate === "2000-01-01") || !club || !clubTimeZone) {
      // If still starting up, exit
      return;
    }

    // Pull club specific info from DynamoDB
    setIsLoading(true);
    const startDateVal = DateTime.fromISO(startDate, {zone: clubTimeZone}).startOf("day").toMillis()
    const endDateVal = DateTime.fromISO(endDate, {zone: clubTimeZone}).endOf("day").toMillis()
    const transactionResults = await loadTransactions(club, startDateVal, endDateVal);

    if(transactionResults.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not load accounting table"
      })
      setIsLoading(false)
      return
    }

    const {membersPayable, deposits, introsPayable, taxesPayable, towRevenue, rentalRevenue, introRevenue, otherRevenue, feeList} = transactionResults.body

    // Convert timestamps to club time string 

    formatSectionDates(introsPayable, clubTimeZone, "breakdown")
    formatSectionDates(taxesPayable, clubTimeZone, "transactions")
    formatSectionDates(towRevenue, clubTimeZone, "breakdown")
    formatSectionDates(rentalRevenue, clubTimeZone, "breakdown")
    formatSectionDates(introRevenue, clubTimeZone, "breakdown")
    formatSectionDates(otherRevenue, clubTimeZone, "breakdown")

    setBreakdowns({
      membersPayable: membersPayable.breakdown,
      cashIn: deposits.breakdown,
      introsPayable: introsPayable.breakdown,
      taxesPayable: taxesPayable.breakdown,
      towRevenue: towRevenue.breakdown,
      rentalRevenue: rentalRevenue.breakdown,
      introRevenue: introRevenue.breakdown,
      otherRevenue: otherRevenue.breakdown
    })
    setSummaries({
      membersPayable: membersPayable.summary,
      cashIn: deposits.summary,
      introsPayable: introsPayable.summary,
      taxesPayable: taxesPayable.summary,
      towRevenue: towRevenue.summary,
      rentalRevenue: rentalRevenue.summary,
      introRevenue: introRevenue.summary,
      otherRevenue: otherRevenue.summary
    })
    setFeeList(feeList)

    setIsLoading(false);
  }, [club, endDate, startDate, clubTimeZone, sendAlert]);

  useEffect(() => {
    if((currentTable !== AccountingTableTypes.MemberTransactions &&
      currentTable !== AccountingTableTypes.TaxTransactions &&
      currentTable !== AccountingTableTypes.DepositTransactions) ||
      !currentTransactions
    ) {
      return
    }

    const loadTransactions = async () => {
      setIsLoading(true);
      const {id, type} = currentTransactions;
      // Avoid making a network request if not necessary
      if(transactionsCache[type+id]) {
        setTransactions(t => {
          t[type] = transactionsCache[type+id]
          return t
        })
        setIsLoading(false)
        return
      }
      
      const startDateVal = DateTime.fromISO(startDate, {zone: clubTimeZone}).startOf("day").toMillis()
      const endDateVal = DateTime.fromISO(endDate, {zone: clubTimeZone}).endOf("day").toMillis()
      let response = {}
      if(type === "membersPayable") {
        response = await loadUserTransactions(club, id, startDateVal, endDateVal);
      } else if(type === "taxesPayable") {
        response = await loadTaxTransactions(club, id, startDateVal, endDateVal);
      } else if(type === "cashIn") {
        response = await loadDepositTransactions(club, id, startDateVal, endDateVal);
      }

      if(response.statusCode !== 200) {
        sendAlert({
          status: NotificationStatus.Danger,
          message: "Could not load transactions history"
        })
        setCurrentTable(
          type === "membersPayable" ?
          AccountingTableTypes.MembersPayable :
          type === "taxesPayable" ?
          AccountingTableTypes.TaxesPayable :
          AccountingTableTypes.CashIn
        )
        setIsLoading(false)
        return
      }

      const transactionsResult = response.body
  
      for(const i in transactionsResult.transactions) {
        const date = DateTime.fromMillis(transactionsResult.transactions[i].timestamp, {zone: clubTimeZone})
        transactionsResult.transactions[i].dateStr = `${date.toISODate()} ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
      }
  
      setTransactions(t => {
        t[type] = transactionsResult
        return t
      })
      setTransactionsCache((cache) => {
        cache[type+id] = transactionsResult
        return cache
      })
  
      setIsLoading(false);
    }

    loadTransactions()
  }, [transactionsCache, club, clubTimeZone, endDate, startDate, currentTable, currentTransactions, sendAlert])

  const getTransactions = (type, id) => {
    // Make sure we change both of these at the same time or else the previous transactions
    // will appear for a split second before the loading starts which is jarring as a user
    setIsLoading(true)
    setCurrentTransactions({type, id})
  }

  useEffect(() => {
    if (!isAuthenticated || club === "" || userID === "") {
      return;
    }

    try {
      const today = DateTime.fromObject({}, {zone: clubTimeZone})
      // Initialize search to the current date
      setStartDate(today.toISODate());
      setEndDate(today.toISODate());
      setTitle(clubName);
    } catch (e) {
      onError(e);        
    }

  }, [isAuthenticated, club, userID, clubName, clubTimeZone]);

  useEffect(() => {
    getRecords()
  }, [getRecords])


  // Debounce input to prevent multiple calls when user quickly changes months
  const updateStart = debounce((date) => {
    setStartDate(date);
    //Reset cache when changing date
    setTransactionsCache({})
  }, 500)
  
  // Debounce input to prevent multiple calls when user quickly changes months
  const updateEnd = debounce((date) => {
    setEndDate(date);
    //Reset cache when changing date
    setTransactionsCache({})
  }, 500)

  function updateRecords(){
    getRecords();
    setTransactionsCache({})
  }

  return (
    <main className="mt-4">
    <div className="row">
    <Form>
      <div className="d-flex justify-content-between">
        <div className="col-sm-4" >
        <FormGroup controlId="startDate" className="pull-left" size="lg">
              <FormLabel>Start Date</FormLabel>
              <FormControl
                autoFocus
                type="date"
                value={startDate}
                disabled={startDate === "2000-01-01"}
                onChange={e => updateStart(e.target.value)}
              />
        </FormGroup>
        </div>
        <div className="col-sm-4">
        <h2>{title} Summary</h2>
        </div>
        <div className="col-sm-4">
        <FormGroup controlId="endDate" className="pull-right" size="lg">
              <FormLabel>End Date</FormLabel>
              <FormControl
                type="date"
                value={endDate}
                disabled={endDate === "2000-01-01"}
                onChange={e => updateEnd(e.target.value)}
              />
        </FormGroup>
        </div>
      </div>
    </Form>
    </div>

    <LoaderButton type="submit" size="lg" isLoading={isLoading} onClick={() => updateRecords()}>
          Update
    </LoaderButton>

    <div className="container p-2">
      <div className="row">
        <div className="col">
            <AccountingSummaryTable
              currentTable={currentTable}
              setCurrentTable={setCurrentTable}
              summaries={summaries}
              breakdowns={breakdowns}
              transactions={transactions}
              getTransactions={getTransactions}
              isLoading={isLoading}
              feeList={feeList}
            />
        </div>
      </div>
    </div>
    </main>
  );
  }

