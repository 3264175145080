const AWSConfig = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://y4u7d0alh7.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_LrIQ4PQuE",
    APP_CLIENT_ID: "4eid8neiieegp9pfsejud3etb7",
    IDENTITY_POOL_ID: "us-east-1:77ec924d-aa56-413e-9f84-97039c8a2d84"
  }
};

export default AWSConfig;