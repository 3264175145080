import React, { useEffect, useMemo, useState } from "react";
import { Button, FormControl, FormGroup, InputGroup, Modal, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { RequestType, apiRequest, deleteGlider } from "../libs/databaseAccess";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { MultiSelect } from "react-multi-select-component";
import { useAppContext } from "../libs/contextLib";
import "./EditModal.css"
import { isEqual } from "lodash";
import { formatTaxOptions } from "../libs/utils";


export default function EditGliderModal({showModal, closeModal, glider, refreshGliderList}) {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const {club, userIsAdmin, clubTaxRates} = useAppContext()

  const [gliderID, setGliderID] = useState(0);
  const [gliderWeight, setGliderWeight] = useState(0);
  const [gliderPlaces, setGliderPlaces] = useState(0);
  const [gliderRate, setGliderRate] = useState(0);
  const [gliderLaunchSpeed, setGliderLaunchSpeed] = useState(0);
  const [gliderStatus, setGliderStatus] = useState("Not Ready");
  const [gliderRegistration, setGliderRegistration] = useState("");
  const [isDeleting, setIsDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [taxStatus, setTaxStatus] = useState("exempt")
  const [taxList, setTaxList] = useState([])

  const modalTitle = useMemo(() => {
    if(!glider) return "Edit Glider";
    if(glider.SK === "new") return "Create New Glider"
    return `Edit ${glider.SK}`
  }, [glider])

  const taxOptions = useMemo(() => {
    return formatTaxOptions(clubTaxRates)
  }, [clubTaxRates])
  
  const taxSelectRenderer = (selected) => {
    if(selected.length < 1) return "None";
    let str = "";
    for(const i in selected) {
      str += selected[i].value
      if(i < selected.length - 1) {
        str += ", "
      }
    }
    return str
  }

  const confirmDelete = async () => {
    const result = await deleteGlider(club, gliderID);

    if(result.statusCode !== 200) {
      await refreshGliderList()
      sendToast({
        status: NotificationStatus.Success,
        message: "Glider deleted successfully"
      })
      closeModal()
    } else {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not delete glider"
      })
    }

    setIsDeleting(false); 
  }

  async function deleteRecord() {
    setIsDeleting(true);     // Start button spinner
    sendConfirmModal({
      heading: "Delete Glider?",
      body: "Last chance - Are you sure you want to delete this record?",
      confirmText: "Delete",
      rejectText: "Cancel",
      confirmColor: "danger",
      confirm: confirmDelete,
      reject: () => setIsDeleting(false)
    })
  }

  async function updateGliderRecord() {
    setIsLoading(true);
    // Build up body for API Call
    let data = {}
    let parsedFloat = !isNaN(parseFloat(gliderWeight)) ? parseFloat(gliderWeight) : glider.weight; 
    if (parsedFloat !== glider.weight){
      data.weight = parsedFloat;
    }
    if (gliderPlaces !== glider.places)  {
      data.places = gliderPlaces;
    }
    parsedFloat = !isNaN(parseFloat(gliderRate)) ? parseFloat(gliderRate) : glider.rate; 
    if (parsedFloat !== glider.rate) {
      data.rate = parsedFloat;
    }
    parsedFloat = !isNaN(parseFloat(gliderLaunchSpeed)) ? parseFloat(gliderLaunchSpeed) : glider.launchSpeed; 
    if (parsedFloat !== glider.launchSpeed) {
      data.launchSpeed = parsedFloat;
    }
    if (gliderStatus !== glider.status) {
      data.status = gliderStatus;
    }
    if(taxStatus !== glider.taxStatus) {
      data.taxStatus = taxStatus
    }
    const taxRates = taxList.map((e) => e.value)
    if(!isEqual(taxRates, glider.taxRates)) {
      data.taxRates = taxRates
    }
    const body = {
      club: club,
      type: "GLIDER",
      id: (gliderID !== "new" ? gliderID : gliderRegistration),
      data: data,
    };

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {"body": body}}
    )
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not update glider"
      })
    } else {
      await refreshGliderList()
      sendToast({
        status: NotificationStatus.Success,
        message: gliderID !== "new" ? "Glider updated successfully" : "Glider created successfully"
      })
      closeModal()
    }
  }

  useEffect(() => {
    if(!glider || !glider.SK) return;

    setGliderID(glider.SK)
    if (glider.SK !== "new") {
      setGliderWeight(glider.weight);
      setGliderPlaces(glider.places);
      setGliderRate(("rate" in glider) ? glider.rate : 0);
      setGliderLaunchSpeed(("launchSpeed" in glider) ? glider.launchSpeed : 0);
      setGliderStatus(glider.status);
      setTaxStatus(glider.taxStatus ?? "exempt");
      const taxRates = glider.taxRates ?? []
      setTaxList(taxRates.map((r) => 
        taxOptions.find((o) => o.value === r) ?? {label: "Unknown", value: r}
      ))
    } else {
      setGliderWeight(0);
      setGliderPlaces(1);
      setGliderRate(0);
      setGliderLaunchSpeed(0);
      setGliderStatus("Not ready");
      setTaxStatus("exempt");
      setGliderRegistration("");
    }

    setIsLoading(false);
    setIsDeleting(false);
  }, [glider, taxOptions])


  const taxStatusChanged = useMemo(() => taxStatus !== glider.taxStatus, [taxStatus, glider])
  const taxRatesChanged = useMemo(() => {
    const taxRates = taxList.map((e) => e.value)
    const gliderTaxRates = glider.taxRates ?? []
    return !isEqual(taxRates, gliderTaxRates)
  }, [taxList, glider])

  const gliderHasChanged = useMemo(() => {
    const parsedWeight = !isNaN(parseFloat(gliderWeight)) ? parseFloat(gliderWeight) : glider.weight;
    const rate = !isNaN(parseFloat(gliderRate)) ? parseFloat(gliderRate) : glider.rate;
    const speed = !isNaN(parseFloat(gliderLaunchSpeed)) ? parseFloat(gliderLaunchSpeed) : glider.launchSpeed;
    return (parsedWeight !== glider.weight) ||
                  (gliderPlaces !== glider.places) ||
                  (rate !== glider.rate) ||
                  (speed !== glider.launchSpeed) ||
                  (gliderStatus !== glider.status) ||
                  taxStatusChanged || taxRatesChanged;
  }, [gliderWeight, gliderPlaces, gliderRate, gliderLaunchSpeed, gliderStatus, glider, taxStatusChanged, taxRatesChanged]);

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormGroup className="d-flex flex-column gap-3" controlId="gliderInfo" size="lg">
          { gliderID === "new" && 
            <>
            <span className="fs-5"><b>WARNING:</b> Registration cannot be changed once entered</span>
            <InputGroup >
            <InputGroup.Text>Registration</InputGroup.Text>
            <FormControl
              type="text"
              value={gliderRegistration}
              onChange={e => setGliderRegistration(e.target.value)}
            />
            </InputGroup>
            </>
          }
          { (gliderID === "new" || userIsAdmin) &&
            <>
            {/*<InputGroup >
              <InputGroup.Text>Weight</InputGroup.Text>
              <FormControl
                type="number"
                inputMode="decimal"
                as="input"
                className="text-end"
                value={gliderWeight}
                onChange={e => setGliderWeight(e.target.value)}
              />
              <InputGroup.Text>kg</InputGroup.Text>
        </InputGroup>*/}
            <InputGroup >
              <InputGroup.Text>Launch Speed</InputGroup.Text>
              <FormControl
                type="number"
                inputMode="decimal"
                as="input"
                className="text-end"
                value={gliderLaunchSpeed}
                onChange={e => setGliderLaunchSpeed(e.target.value)}
              />
              <InputGroup.Text>knots</InputGroup.Text>
            </InputGroup>
            <InputGroup >
              <InputGroup.Text>Rate $</InputGroup.Text>
              <FormControl
                type="number"
                inputMode="decimal"
                as="input"
                className="text-end"
                value={gliderRate}
                onChange={e => setGliderRate(e.target.value)}
              />
              <InputGroup.Text>/minute</InputGroup.Text>
            </InputGroup>
            <ToggleButtonGroup  name="taxStatusCheck" className="flex-wrap" type="radio" value={taxStatus} onChange={(e) => setTaxStatus(e)}>
              <InputGroup.Text>Tax Status:</InputGroup.Text>
              <ToggleButton id="exempt" variant="outline-primary" name="taxStatusCheck" value="exempt">Tax Exempt</ToggleButton>
              <ToggleButton id="inclusive" variant="outline-primary" name="taxStatusCheck" value="inclusive">Tax Inclusive</ToggleButton>
              <ToggleButton id="exclusive" variant="outline-primary" name="taxStatusCheck" value="exclusive">Tax Exclusive</ToggleButton>
            </ToggleButtonGroup>
            {taxStatus !== "exempt" &&
              <InputGroup>
                <InputGroup.Text>Applicable Taxes:</InputGroup.Text>
                <MultiSelect
                  className="flex-grow-1 flex-shrink-1 multi-select"
                  disableSearch
                  hasSelectAll={false}
                  options={taxOptions}
                  value={taxList}
                  onChange={setTaxList}
                  valueRenderer={taxSelectRenderer}
                />
              </InputGroup>
            }
            <FormGroup onChange={(e) => setGliderPlaces(parseFloat(e.target.value))} >
              <div className="d-flex flex-row gap-2">
                <span>Seats:</span> 
                <input type="radio" id="places1" value="1" name="number" onChange={setGliderPlaces} checked={gliderPlaces===1} />
                <label htmlFor="places1">1</label>
                <input type="radio" id="places2" value="2" name="number" onChange={setGliderPlaces} checked={gliderPlaces===2} />
                <label htmlFor="places2">2</label>
                <input type="radio" id="places3" value="3" name="number" onChange={setGliderPlaces} checked={gliderPlaces===3} />
                <label htmlFor="places3">3</label>
                <input type="radio" id="places4" value="4" name="number" onChange={setGliderPlaces} checked={gliderPlaces===4} />
                <label htmlFor="places4">4</label>
              </div>
            </FormGroup>
            </>
          }
          <span className="fs-4 fw-semibold">Current status: {glider.status}</span>
          <FormGroup onChange={(e) => setGliderStatus(e.target.value)}>
            <div className="d-flex flex-row gap-2">
              <span>Reset to:</span>
              <input type="radio" name="ready" id="statusNotReady" onChange={setGliderStatus} checked={gliderStatus==="Not ready"} value="Not ready"/>
              <label htmlFor="statusNotReady">Not ready</label>
              <input type="radio" name="ready" id="statusReady" onChange={setGliderStatus} checked={gliderStatus==="Ready"} value="Ready"/>
              <label htmlFor="statusReady">Ready</label>
            </div>
          </FormGroup>
        </FormGroup> 
        {gliderHasChanged && 
          <>
            <hr></hr>
            <div className="fs-5 mb-2 fw-semibold">Confirm the following changes to glider: {gliderID}</div>
            <ol className="mb-0">
              {((!isNaN(parseFloat(gliderWeight)) ? parseFloat(gliderWeight) : glider.weight) !== glider.weight) && 
                <li>Weight changed to {gliderWeight}</li>}
              {((!isNaN(parseFloat(gliderRate)) ? parseFloat(gliderRate) : glider.rate) !== glider.rate) && 
                <li>Rate changed to ${gliderRate}/minute</li>}
              {((!isNaN(parseFloat(gliderLaunchSpeed)) ? parseFloat(gliderLaunchSpeed) : glider.launchSpeed) !== glider.launchSpeed) && 
                <li>Launch speed changed to {gliderLaunchSpeed} knots</li>}
              {(gliderPlaces !== glider.places) && <li>Changed number of places to {gliderPlaces}</li>}
              {(gliderStatus !== glider.status) && <li>Status changed to {gliderStatus}</li>}
              {(taxStatusChanged) && <li>Tax status changed to {taxStatus}</li>}
              {(taxRatesChanged) && <li>Applicable taxes changed to {taxSelectRenderer(taxList)}</li>}
            </ol>
          </>
        }
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-row justify-content-between w-100">
          <div>
            { (gliderID !== "new") &&
              <LoaderButton type="button" variant="danger" isLoading={isDeleting} onClick={deleteRecord}>
                Delete Glider
              </LoaderButton>
            }
          </div>
          <div className="d-flex flex-row gap-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <LoaderButton type="button" variant="primary" isLoading={isLoading} onClick={updateGliderRecord}>
              Save Changes
            </LoaderButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}