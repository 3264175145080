import React, { useState } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { signIn } from "aws-amplify/auth";
import { Link } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Login.css";

// TODO: Assigning a club to a user (Cognito) is current but:
//      Currently the user is authenticated across the app
//      Assigning a club to the user is fine until a user wants to fly at two clubs. 
//      Some way is needed to allow specific club signin that is persistent 
//      This might have to be handled by serving specific pages per club, rather than associating a user with a club
//      Unless the user can be unique per club (username, password, club)
//      Currently not working well with a mix of user defined club and addressed defined club

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await signIn({username: fields.email, password: fields.password});
      userHasAuthenticated(true);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <main className="Login">
      <form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
        <FormGroup controlId="email" size="lg">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" size="lg">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={fields.password}
            onChange={handleFieldChange}
            type="password"
            autoComplete="on"
          />
        </FormGroup>
        <Link className="mb-0" to="/login/reset">Forgot password?</Link>
        <LoaderButton
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </main>
  );
}