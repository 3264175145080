import React, {useState, useEffect} from "react";
import { ListGroupItem, FormControl, InputGroup, FormCheck } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { returnPilot, loadPilots, RequestType, apiRequest } from "../libs/databaseAccess";
import { LinkContainer } from "react-router-bootstrap";

export default function Profile() {
    const { userID, club, isAuthenticated } = useAppContext();
    const [pilot, setPilot] = useState({});
    const [emContact, setEmContact] = useState("");
    const [emPhone, setEmPhone] = useState("");
    const [emRelation, setEmRelation] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [emNote, setEmNote] = useState("");
    const [emLink, setEmLink] = useState("");
    const [address, setAddress] = useState("");
    const [shareEmail, setShareEmail] = useState(false);
    const [sharePhone, setSharePhone] = useState(false);
    const [shareAddress, setShareAddress] = useState(false);

    useEffect(() => {
        if (!isAuthenticated || userID === "" || club === "") {
            return;
        }
        async function onLoad() {
            try {
                const pilots = await loadPilots(club);
                const pilot = returnPilot(club, userID, pilots);
                setPilot(pilot);
                setEmContact(pilot.emContact || "");
                setEmPhone(pilot.emPhone || "");
                setEmRelation(pilot.emRelation || "");
                setPhone(pilot.phone || "");
                setEmail(pilot.email || "");
                setAddress(pilot.address || "");
                setEmNote(pilot.emNote || "");
                setEmLink(pilot.emLink || "");
                setShareEmail(pilot.shareEmail || false);
                setSharePhone(pilot.sharePhone || false);
                setShareAddress(pilot.shareAddress || false);
            } catch (e) {
                onError(e);        
            }
          }
      
        onLoad();
    }, [isAuthenticated, club, userID]);

   function updatePilot(){
        const data = {
            emContact: emContact,
            emPhone: emPhone,
            emRelation: emRelation,
            phone: phone,
            email: email,
            address: address,
            emNote: emNote,
            emLink: emLink,
            shareEmail: shareEmail,
            sharePhone: sharePhone,
            shareAddress: shareAddress,
        };
        const body = {
            club: club,
            type: "PILOT",
            id: pilot.SK,
            data: data,
          };
        apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-item/", options: {
            "body": body
        }});
    }

    return(
        <>
        <h1>Profile for {pilot.first} {pilot.last}</h1>
        <h2 style={{textAlign: 'left'}}>Emergency Tracking (Spot, Skylines, etc)</h2>
        <InputGroup>
            <InputGroup.Text>Online tracking link</InputGroup.Text>
            <FormControl
                type="link"
                value={emLink}
                onChange={e => setEmLink(e.target.value)}
              />
        </InputGroup>
        <InputGroup>
            <InputGroup.Text>Tracking Instructions</InputGroup.Text>
            <FormControl
                type="text"
                value={emNote}
                onChange={e => setEmNote(e.target.value)}
              />
        </InputGroup>
        <h2 style={{textAlign: 'left'}}>Emergency Contact</h2>
        <InputGroup>
            <InputGroup.Text>Name</InputGroup.Text>
            <FormControl
                type="text"
                value={emContact}
                onChange={e => setEmContact(e.target.value)}
              />
        </InputGroup>
        <InputGroup>
            <InputGroup.Text>Phone Number</InputGroup.Text>
            <FormControl
                type="text"
                value={emPhone}
                onChange={e => setEmPhone(e.target.value)}
              />
        </InputGroup>
        <InputGroup>
            <InputGroup.Text>Relationship</InputGroup.Text>
            <FormControl
                type="text"
                value={emRelation}
                onChange={e => setEmRelation(e.target.value)}
              />
        </InputGroup>
        <div>
            <div style={{display:'inline-block', textAlign: 'left', width:'70%', maxWidth:'70%'}}><h2>Personal Contact Info</h2></div>
            <div style={{display:'inline-block', textAlign: 'right', width:'30%', maxWidth:'30%'}}><h5>Click to share in member directory</h5></div>
        </div>
        <InputGroup>
            <InputGroup.Text>Phone Number</InputGroup.Text>
            <FormControl
                type="text"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            <InputGroup.Text><FormCheck type="checkbox" aria-label="..." checked={sharePhone} onChange = {() => setSharePhone(!sharePhone)}/></InputGroup.Text>
        </InputGroup>
        <InputGroup>
            <InputGroup.Text>Email Address</InputGroup.Text>
            <FormControl
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            <InputGroup.Text><FormCheck type="checkbox" aria-label="..." checked={shareEmail} onChange = {() => setShareEmail(!shareEmail)}/></InputGroup.Text>
        </InputGroup>
        <InputGroup>
            <InputGroup.Text>Mailing Address</InputGroup.Text>
            <FormControl
                type="text"
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            <InputGroup.Text><FormCheck type="checkbox" aria-label="..." checked={shareAddress} onChange = {() => setShareAddress(!shareAddress)}/></InputGroup.Text> 
        </InputGroup>
        <br></br>
        <LinkContainer className="bg-primary py-2 text-white" key = "submit" to = "/settings" onClick = {updatePilot}>
            <ListGroupItem action>Submit Changes</ListGroupItem>
        </LinkContainer>
        </>
    )
}