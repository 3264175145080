import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { fetchUserAttributes, signOut, getCurrentUser } from "aws-amplify/auth";
import { AppContext } from "./libs/contextLib";
import { NotificationProvider } from "./libs/notificationLib";
import Routes from "./Routes";
import { checkVersion, getUserData, getClubInfo } from "./libs/databaseAccess";
import appPackage from '../package.json'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DateTime } from "luxon";
const appVersion = appPackage.version

function App() {
  //We initialize isAuthenticated as null so we can know whether the user is logged out, or the variable just hasn't been set yet
  const [isAuthenticated, userHasAuthenticated] = useState(null);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [userIsActive, setUserIsActive] = useState(false);
  const [userCanEditLogs, setUserCanEditLogs] = useState(false);
  const [userCanAddFunds, setUserCanAddFunds] = useState(false);
  const [userCanUpdateGliders, setUserCanUpdateGliders] = useState(false);
  const [userCanAddIntros, setUserCanAddIntros] = useState(false);   
  const [clubName, setClubName] = useState("");
  const [club, setClub] = useState("");
  const [clubLink, setClubLink] = useState("");
  const [clubLogo, setClubLogo] = useState("");
  const [clubMessage, setClubMessage] = useState("");
  const [clubUnits, setClubUnits] = useState([]);
  const [clubLockDate, setClubLockDate] = useState(0);
  const [clubDefaultIntroStalePeriod, setClubDefaultIntroStalePeriod] = useState(0);
  const [userID, setUser] = useState("");
  const [appIsCurrent, setAppIsCurrent] = useState(true);
  const [tzoffset, setTzoffset] = useState(0);
  const [clubTimeZone, setClubTimeZone] = useState(0);
  const [clubTaxRates, setClubTaxRates] = useState(0);
  const [clubMembershipYearEnd, setClubMembershipYearEnd] = useState(null)
  const [clubFeeList, setClubFeeList] = useState([]);
  const navigate = useNavigate();

  // Initialize on starup
  useEffect(() => {
    async function onLoad() {
      try {
        const { username } = await getCurrentUser();
        if(username) userHasAuthenticated(true);
      }
      catch(e) {
        userHasAuthenticated(false)
      }
      const date = new Date();
      setTzoffset(date.getTimezoneOffset());
    }

    onLoad();
  },[]);

  async function getUserInfo() { 
    let userClub = "";
    let userID = "";
    let userInfo = {
      userIsAdmin: false,
      userActive: false,
      userCanEditLogs: false,
      userCanAddFunds: false,
      userCanAddIntros: false,
      userCanUpdateGliders: false,
      showAdminPage: false,
    }
    try {
      userInfo = await getCurrentUser()
      const currentUserAttributes = await fetchUserAttributes();
      userClub = currentUserAttributes['custom:club'];
      userID = userInfo.username;
      if(userClub == null) {
        return ["", userID, {}]
      }
      const currentUserData = await getUserData(userClub, userID);
      userInfo.userIsAdmin = currentUserData.isAdmin || false;
      userInfo.userActive = currentUserData.isActive || false;
      userInfo.activeExpiration = currentUserData.activeExpiration || null;
      userInfo.userCanEditLogs = currentUserData.canEditLogs || false;
      userInfo.userCanAddFunds = currentUserData.canAddFunds || false;
      userInfo.userCanAddIntros = currentUserData.canAddIntros || false;
      userInfo.userCanUpdateGliders = currentUserData.canUpdateGliders || false;
    } catch (err) {
      console.log('error fetching user info: ', err);
    }

    return [userClub, userID, userInfo];
  }

  const loadClubInfo = useCallback(async () => {
    const [userClub, userID, userInfo] = await getUserInfo();
    let userClubInfo
    if (userClub !== "") {
      userClubInfo = await getClubInfo(userClub);
      setClubLink(userClubInfo.clubLink);
      setClubLogo(userClubInfo.clubLogo)
      setClubMessage(userClubInfo.message);
      setClubLockDate(userClubInfo.transactionLockDate)
      setClubTimeZone(userClubInfo.timeZone)
      setClubDefaultIntroStalePeriod(userClubInfo.introStalePeriod)
      setClubTaxRates(userClubInfo.taxRates)
      setClubMembershipYearEnd(userClubInfo.membershipYearEnd ?? null)
      setClubFeeList(userClubInfo.feeList ?? [])
      const launchUnitsLocal = [];
      if (userClubInfo.units && userClubInfo.units.launch) {
        for(let i=0; i < (userClubInfo.units.launch.length);i++){
          launchUnitsLocal.push(userClubInfo.units.launch[i]);
        }
      }
      setClubUnits(launchUnitsLocal);
      setClubName(userClubInfo.name);
    }
    setUser(userID);
    setUserIsAdmin(userInfo.userIsAdmin);
    setUserCanEditLogs(userInfo.userCanEditLogs);
    setUserCanAddFunds(userInfo.userCanAddFunds);
    setUserCanUpdateGliders(userInfo.userCanUpdateGliders);
    setUserCanAddIntros(userInfo.userCanAddIntros);
    const versionActive = await checkVersion(appVersion);
    setAppIsCurrent(versionActive);
    if(userInfo.activeExpiration != null && userClubInfo != null) {
      const expirationDate = DateTime.fromMillis(userInfo.activeExpiration, {zone: userClubInfo.timeZone}).endOf("day")
      const today = DateTime.fromObject({}, {zone: userClubInfo.timeZone})
      if(today < expirationDate){
        setUserIsActive(userInfo.userActive);
      } else {
        setUserIsActive(false);
      }
    } else {
      setUserIsActive(userInfo.userActive);
    }
    setClub(userClub ? userClub : "None"); // Save club unique identifier so it can be used for API calls
  }, [])

  useEffect(() => {
    if(!isAuthenticated) return

    loadClubInfo()
  },[isAuthenticated, loadClubInfo]);

  async function handleLogout() {
    await signOut();
    userHasAuthenticated(false);
    setClubName("");
    navigate("/login");
  }

  return (
    true &&
    <div className="App container">
      <Navbar bg="light" variant="light" className="border rounded">
        <Container>
          <Nav>
              {(clubName === "") ? 
              <LinkContainer to="/">
                <Navbar.Brand>
                  Take Up Slack
                </Navbar.Brand>
              </LinkContainer>
              :
              <LinkContainer to="/">
                <Navbar.Brand>
                  Home
                </Navbar.Brand>
              </LinkContainer>}
          </Nav>
          <Navbar.Collapse>
              <Nav className="nav navbar-nav navbar-right">
                {isAuthenticated
                  ? <>
                    <LinkContainer to="/settings">
                      <Nav.Link>Settings</Nav.Link>
                    </LinkContainer>
                      <Nav.Link onClick = {() => handleLogout()}>Logout</Nav.Link>
                    </>
                  : <>
                    <LinkContainer to="/signup/">
                      <Nav.Link>Signup</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <Nav.Link>Login</Nav.Link>
                    </LinkContainer>
                    </>
                }
                </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
      { (appIsCurrent || !isAuthenticated) ?
        <>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, club, clubLink, clubLogo, clubUnits, 
                                      clubMessage, setClub, clubName, userID, userIsAdmin, userIsActive, userCanEditLogs, 
                                      userCanAddFunds, userCanAddIntros, userCanUpdateGliders, tzoffset, clubLockDate, setClubLockDate,
                                      clubTimeZone, clubDefaultIntroStalePeriod, setClubDefaultIntroStalePeriod, clubTaxRates,
                                      clubFeeList, setClubFeeList, loadClubInfo, clubMembershipYearEnd}}>
            <NotificationProvider>
              <Routes />
            </NotificationProvider>
        </AppContext.Provider>
        <footer className="mt-5">TakeUpSlack Version {appVersion}</footer>
        </>
      : 
        <div className="version check">
        <h1>Time for an Update</h1>
        <p>We have improved the TakeUpSlack application. The version you are using is no longer supported.</p>
        <hr></hr>
        <p>Navigate to <a href="https://takeupslack.com/" target="_blank" rel="noopener noreferrer">takeupslack.com</a> in your browser for the new version</p>
        <p>You may have to force a browser refresh to load the new page, as follows:
          <li>Windows: {"<"}CTRL{">"} and click the reload button</li> 
          <li>Mac: {"<"}SHIFT{">"} and click the reload button</li>
          <li>iPhone or Android - Drag down on page to reload</li>
        </p>
        <hr></hr>
        <p>You can run the new version in a browser at the link above, or "Add to Home Screen" on your Apple or Android device to download and run it as a local application</p>
        </div>
      }
    </div>
  );
}

export default App;
