import React, { useMemo, useState } from "react";
import { Form, InputGroup, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { DateTime } from "luxon";
import { fuzzify } from "../libs/utils";

export default function IntroSearch({intros, selectIntro, highlightExpired}) {
  const { clubTimeZone } = useAppContext();

  const [searchText, setSearchText] = useState("")

  const today = useMemo(() => {
    return DateTime.fromObject({}, {zone: clubTimeZone}).toISODate()
  }, [clubTimeZone])

  const filterIntro = (filterText, intro) => {
    const fuzzyFilter = fuzzify(filterText);
    if(filterText === "") {
      return true
    }

    if(fuzzify(intro.SN).includes(fuzzyFilter)) {
      return true
    }

    const fuzzyName = fuzzify(`${intro.recipientFirst} ${intro.recipientLast}`);
    if(fuzzyName.includes(fuzzyFilter)) {
      return true
    }

    if(fuzzify(intro.buyerEmail).includes(fuzzyFilter)) {
      return true
    }
    return false
  }

  function renderStaleTooltip(props, isStale, staleDate) {
    if(!isStale) return(<></>)
    return (
      <Tooltip id="intro-tooltip" {...props}>This intro expired on {staleDate}</Tooltip>
    )
  }

  const filteredIntros = useMemo(() => {
    return intros.filter((a) => filterIntro(searchText, a)).slice(0,5)
  }, [intros, searchText])

  const expiredIntros = useMemo(() => {
    return intros.filter((a) => a.staleDate <= today).slice(0,5)
  }, [intros, today])

  const introButton = (item) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        key={item.SK}
        overlay={(props) => renderStaleTooltip(props, item.staleDate <= today, item.staleDate)}
      >
        <Button variant={`${item.staleDate <= today ? "warning" : "outline-secondary"}`} key={item.SK} onClick = {() => selectIntro(item)}>
          {`${item.SN}, ${item.recipientFirst} ${item.recipientLast}${item.buyerEmail ? ', ' + item.buyerEmail : ''}`}
        </Button>
      </OverlayTrigger>
    )
  }

  const renderExpiredHighlight = () => {
    return (
      <>
        <h2 className="fs-4">Expired Intros:</h2>
        {
          expiredIntros.length > 0 ?
          <div className="mb-2 d-flex flex-row flex-wrap gap-2 justify-content-center">
            {expiredIntros.map((item) => introButton(item))}
          </div> :
          <span>None</span>
        }
      </>
    )
  }

  return (
    <div className="d-flex flex-column gap-2">
      {highlightExpired && renderExpiredHighlight()}
      <h2 className="fs-4">All Intros:</h2>
      <Form.Group className="mb-2" controlId="introSearch" size="lg">
        <InputGroup>
        <InputGroup.Text id="basic-addon1">Search for intro: </InputGroup.Text>
        <Form.Control
            type="text"
            placeholder="Start typing serial number, name, or email"
            value = {searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </InputGroup>
      </Form.Group>
      <div className="mb-2 d-flex flex-row flex-wrap gap-2 justify-content-center">
        {filteredIntros.map((item) => introButton(item))}
      </div>
    </div>
  );
}