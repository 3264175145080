import React, { useState, useEffect } from "react";
import { Spinner, Button, Dropdown, Form} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import "./Test.css";
import { loadPilots, returnPilot, returnGlider, returnLaunch, returnTug, loadGliders, loadLaunches, loadTugs,
  loadIntroLaunches, loadAdminPayload} from "../libs/databaseAccess";
import "./Admin.css";
import EditGliderModal from "../components/EditGliderModal";
import EditLaunchModal from "../components/EditLaunchModal";
import EditIntroLaunchModal from "../components/EditIntroLaunchModal";
import EditTugModal from "../components/EditTugModal";
import EditPilotModal from "../components/EditPilotModal";
import { isUserActive } from "../libs/utils";

export default function Admin() {
  const {
    club,
    isAuthenticated,
    userIsAdmin,
    clubTimeZone,
    userCanAddFunds,
    userCanAddIntros,
    userCanUpdateGliders,
  } = useAppContext();

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [pilots, setPilots] = useState([]);
  const [gliders, setGliders] = useState([]);
  const [launches, setLaunches] = useState([]);
  const [introLaunches, setIntroLaunches] = useState([]);
  const [tugs, setTugs] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [errorMsg, setErrorMsg] = useState("")

  const [showGliderModal, setShowGliderModal] = useState(false)
  const [gliderToEdit, setGliderToEdit] = useState({})
  
  const [showLaunchModal, setShowLaunchModal] = useState(false)
  const [launchToEdit, setLaunchToEdit] = useState({})
  
  const [showIntroModal, setShowIntroModal] = useState(false)
  const [introToEdit, setIntroToEdit] = useState({})

  const [showTugModal, setShowTugModal] = useState(false)
  const [tugToEdit, setTugToEdit] = useState({})
  
  const [showPilotModal, setShowPilotModal] = useState(false)
  const [pilotToEdit, setPilotToEdit] = useState({})

  //Methods
  const sortPilotList = (pilots) => {
    setPilots(pilots.sort((a, b) => (a.first+a.last).localeCompare(b.first+b.last)));
  }

  const sortLaunchList = (launches) => {
    setLaunches(launches.sort((a, b) => (a.SK).localeCompare(b.SK)));
  }

  const sortTugList = (tugs) => {
    setTugs(tugs.sort((a, b) => (a.SK).localeCompare(b.SK)));
  }

  const sortGliderList = (gliders) => {
    setGliders(gliders.sort((a, b) => (a.SK).localeCompare(b.SK)));
  }

  const sortIntroList = (intros) => {
    setIntroLaunches(intros.sort((a, b) => (a.SK).localeCompare(b.SK)));
  }

  const refreshPilotList = async () => {
    const pilots = await loadPilots(club);
    sortPilotList(pilots)
  }

  const refreshLaunchList = async () => {
    const launches = await loadLaunches(club);
    sortLaunchList(launches)
  }

  const refreshTugList = async () => {
    const tugs = await loadTugs(club);
    sortTugList(tugs)
  }

  const refreshGliderList = async () => {
    const gliders = await loadGliders(club);
    sortGliderList(gliders)
  }

  const refreshIntroList = async () => {
    const intros = await loadIntroLaunches(club);
    sortIntroList(intros)
  }

  useEffect(() => {
    if (!isAuthenticated || club === "") {
      return;
    }

    async function onLoad() {
      try {
        if(!userIsAdmin && !userCanAddFunds && !userCanAddIntros && !userCanUpdateGliders) {
          setErrorMsg(`User is not authorized (603)`)
          return
        }

        const result = await loadAdminPayload(club)

        if(result.statusCode !== 200){
          setErrorMsg(`Could not load content (${result.statusCode})`)
          return
        }

        const data = result.body;
        // Pull club specific info from DynamoDB
        if (userIsAdmin || userCanUpdateGliders) {
          sortGliderList(data.gliders)
        }

        if (userIsAdmin || userCanAddIntros) {
          // While userCanAddIntros does not allow definition of new intros, it needs to know that Intro types have been defined
          sortIntroList(data.intros)
        }
        
        if (userIsAdmin) {
          sortTugList(data.tugs)
          sortLaunchList(data.launches)
        }

        if (userIsAdmin || userCanAddFunds) {
          sortPilotList(data.pilots)
          setPaymentMethods(data.paymentMethods);
        }

        setIsInitialLoading(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();

  }, [
    isAuthenticated,
    club,
    userIsAdmin,
    userCanAddFunds,
    userCanUpdateGliders,
    userCanAddIntros
  ]);
  
  function startEditingPilot(id){
    const pilot = returnPilot(club, id, pilots);
    setPilotToEdit(pilot)
    setShowPilotModal(true)
  }

  function stopEditingPilot(){
    // The modal takes some time to fade out, so don't blank
    // the record until it has disappeared  
    setTimeout(() => setPilotToEdit({}), 500)
    setShowPilotModal(false)
  }

  function startEditingGlider(id) {
    if (id !== "new") {
      const glider = returnGlider(club, id, gliders);
      setGliderToEdit(glider)
    } else {
      setGliderToEdit({SK: "new"})
    }
    setShowGliderModal(true)
  }

  function stopEditingGlider() {
    // The modal takes some time to fade out, so don't blank
    // the record until it has disappeared  
    setTimeout(() => setGliderToEdit({}), 500)
    setShowGliderModal(false)
  }

  function startEditingLaunch(id) {
    if (id !== "new") {
      const launch = returnLaunch(club, id, launches);
      setLaunchToEdit(launch)
    } else {
      setLaunchToEdit({SK: "new"})
    }
    setShowLaunchModal(true)
  }

  function stopEditingLaunch() {
    // The modal takes some time to fade out, so don't blank
    // the record until it has disappeared  
    setTimeout(() => setLaunchToEdit({}), 500)
    setShowLaunchModal(false)
  }

  function startEditingIntro(id) {
    if (id !== "new") {
      const launch = introLaunches.filter((a)=>(a.SK===id)).length>0 ? introLaunches.filter((a)=>(a.SK===id))[0] : {};
      setIntroToEdit(launch)
    } else {
      setIntroToEdit({SK: "new"})
    }
    setShowIntroModal(true)
  }

  function stopEditingIntro() {
    // The modal takes some time to fade out, so don't blank
    // the record until it has disappeared  
    setTimeout(() => setIntroToEdit({}), 500)
    setShowIntroModal(false)
  }

  function startEditingTug(id) {
    if (id !== "new") {
      const tug = returnTug(club, id, tugs);
      setTugToEdit(tug)
    } else {
      setTugToEdit({SK: "new"})
    }
    setShowTugModal(true)
  }

  function stopEditingTug() {
    // The modal takes some time to fade out, so don't blank
    // the record until it has disappeared  
    setTimeout(() => setTugToEdit({}), 500)
    setShowTugModal(false)
  }

  // Dropdown Rendering

  // Must use ForwardRef, Dropdown needs access to the DOM of the Menu to measure it
  const CustomPilotMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [searchStr, setSearchStr] = useState('');

      const normalizeString = (str) => {
        if(typeof str !== 'string') return ''
        return str.replace(/\s/g,'').toLowerCase()
      }

      const filterChild = (child) =>{
        if(!searchStr) return true
        if([".$active-header", ".$inactive-header"].includes(child.key)) return true
        const childStr = Array.isArray(child.props.children) ? child.props.children.join() : child.props.children
        if(normalizeString(childStr).includes(normalizeString(searchStr))) return true
        return false
      }

      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setSearchStr(e.target.value)}
            value={searchStr}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => filterChild(child)
            )}
          </ul>
        </div>
      );
    },
  );

  function renderPilots() {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Dropdown drop="down-centered">
          <Dropdown.Toggle variant="secondary">
            Edit Pilot
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomPilotMenu} className="scrollable-dropdown">
            <Dropdown.Header key="active-header">Active Pilots</Dropdown.Header>
            {pilots.filter((a) => isUserActive(a, clubTimeZone)).map((item) => 
              <Dropdown.Item key={item.SK} onClick = {() => startEditingPilot(item.SK)} as="button">
                {item.first} {item.last}
              </Dropdown.Item>
            )}
            <Dropdown.Header key="inactive-header">Inactive Pilots</Dropdown.Header>
            {pilots.filter((a) => !isUserActive(a, clubTimeZone)).map((item) => 
              <Dropdown.Item key={item.SK} onClick = {() => startEditingPilot(item.SK)} as="button">
                {item.first} {item.last}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function renderGliders() {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Button variant="success" onClick = {() => startEditingGlider("new")}>
          New Glider
        </Button>
        <Dropdown drop="down-centered">
          <Dropdown.Toggle variant="secondary">
            Edit Glider
          </Dropdown.Toggle>

          <Dropdown.Menu className="scrollable-dropdown">
            {gliders.map((item) => 
              <Dropdown.Item key={item.SK} onClick = {() => startEditingGlider(item.SK)} as="button">{item.SK}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function renderLaunches() {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Button variant="success" onClick = {() => startEditingLaunch("new")}>
          New Launch
        </Button>

        <Dropdown drop="down-centered">
          <Dropdown.Toggle variant="secondary">
            Edit Launch
          </Dropdown.Toggle>

          <Dropdown.Menu className="scrollable-dropdown">
            {launches.map((item) => 
              <Dropdown.Item key={item.SK} onClick = {() => startEditingLaunch(item.SK)} as="button">{item.SK}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function renderIntros() {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Button variant="success" onClick = {() => startEditingIntro("new")}>
          New Intro Launch
        </Button>

        <Dropdown drop="down-centered">
          <Dropdown.Toggle variant="secondary">
            Edit Intro Launch
          </Dropdown.Toggle>

          <Dropdown.Menu className="scrollable-dropdown">
            {introLaunches.map((item) => 
              <Dropdown.Item key={item.SK} onClick = {() => startEditingIntro(item.SK)} as="button">{item.SK}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function renderTugs() {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Button variant="success" onClick = {() => startEditingTug("new")}>
          New Tug
        </Button>

        <Dropdown drop="down-centered">
          <Dropdown.Toggle variant="secondary">
            Edit Tug
          </Dropdown.Toggle>

          <Dropdown.Menu className="scrollable-dropdown">
            {tugs.map((item) => 
              <Dropdown.Item key={item.SK} onClick = {() => startEditingTug(item.SK)} as="button">{item.SK}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  // Render Admin Sections
  
  function renderUserAdmin(){
    return(
    <section>
      <EditPilotModal
        showModal={showPilotModal}
        closeModal={stopEditingPilot}
        pilot={pilotToEdit}
        refreshPilotList={refreshPilotList}
        paymentMethods={paymentMethods}
      />
      <h4 className="text-center">User Administration</h4>
      <div className="Home">
        {renderPilots()}
      </div>
    </section>
    )
  }

  function renderGliderAdmin(){
    return(
      <section>
        <EditGliderModal
          showModal={showGliderModal}
          closeModal={stopEditingGlider}
          glider={gliderToEdit}
          refreshGliderList={refreshGliderList}
        />
        <h4 className = "centerHeader">Glider Administration</h4>
        <div className="Home">
          {renderGliders()}
        </div>
      </section>
    );
  }

  function renderLaunchAdmin(){
    return(
      <section>
        <EditLaunchModal
          showModal={showLaunchModal}
          closeModal={stopEditingLaunch}
          launch={launchToEdit}
          refreshLaunchList={refreshLaunchList}
        />
        <h4 className = "centerHeader">Launch Administration</h4>
        <div className="Home">
          {renderLaunches()}
        </div>
      </section>
    );
  }

  function renderIntroLaunchAdmin(){
    return(
      <section>
        <EditIntroLaunchModal
          showModal={showIntroModal}
          closeModal={stopEditingIntro}
          intro={introToEdit}
          refreshIntroList={refreshIntroList}
          launches={launches}
        />
        <h4 className = "centerHeader">Intro Launch Administration</h4>
        <div className="Home">
          {renderIntros()}
        </div>
      </section>
    );
  }

  function renderTugAdmin(){
    return(
      <section>
        <EditTugModal
          showModal={showTugModal}
          closeModal={stopEditingTug}
          tug={tugToEdit}
          refreshTugList={refreshTugList}
        />
        <h4 className = "centerHeader">Tug Administration</h4>
        <div className="Home">
          {renderTugs()}
        </div>
      </section>
    );
  }

  function renderLoading() {
    return (
      <Spinner className="mt-4" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }

  function renderError() {
    return (
      <p>Error: {errorMsg}</p>
    )
  }

  function renderIntroManagement() {
    return (
      <section className="d-flex flex-column gap-3">
        <h4>Intro Management</h4>
        <LinkContainer className="border py-2" key="intro" to="/intro">
          <Button variant="success">New Intro Flight</Button>
        </LinkContainer>
        <LinkContainer className="border py-2" key="refund-intro" to="/intro/refund/">
          <Button variant="secondary">Refund Intro</Button>
        </LinkContainer>
        <LinkContainer className="border py-2" key="cancel-intro" to="/intro/cancel/">
          <Button variant="secondary">Cancel Intro</Button>
        </LinkContainer>
      </section>
    )
  }

  function renderActivities() {
    return (
      <main className="d-flex flex-column gap-3 mt-3">
        {(userIsAdmin || userCanAddFunds) && renderUserAdmin()}
        {(userIsAdmin || userCanUpdateGliders) && renderGliderAdmin()}
        {(userIsAdmin) && renderLaunchAdmin()}
        {(userIsAdmin) && renderTugAdmin()}
        {(userIsAdmin) && renderIntroLaunchAdmin()}
        {(userIsAdmin) && <hr />}
        {(userIsAdmin || userCanAddIntros) && (introLaunches.length > 0) &&
          renderIntroManagement()
        }
        {(userIsAdmin) &&
          <section className="d-flex flex-column gap-3">
            <h4>Exports</h4>
            <LinkContainer className="border py-2" key = "export" to = "/export">
              <Button variant="secondary">Club Data Exports</Button>
            </LinkContainer>
          </section> 
        }
      </main>
    );
  }

  function renderPage() {
    if(errorMsg) {
      return renderError()
    }
    if(!isAuthenticated || isInitialLoading) {
      return renderLoading()
    }
    if(userIsAdmin || userCanAddFunds || userCanAddIntros || userCanUpdateGliders) {
      return renderActivities()
    }
    return renderError()
  }

  return (
    <div className="Home">
      {renderPage()}
    </div>
  );
}

