import { isEqualWith } from 'lodash'
import { DateTime } from 'luxon'
import {useCallback, useMemo} from'react'
import { OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { nameFromPilotId } from '../libs/utils'

const ModificationTypes = {
  "introRef": "Intro Flight",
  "landedAt": "Landing Time",
  "launchedAt": "Launch Time",
  "passengerFirst": "Passenger Name",
  "passengerLast": "Passenger Name",
  "shareCost": "Flight Cost Shared",
  "pilotInCharge": "Pilot in Charge",
  "flightCost": "Flight Cost",
  "time": "Time",
  "tug": "Tug",
  "towPilot": "Tow Pilot",
  "glider": "Glider",
  "launchType": "Launch Type",
  "launchCost": "Launch Cost",
  "user": "Pilot",
  "passengerID": "Passenger Name",
  "flyWith": "Fly With"
}

const CostModificationTypes = {
  "launchCost": "Launch",
  "flightCost": "Flight"
}

const RevisionsTable = ({rawFlights = [], currentFlightData = {}, allPilots = []}) => {
  
  const flight = useMemo(() => {
    const flightData = rawFlights.find((f) => f.SK === currentFlightData.SK )
    // Copy the flight object since we're going to modify it
    return {...flightData}
  }, [currentFlightData.SK, rawFlights])


  // We want most recent changes first
  const revisions = useMemo(() => currentFlightData.history.sort((a,b) => b.submittedAt - a.submittedAt), [currentFlightData])

  function isEmptyValues(value) {
    return value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'string' && value.length === 0);
  }

  // Make isEqual treat empty or null or undefined values as equal to each other
  const customizer = useCallback((a,b) => {
    if(isEmptyValues(a) && isEmptyValues(b)) return true
  }, [])

  const revisionList = useMemo(() => revisions.reduce((list, r) => {
    const revision = {...r}
    const newRevision = {
      by: revision.by,
      date: DateTime.fromMillis(revision.submittedAt ?? 0),
      changes: [],
      costChanges: []
    }
    delete revision.by
    delete revision.submittedAt

    
    let oldPassenger, newPassenger
    if(revision.passengerID) {
      oldPassenger = nameFromPilotId(revision.passengerID, allPilots)
    } else {
      oldPassenger =  revision.passengerFirst ? `${revision.passengerFirst} ${revision.passengerLast}` : "None"
    }
    if(flight.passengerID) {
      newPassenger = nameFromPilotId(flight.passengerID, allPilots)
    } else {
      newPassenger = flight.passengerFirst ? `${flight.passengerFirst} ${flight.passengerLast}` : "None"
    }

    const newPilot = flight.user ? nameFromPilotId(flight.user, allPilots) : "None"
    const oldPilot = revision.user ? nameFromPilotId(revision.user, allPilots) : newPilot


    Object.keys(revision).forEach((k) => {
      // "time" and "id" were erroniously included in old revisions so we ignore them
      // we also ignore non-user level changes like transaction ids
      if(["time", "id", "launchTransaction", "landingTransaction", "first", "last", "passengerLast", "introRef"].includes(k)) return
      // Some properties we want to update on the state tracking object but not report ot the user
      if(k === 'passengerID') {
        flight[k] = revision[k]
        return
      }
      // Children can be objects or arrays so we need to check deep equality
      if(isEqualWith(revision[k], flight[k], customizer)) return

      let oldVal = revision[k]
      let newVal = flight[k]

      // Handle special cases
      if(k === 'pilotInCharge') {
        oldVal = oldVal ? oldPilot : oldPassenger
        newVal = newVal ? newPilot : newPassenger
      }

      if(k === 'passengerFirst') {
        oldVal = oldPassenger
        newVal = newPassenger
      }

      const newChange = {
        old: oldVal,
        new: newVal,
        parameter: k,
        parameterName: ModificationTypes[k] ?? k,
      }

      // Update the state of 'flight' to before the processed revision
      flight[k] = revision[k]
      if(k === 'passengerFirst'){
        flight.passengerLast = revision.passengerLast
      }

      if(["flightCost", "launchCost"].includes(k)){
        newRevision.costChanges.push(newChange)
      } else {
        newRevision.changes.push(newChange)
      }
    })
    list.push(newRevision)
    return list
  }, []),[flight, revisions, customizer, allPilots])


  const shortChangesString = (changes) => {
    if(changes.length <= 0) return "None"
    let str = changes[0].parameterName
    let moreChangesNum = changes.length - 1
    if (moreChangesNum > 0) {
      str += ` [+${moreChangesNum}]`
    }
    return str
  }

  const shortCostString = (changes) => {
    if(changes.length <= 0) return "None"
    let costStr = CostModificationTypes[changes[0].parameter] ?? "Unknown"
    if(changes.length === 1) return costStr
    if(changes.length > 2) {
      costStr += ` [+${changes.length - 1}]`
    } else {
      costStr += `, ${CostModificationTypes[changes[1].parameter] ?? "Unknown"}`
    }
    return costStr
  }

  const renderParameter = (parameter, value) => {
    if(isEmptyValues(value)) return 'None'
    switch(parameter) {
      case 'landedAt':
      case 'launchedAt':
      case 'time':
        const date = DateTime.fromMillis(value ?? 0)
        return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      case 'launchCost':
      case 'flightCost':
        return `$${value.toFixed(2)}`
      case 'shareCost':
        return value ? "Yes" : "No"
      case "user":
      case "passengerID":
      case 'towPilot':
        const pilot = allPilots.find((p) => p.SK === value)
        return pilot ? nameFromPilotId(pilot.SK, allPilots) : "Unknown"
      default:
        return value;
    }
  }

  const changePopover = (changes, date = undefined) => {
    return (
      <Popover>
        <Popover.Header>{date ? `${date.toLocaleString(DateTime.DATETIME_MED)}` : "All Changes"}</Popover.Header>
        <Popover.Body className="d-flex flex-column gap-2">
          {changes.map((c, i) => 
            <span key={i}>
              <strong>{c.parameterName}:&nbsp;</strong>
              {`${renderParameter(c.parameter, c.old)} → ${renderParameter(c.parameter, c.new)}`}
            </span>
          )}
          {changes.length <= 0 && <span>No changes for this revision</span>}
        </Popover.Body>
      </Popover>
    )
  }

  const costPopover = (changes, date = undefined) => {
    return (
      <Popover>
        <Popover.Header>{date ? `${date.toLocaleString(DateTime.DATETIME_MED)}` : "All Changes"}</Popover.Header>
        <Popover.Body className="d-flex flex-column gap-2">
          {changes.map((c, i) => 
            <span key={i}>
              <strong>{c.parameterName}:&nbsp;</strong>
              {`${renderParameter(c.parameter, c.old)} → ${renderParameter(c.parameter, c.new)}`}
              {` [${c.new - c.old >= 0 ? '+' : '-'}$${Math.abs(c.new - c.old)}]`}
            </span>
          )}
          {changes.length <= 0 && <span>No cost changes for this revision</span>}
        </Popover.Body>
      </Popover>
    )
  }

  return (
    <Table className="table table-bordered table-hover" style={{minWidth: "30rem"}}>
      <thead className="fw-bold">
        <tr>
          <td>Date Changed</td>
          <td>Changed By</td>
          <td>Parameter Changed</td>
          <td>Cost Changed</td>
        </tr>
      </thead>

      <tbody>
        {flight.flightLocked && 
          <tr>
            <td className="bg-danger text-center text-white" colSpan="4" >Flight Locked</td>
          </tr>
        }
        {revisionList.map((rev, i) => 
          <tr key={i}>
            <td>{rev.date ? rev.date.toLocaleString(DateTime.DATETIME_MED) : "Unknown"}</td>
            <td>{nameFromPilotId(rev.by, allPilots)}</td>
            <OverlayTrigger trigger="click" placement="top" overlay={changePopover(rev.changes, rev.date)}>
              <td role="button">{shortChangesString(rev.changes)}</td>
            </OverlayTrigger>
            <OverlayTrigger trigger="click" placement="top" overlay={costPopover(rev.costChanges, rev.date)}>
              <td role="button">{shortCostString(rev.costChanges)}</td>
            </OverlayTrigger>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default RevisionsTable