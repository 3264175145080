import { Button, Form } from "react-bootstrap";
import AccountingAdjustments from "../components/AccountingAdjustments";
import { useAppContext } from "../libs/contextLib";
import { DateTime } from "luxon";
import { updateClubTransactionLock } from "../libs/databaseAccess";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { useCallback, useEffect, useState } from "react";
import ClubFeeAdmin from "../components/ClubFeeAdmin";
import IntroExpirationAdmin from "../components/IntroExpirationAdmin";


export default function AccountingAdmin() {
  const {club, setClubLockDate, userIsAdmin, clubTimeZone, clubLockDate} = useAppContext();
  const { sendToast, sendAlert, sendConfirmModal, clearAlerts } = useNotificationContext();

  const [selectedLockDate, setSelectedLockDate] = useState("1970-01-01T00:00")

  function localeDateString(date) {
    let newDate
    if(typeof date === 'number') {
      newDate = DateTime.fromMillis(date, { zone: clubTimeZone })
    } else {
      newDate = DateTime.fromISO(date, { zone: clubTimeZone })
    }
    return newDate.toLocaleString(DateTime.DATE_FULL) 
  }

  const setLockDateFromTimestamp = useCallback((date) => {
    let selectedLockDate
    const tmpDate = DateTime.fromMillis(isNaN(parseInt(date)) ? 0 : parseInt(date))
    tmpDate.setZone(clubTimeZone)
    try{
      selectedLockDate = tmpDate.toISODate()
    } catch(e) {
      selectedLockDate = "1970-01-01"
    }
    setSelectedLockDate(selectedLockDate)
  }, [clubTimeZone])

  useEffect(() => {
    if(isNaN(parseInt(clubLockDate)) || !clubTimeZone) return
    setLockDateFromTimestamp(clubLockDate)
  }, [clubLockDate, setLockDateFromTimestamp, clubTimeZone])

  async function updateLockDate() {
    const date = DateTime.fromISO(selectedLockDate, { zone: clubTimeZone })

    const response = await updateClubTransactionLock(club, date.toMillis())
    if(response.statusCode !== 200 || (response.body && !response.body.status)) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not lock transactions",
        autohide: false
      })
      setLockDateFromTimestamp(clubLockDate)
    } else {
      clearAlerts()
      setClubLockDate(response.body.lockDate)
      sendToast({
        status: NotificationStatus.Success,
        message: `Locked transactions on and before ${localeDateString(response.body.lockDate)}`,
      })
    }
  }

  async function confirmUpdateLockDate() {
    const newLockDate = DateTime.fromISO(selectedLockDate, { zone: clubTimeZone })
    if(newLockDate.toMillis() <= clubLockDate) {
      sendAlert({
        status: NotificationStatus.Warning,
        message: "The new lock date must be after the current lock date",
      })
      return
    }
    sendConfirmModal({
      body: (
        <>
          <p>
            By confirming this request, you acknowledge that modifications to any record on or before <strong>{localeDateString(selectedLockDate)}</strong> will not be possible. 
            Managing corrections before the locked date will need to be done through adjustments to user accounts, and requests for database modifications will be billed at $50 per modification.
          </p>
          <p>
            <u><strong className="underline">This action cannot be undone. Do you want to update the transaction lock date?</strong></u>
          </p>
        </>
      ),
      confirm: updateLockDate,
      confirmColor: "danger",
      confirmText: "Update Transaction Lock Date",
      rejectText: "Cancel",
      heading: "Update Transaction Lock Date?"
    })
  }

  function renderLockDateAdmin() {
    return (
      <section>
        <h4 className = "text-center">Transaction Lock Date</h4>
        <Form.Group controlId="lockDate" className="d-flex flex-row gap-3">
            <Form.Control
              autoFocus
              type="date"
              value={selectedLockDate}
              onChange={e => setSelectedLockDate(e.target.value)}
            />
            <Button type="submit" size="md" onClick={confirmUpdateLockDate}>
                Lock Transactions
            </Button>
        </Form.Group>
        <Form.Text className=" w-100 d-block text-start">No records on or before this date in the club's time zone can be altered.</Form.Text>
      </section>
    )
  }

  return(
    <main className="d-flex flex-column gap-4 pt-3">
      {userIsAdmin && 
        <>
          <AccountingAdjustments />
          <hr />
          <ClubFeeAdmin />
          <hr />
          {renderLockDateAdmin()}
          <hr />
          <IntroExpirationAdmin />
        </>
      }
    </main>
  )
}