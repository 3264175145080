import { DateTime } from "luxon";

export function buildNumberString(incomingValue, last, decimals) {
    let reString = "^\\-?\\d*";
    let value = last;
    
    let decimalLocation = incomingValue.indexOf('.');
    if (decimalLocation >= 0) {
      incomingValue = incomingValue.slice(0,decimalLocation+decimals+1)
    }

    if (decimals > 0) {
        reString += "\\.?[\\d]{0,"+decimals+"}$";
    } else {
        reString += "$";
    }
    let re = new RegExp(reString);
    if (re.test(incomingValue)) {
        value = incomingValue;
    } else {
        if (!isNaN(parseFloat(incomingValue))) {
            value = parseFloat(incomingValue).toFixed(decimals);
        }
    }
    return(value)
  }

export function parseFloatDefault(incomingValue, defaultValue) {
    let parsedValue = parseFloat(incomingValue);
    return !isNaN(parsedValue) ? parsedValue : defaultValue
}

export function parseIntDefault(incomingValue, defaultValue) {
    let parsedValue = parseInt(incomingValue);
    return !isNaN(parsedValue) ? parsedValue : defaultValue
}

export function parseFloatChanged(incomingValue, defaultValue) {
    // Report if the incoming value is changed from the default value. If the incoming value is not 
    // a valid float then report no change (i.e.: An invalid float is reported as not changing the default)
    let parsedValue = parseFloat(incomingValue);
    if (isNaN(parsedValue)) {
        return false
    } else {
        return parsedValue !== defaultValue
    }
}

export function nameFromPilotId(id, allPilots = []) {
  const pilot = allPilots.find((p) => p.SK === id)
  if(pilot) return `${pilot.first} ${pilot.last}`
  return "Unknown"
}

export function isoTimeToLocaleTime(time) {
  const newTime = DateTime.fromISO(time)
  if(!newTime.isValid) return time ? time : "Not Recorded"
  return newTime.toLocaleString(DateTime.TIME_SIMPLE)
}

export function toCurrencyString(number, currency, showSymbol = false) {
  const toConvert = parseFloat(number)
  let opts = {}

  if(currency === undefined) {
    return number
  }

  if(showSymbol) {
    opts = {style: 'currency', currency, currencyDisplay: "narrowSymbol" }
  } else {
    const options = new Intl.NumberFormat(undefined, { style: 'currency', currency }).resolvedOptions()
    opts = {
      maximumFractionDigits: options.maximumFractionDigits ?? 2,
      minimumFractionDigits: options.minimumFractionDigits ?? 0
    }
  }

  return toConvert.toLocaleString(undefined, opts)
}

export function toValidFloatBuild(value){
  // Silly validation that string is building a valid float
  let newValue = ""
  let formattedValue = value
  if(isNaN(value+"0")) {
    for(let i = 0; i <= value.length; i++){
      if(isNaN(value.slice(0,i)+"0")){
        break
      } else {
        newValue = value.slice(0,i);
      }
    }
    formattedValue = newValue;
  }
  return formattedValue;
}

export function isUserActive(user, clubTimeZone) {
  if(user.activeExpiration != null && clubTimeZone != null) {
    const expirationDate = DateTime.fromMillis(user.activeExpiration, {zone: clubTimeZone}).startOf("day")
    const today = DateTime.fromObject({}, {zone: clubTimeZone})
    if(today < expirationDate){
      return user.isActive
    } else {
      return false
    }
  }
  return user.isActive
}

export function formatTaxOptions (taxRates) {
  if(!taxRates) return []
  return Object.keys(taxRates).map((key) => {
    const percentage = (taxRates[key]) * 100
    return {
      value: key,
      label: `${key} (${parseFloat(percentage.toFixed(2))}%)`
    }
  })
}

  export const fuzzify = (str) => {
  if(!str) return ""
  return str.toLocaleLowerCase().replace(/\s/g,'').normalize("NFKD");
}