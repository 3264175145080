import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { RequestType, apiRequest } from "../libs/databaseAccess";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { useAppContext } from "../libs/contextLib";
import "./EditModal.css"
import { DateTime } from "luxon";


export default function ReleaseHeightModal({showModal, closeModal, allPilots, flight, onSuccess}) {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const {club, clubTimeZone, userID, clubUnits} = useAppContext()

  const [isLoading, setIsLoading] = useState(false)
  const [releaseHeight, setReleaseHeight] = useState(0)

  const heightUnit = useMemo(() => {
    if(!clubUnits) return "  "
    return clubUnits.height ? clubUnits.height.S : "ft" 
  }, [clubUnits])

  const millisToTimeString = useCallback((millis) => {
    const date = DateTime.fromMillis(millis, {zone: clubTimeZone ?? 'utc'})
    return date.toLocaleString(DateTime.TIME_WITH_SECONDS)
  }, [clubTimeZone])

  const modalTitle = useMemo(() => {
    if(!flight || !flight.SK) return ""
    return `${flight.first} ${flight.last.slice(0,1)} in ${flight.glider} launched ${millisToTimeString(flight.launchedAt)}`
  }, [millisToTimeString, flight])

  const submitReleaseHeight = async (e) => {
    e.preventDefault()
    sendReleaseHeightUpdate(false)
  }

  const sendReleaseHeightUpdate = async (overwrite = false) =>  {
    const releaseHeightNum = parseInt(releaseHeight)
    if(Number.isNaN(releaseHeightNum) || releaseHeightNum === 0) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Must set a release height before submitting"
      })
      return
    }

    setIsLoading(true)

    const dateNow = DateTime.fromObject({}, {zone: clubTimeZone})
    const body = {
      club,
      id: flight.SK,
      releaseHeight: releaseHeightNum,
      date: dateNow.toISODate(),
      time: dateNow.toMillis(),
      action: "ReleaseHeight",
      by: userID,
      overwrite
    }

    const result  = await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-flight/", options: {body}})
    if(result.statusCode === 200) {
      await onSuccess()
      sendToast({
        status: NotificationStatus.Success,
        message: "Successfully set release height"
      })
      closeModal()
    } else if(result.statusCode === 409) {
      const releaseSetMember = allPilots[result.body.releaseSetBy] ?? {}
      const releaseSetName = releaseSetMember.SK ? `${releaseSetMember.first} ${releaseSetMember.last}` : "Unknown"
      confirmOverwrite(result.body.currentReleaseHeight, releaseSetName)
    } else {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Failed to set release height"
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if(!flight.SK) return
    setReleaseHeight(0)
  }, [flight])

  const confirmOverwrite = (currentReleaseHeight, setByName) => {
    sendConfirmModal({
      heading: "Release Height Already Set",
      body: `${setByName} previously recorded this release at ${currentReleaseHeight} ${heightUnit}. Would you like to update the release height to ${releaseHeight} ${heightUnit}, or ignore?`,
      confirmText: "Overwrite",
      confirmColor: "danger",
      confirm: () => sendReleaseHeightUpdate(true),
      rejectText: "Ignore",
      reject: closeModal
    })
  }

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="d-flex flex-column gap-3" onSubmit={submitReleaseHeight}>
          <Form.Group>
            <Form.Label>Release Height:</Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                as="input"
                inputMode="numeric"
                type="number"
                value={releaseHeight}
                onChange={(e) => setReleaseHeight(e.target.value)}
              />
              <InputGroup.Text>{heightUnit}</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <LoaderButton className="w-100" type="submit" variant="primary" isLoading={isLoading}>
            Submit
          </LoaderButton>
        </Form>
      </Modal.Body>
    </Modal>
  );
}