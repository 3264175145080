import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

export default function AuthenticatedRoute() {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate()

  useEffect(() => {
    if(isAuthenticated === false) {
      navigate(`/login?redirect=${pathname}${search}`, {replace: true})
    }
  })

  return (
    <Outlet />
  );
}