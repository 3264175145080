import React, { useEffect, useMemo, useState } from "react";
import { Form, FormGroup, InputGroup } from "react-bootstrap";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "./LoaderButton";
import { formatTaxOptions, toCurrencyString } from "../libs/utils";
import { RequestType, apiRequest } from "../libs/databaseAccess";

const SalesTaxAccount = 2510
const IntroAccount = 2120

export default function AccountingAdjustments() {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const { club, userID, clubTaxRates } = useAppContext()

  const [amount, setAmount] = useState("");
  const [adjustmentAccount, setAdjustmentAccount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [taxType, setTaxType] = useState("")

  // Set default tax rate once tax rates load
  useEffect(() => {
    if(clubTaxRates != null ){
      const taxRates = Object.keys(clubTaxRates)
      setTaxType(taxRates.length > 0 ? taxRates[0] : "")
    }
  }, [clubTaxRates])

  async function confirmSubmitAdjustment() {
    setIsSubmitting(true)

    const accountNameCache = accountName; // In case the account is changes before toast is sent

    const body = {
      club,
      amount,
      account: adjustmentAccount,
      by: userID,
      time: Date.now(),
      transactionType: adjustmentType.replace(/\s/g,'')
    }

    if(adjustmentAccount === SalesTaxAccount) {
      body.taxType = taxType
    }

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/add-transaction/", options: {"body": body}}
    )

    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Failed to submit adjustment"
      })
    } else {
      sendToast({
        status: NotificationStatus.Success,
        message: `${accountNameCache} adjusted successfully`
      })
      setAmount("")
    }
    setIsSubmitting(false);
  }
  
  async function submitAdjustment() {
    sendConfirmModal({
      heading: "Submit Adjustment",
      body: (<span>
        Do you want to submit a <strong>{adjustmentType}</strong> to <strong>{accountName}</strong>? 
        This will reduce the value of the <strong>{accountName}</strong> account by <strong>{toCurrencyString(amount, "CAD", true)}</strong>.
      </span>),
      confirmText: "Submit",
      rejectText: "Cancel",
      confirmColor: "primary",
      center: true,
      confirm: confirmSubmitAdjustment
    })
  }

  const typeOptions = useMemo(() => {
    let name = ""
    if(adjustmentAccount === SalesTaxAccount) name = "Bank Payment"
    if(adjustmentAccount === IntroAccount) name = "Write Down"

    return [
      <option key={adjustmentAccount} value={adjustmentAccount}>
        {name}
      </option>
    ]
  }, [adjustmentAccount])

  const adjustmentType = useMemo(() => {
    let name = "Adjustment"
    if(adjustmentAccount === SalesTaxAccount) name = "Bank Payment"
    if(adjustmentAccount === IntroAccount) name = "Write Down"

    return name
  }, [adjustmentAccount])

  const accountName = useMemo(() => {
    let name = "Account"
    if(adjustmentAccount === SalesTaxAccount) name = "Sales Tax A/P"
    if(adjustmentAccount === IntroAccount) name = "Intro A/P"

    return name
  }, [adjustmentAccount])

  const canSubmit = useMemo(() => {
    if(adjustmentAccount === SalesTaxAccount && taxType === "") {
      return false
    }
    return adjustmentAccount !== 0 && amount !== "" && amount !== 0
  }, [adjustmentAccount, amount, taxType])

  const taxOptions = useMemo(() => {
    return formatTaxOptions(clubTaxRates)
  }, [clubTaxRates])

  const updateAmount = (amount) => {
    if(amount === "") {
      setAmount(amount)
      return
    }

    // Round to two decimal places before updating
    setAmount(Math.floor((amount * 100)) / 100)
  }

  return (
    <section>
      <h4>Accounting Adjustments</h4>
      <FormGroup className="d-flex flex-column gap-3" controlId="adjustmentInfo" size="lg">
        <InputGroup>
          <InputGroup.Text>Account</InputGroup.Text>
          <Form.Select value={adjustmentAccount} onChange={e => setAdjustmentAccount(parseInt(e.target.value))}>
            <option key={0} value={0}>Select Account</option>
            <option key={SalesTaxAccount} value={SalesTaxAccount}>Sales Tax A/P</option>
            <option key={IntroAccount} value={IntroAccount}>Intro A/P</option>
          </Form.Select>  
        </InputGroup>

        <InputGroup>
          <InputGroup.Text>Adjustment</InputGroup.Text>
          <Form.Select value={adjustmentAccount} disabled={adjustmentAccount === 0}>
            {typeOptions.map((a) => a)}
          </Form.Select>
        </InputGroup>

        {adjustmentAccount === SalesTaxAccount && 
          <InputGroup>
            <InputGroup.Text>Tax Type</InputGroup.Text>
            <Form.Select value={taxType} onChange={e => setTaxType(e.target.value)}>
              {taxOptions.map((opt, i) => 
                <option key={opt.value ?? i} value={opt.value}>{opt.label}</option>
              )}
            </Form.Select>
          </InputGroup>
        }

        <div>
          <InputGroup>
            <InputGroup.Text>Amount</InputGroup.Text>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="number"
              min="0"
              value={amount}
              disabled={adjustmentAccount === 0}
              onChange={e => updateAmount(e.target.value)}
            />
          </InputGroup>  
          <Form.Text className="d-block text-start">The adjustment will reduce the value of the account by this amount</Form.Text>
        </div>

        <LoaderButton isLoading={isSubmitting} disabled={!canSubmit} onClick={submitAdjustment} variant="primary">
          Submit Adjustment
        </LoaderButton>

      </FormGroup> 
    </section>

  );
}