import React, {useState, useEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { Spinner, Form, ToggleButtonGroup, ToggleButton, InputGroup, Button, Row, Col, Container, ButtonGroup, FloatingLabel, OverlayTrigger, Tooltip, Modal} from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { loadCrewPayload, returnLaunch, deleteFlight, getUserBalance, returnIntroLaunch, apiRequest, RequestType } from "../libs/databaseAccess";
import "./Home.css";
import ShortUniqueId from 'short-unique-id';
import { DateTime } from "luxon";

const unknownString = "?";
const unknownGlider = {SK: "None"}

export default function Crew() {
  const navigate = useNavigate();
  const {userID, club, isAuthenticated, clubTimeZone} = useAppContext();
  // Form variables
  const [weight, setWeight] = useState(0);
  const [passengerWeight, setPassengerWeight] = useState(0);
  const [gliderWeight, setGliderWeight] = useState(0);
  const [waterWeight, setWaterWeight] = useState(0);
  const [towSpeed, setTowSpeed] = useState(0);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [flyWith, setFlyWith] = useState("Alone");
  const [pilotInCharge, setPIC] = useState(true);
  const [passengerFirst, setPassengerFirst] = useState("");
  const [passengerLast, setPassengerLast] = useState("");
  const [glider, setGlider] = useState(unknownGlider);
  const [launchType, setLaunchType] = useState(unknownString);
  const [passengerID, setPassengerID] = useState("");
  const [flightId, setFlightId] = useState("");
  const [flightDateString, setFlightDateString] = useState("");
  const [pilotID, setPilotID] = useState("");
  const [shareCost, setShareCost] = useState(false);
  const [pilotBalance, setPilotBalance] = useState(0);
  const [clubWarnLevel, setWarnLevel] = useState(0);
  const [clubMinLevel, setMinLevel] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [pilots, setPilots] = useState([]);
  const [gliders, setGliders] = useState([]);
  const [multiSeatGliders, setMultiSeatGliders] = useState([]);
  const [flyingGliders, setFlyingGliders] = useState([]);
  const [launches, setLaunches] = useState([]);
  const [introSelected, setIntroSelected] = useState({});
  const [intros, setIntros] = useState([]);
  const [newMember, setNewMember] = useState(false);
  const [introType, setIntroType] = useState("");
  const [newLaunches, setNewLaunches] = useState([])
  const [serialNum, setSerialNum] = useState("")
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [payment, setPayment] = useState("");
  const [introLaunchPrice, setIntroLaunchPrice] = useState(0);
  const [paymentRef, setPaymentRef] = useState("");
  const [recGlider, setRecGlider] = useState([]);
  const [recMultiGlider, setRecMultiGlider] = useState([]);
  const [readyGliders, setReadyGliders] = useState({})
  const [errorMessage, setErrorMessage] = useState("");

  const [showIntroPassengetModal, setShowIntroPassengerModal] = useState(false)
  const [introPassengerFirst, setIntroPassengerFirst] = useState("")
  const [introPassengerLast, setIntroPassengerLast] = useState("")

  const flightTypes = {
    "Alone":"Alone", 
    "Instructor":"Instructor",
    "Member":"Member",
    "Intro":"Intro",
    "Friend":"Friend",
  }

  const today = useMemo(() => {
    return DateTime.fromObject({}, {zone: clubTimeZone}).toISODate()
  }, [clubTimeZone])

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated || club === "" || userID === "") {
        return;
      }
  
      try {
        let crewPayloadResponse
        try{
          crewPayloadResponse = await loadCrewPayload(club, userID)
        } catch(e) {
          setErrorMessage("Could not load crew page (code: 600)")
          setIsLoading(false);
          return
        }

        // Pull club specific info from DynamoDB
        if(crewPayloadResponse.statusCode !== 200) {
          setErrorMessage(`Could not load crew page (code: ${crewPayloadResponse.statusCode})`)
          setIsLoading(false);
          return
        }

        const crew = crewPayloadResponse.body

        setLaunches(crew.launches)
        setNewLaunches(crew.introLaunches)
        setWarnLevel(crew.clubInfo.warningLevel);
        setMinLevel(crew.clubInfo.minimumLevel);
        setIntros(crew.intros);
        setPilotID(crew.pilot.pilotID);
        setPilotBalance(crew.pilot.balance);
        setWeight(crew.pilot.weight);
        setFirst(crew.pilot.first);
        setLast(crew.pilot.last);
        setPilots(crew.pilots)
        setReadyGliders(crew.readyGliders)
        setMultiSeatGliders(crew.unusedMultiSeatGliders.map((g) => crew.readyGliders[g]))
        setFlyingGliders(crew.flyingGliders)
        setRecGlider(crew.recentGliders.map((g) => crew.readyGliders[g]))
        setRecMultiGlider(crew.recentMultiSeatGliders.map((g) => crew.readyGliders[g]))
        setPaymentMethods(crew.clubInfo.paymentMethods);
        if(crew.clubInfo.paymentMethods?.length > 0) {
          setPayment(crew.clubInfo.paymentMethods[0])
        }
        
        let defaultGlider = undefined;
        if((typeof crew.recentGliders !== 'undefined') && (crew.recentGliders.length !== 0)){
          setGliders(crew.unusedGliders.map((g) => crew.readyGliders[g]));
          if (crew.recentGliders.length > 0) {
            defaultGlider = crew.readyGliders[crew.recentGliders[0]];
          } else if (crew.unusedGliders.length > 0) {
            defaultGlider = crew.readyGliders[crew.unusedGliders[0]];
          }
        } else {
          setGliders(Object.values(crew.readyGliders));
          defaultGlider = Object.values(crew.readyGliders)[0];
        }

        if (defaultGlider !== undefined) {
          setGlider(defaultGlider);
          if ("weight" in defaultGlider) { 
            setGliderWeight(defaultGlider.weight);
          }
          if ("launchSpeed" in defaultGlider) {
            setTowSpeed(defaultGlider.launchSpeed);
          }
        }

        if (crew.recentGliders.length !== 0){
          setGlider(crew.readyGliders[crew.recentGliders[0]]);
        }

        //randomly generate serial number
        const uid = new ShortUniqueId();
        var defaultSerial = uid();
        setSerialNum(defaultSerial);
        
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, club, userID]);

  function createBody() {
    // Build local date string
    const date = DateTime.fromObject({}, {zone: clubTimeZone})
    const localDateString = date.toISODate();
    const flightUuid = uuidv4();
    setFlightDateString(localDateString);
    setFlightId(flightUuid);

    // Build up body for API Call
    let data = {
      weight: !isNaN(parseFloat(weight)) ? parseFloat(weight) : 0,
      pilotInCharge: pilotInCharge,
      gliderWeight: !isNaN(parseFloat(gliderWeight)) ? parseFloat(gliderWeight) : 0,
      waterWeight: !isNaN(parseFloat(waterWeight)) ? parseFloat(waterWeight) : 0,
      first: first,
      last: last,
      flyWith: flyWith,
      towSpeed: !isNaN(parseFloat(towSpeed)) ? parseFloat(towSpeed) : 0,
      glider: glider.SK,
      launchType: launchType,
      user: pilotID,              // Usually the user, but option to enter a flight for someone else
      shareCost: false,
      submittedAt: Date.now(),
      action: "Create",
    };

    if (flyWith !== flightTypes.Alone){
      data["passengerWeight"] = !isNaN(parseFloat(passengerWeight)) ? parseFloat(passengerWeight) : 0;
      data["passengerID"] = passengerID;
      data["passengerFirst"] = passengerFirst;
      data["passengerLast"] = passengerLast;
      if ((flyWith === flightTypes.Member) && (shareCost)) {
        data["shareCost"] = true;
      }
      if (flyWith === flightTypes.Intro){
        data["introRef"]= introSelected.SK;
      }
    } else {
      data.passengerWeight = 0;
      data.passengerID = "";
      data.passengerFirst = "";
      data.passengerLast = "";
      setPassengerID("");
      setPassengerFirst("");
      setPassengerLast("");
      setPassengerWeight(0);
    };

    const body = {
      date: localDateString,
      club: club,
      type: "FLIGHT",
      id: flightUuid,
      data: data,
    };

    return body
  }

  function createPilotBody(id, weight) {
    // Build up body for API Call
    const data = {
      weight: weight,
    };
    const body = {
      club: club,
      type: "PILOT",
      id,
      data: data,
    };
    return body
  }

  async function createFlight() {
    const bodyContent = createBody();
    if (flyWith === 'Intro') {
      if (newMember) {
        const date = DateTime.fromObject({}, {zone: clubTimeZone});
        const newIntro = {
          "time": date.toMillis(),
          "date": date.toISODate(),
          "launchType": launchType,
          "price": introLaunchPrice,
          "SN": serialNum,
          "recipientFirst": passengerFirst,
          "recipientLast": passengerLast,
          "payment": payment,
          "paymentRef": paymentRef
        }
        bodyContent.data.newIntro = newIntro;
      } else {
        const updateIntro = {
          id: introSelected.SK,
        }

        if(introSelected.recipientFirst !== passengerFirst) {
          updateIntro.recipientFirst = passengerFirst
        }
        if(introSelected.recipientLast !== passengerLast) {
          updateIntro.recipientLast = passengerLast
        }
        bodyContent.data.updateIntro = updateIntro;
      }
    }

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {"body": bodyContent}}
    )
    if(result.statusCode !== 200) {
      onError("Could not create flight")
      return false
    }
    return true
  }

  async function updatePilotWeight(id, weight) {
    const bodyContent = createPilotBody(id, weight);
    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {"body": bodyContent}}
    )
    if(result.statusCode !== 200) {
      onError("Could not update pilot weight")
    }
  }

  async function updateGlider(status) {
    const data = {
      status: status,
    };
    const body = {
      club: club,
      type: "GLIDER",
      id: glider.SK,
      data: data,
    };
    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {body}}
    )
    if(result.statusCode !== 200) {
      onError("Could not create flight")
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (window.confirm(`Confirm ${first} ${last} in ${glider.SK} requesting ${launchType} launch.`)) {
      setIsLoading(true);
      try {
        await createFlight();
        const pilot = pilots.find((p) => p.pilotID === pilotID);
        let parsedWeight = !isNaN(parseFloat(weight)) ? parseFloat(weight) : 0 ;
        if (pilot.weight !== parsedWeight) {
          await updatePilotWeight(pilot.pilotID, parsedWeight);
        }
        if ([flightTypes.Instructor, flightTypes.Member].includes(flyWith)) {
          const passenger = pilots.find((p) => p.pilotID === passengerID);
          parsedWeight = !isNaN(parseFloat(passengerWeight)) ? parseFloat(passengerWeight) : 0;
          if (passenger.weight !== parsedWeight) {
            await updatePilotWeight(passenger.pilotID, parsedWeight);
          }
        }
        await updateGlider("Flying");
        setShareCost(false);    // Reset cost sharing off as the default
        setIsLoading(false);
        navigate("/");
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    } 
  }

  function setValues(eventKey){
    const id = eventKey;
    const pilot = pilots.find((p) => p.pilotID === id)
    if (typeof pilot === 'undefined'){
      return
    }
    setPassengerID(id);
    if ("first" in pilot) { 
      setPassengerFirst(pilot.first);
    }
    if ("last" in pilot) { 
      setPassengerLast(pilot.last);
    }
    if ("weight" in pilot) { 
      setPassengerWeight(pilot.weight);
    }    
  }

  function setPayingPilot(eventKey){
    const id = eventKey;
    const pilot = pilots.find((p) => p.pilotID === id)
    if (typeof pilot === 'undefined'){
      return
    }
    setPilotID(id);
    if ("first" in pilot) { 
      setFirst(pilot.first);
    }
    if ("last" in pilot) { 
      setLast(pilot.last);
    }
    if ("weight" in pilot) { 
      setWeight(pilot.weight);
    }    
  }
  
  function setGliderValues(gliderSK){
    const glider = readyGliders[gliderSK]
    setGlider(glider);
    if ("weight" in glider) { 
      setGliderWeight(glider.weight);
    }
    if ("launchSpeed" in glider) {
      setTowSpeed(glider.launchSpeed);
    }
  }

  function setFlightType(event){
    if (event !== flightTypes.Alone) {
      if (glider.places <= 1){
        if(recMultiGlider.length > 0) {
          setGlider(recMultiGlider[0]);
        } else if(multiSeatGliders.length > 0){
          setGlider(multiSeatGliders[0])
        } else {
          setGlider(unknownGlider);
        }
      }
    }
    if (event === flightTypes.Instructor) {
      setPIC(false);
      if(passengerID !== "") {
        const pilot = pilots.find((p) => p.pilotID === passengerID)
        if (!pilot.isInstructor) {
          setPassengerID("");
          setPassengerFirst("");
          setPassengerLast("");
        }
      }
      else{
        setPassengerID("");
        setPassengerFirst("");
        setPassengerLast("");
      }
    }
    else {
      if(event !== flightTypes.Intro && flyWith === 'Intro'){
        setIntroSelected({});
        setPassengerID("");
        setPassengerFirst("");
        setPassengerLast("");
        setIntroPassengerFirst("");
        setIntroPassengerLast("");
      }
      else if(event === flightTypes.Alone || event === flightTypes.Intro){
        setPassengerFirst("");
        setPassengerLast("");
        setPassengerID("");
      }
      else if(event === flightTypes.Member){
        if(!passengerID) {
          setPassengerID("");
          setPassengerFirst("");
          setPassengerLast("");
        }
      }
      else if(event === flightTypes.Friend){
        setPassengerID("");
      }
      setPIC(true);
    }
    if (event !== flightTypes.Intro) {
      setNewMember(false);
    }
    setFlyWith(event);
  }

  function setLaunchValues(event){
    const id = event.target.value;
    const launch = returnLaunch(club, id, launches);
    setLaunchType(launch.SK);
  }

  function renderLaunches(launches) {
    return (
      <>
        <div className="launches">
          <ButtonGroup className="flex-wrap">
            { launches.map((launch) =>
              <Button variant="outline-secondary" key={launch.SK} value={launch.SK} onClick = {(e) => setLaunchValues(e)} active={launch.SK === launchType} disabled = {flyWith === flightTypes.Intro}>
                {launch.SK}
              </Button>)
            }
          </ButtonGroup>
        </div>
      </>
    );
  }

  function setIntroLaunchValues(event){
    if(event.target.value === unknownString) return;
    const id = event.target.value;
    const introLaunch = returnIntroLaunch(club, id, newLaunches);
    setLaunchType(introLaunch.launch);
    setIntroType(introLaunch.SK);
    setIntroLaunchPrice(introLaunch.price);
  }

  function renderPilots(pilots) {
    let newList = [];
    const userpilot = pilots.filter(a => (a.pilotID === userID));
    const othermembers = pilots.filter(a => (a.pilotID !== userID)).sort((a, b) => (a.first+a.last).localeCompare(b.first+b.last));
    newList = userpilot.concat(othermembers);
    return (
      <FloatingLabel controlId="floatingSelect" label="Select Pilot">
      <Form.Control title={first !== "" ? first+" "+last : "Pilots"} as="select" onChange = {(e) => setPilot(e.target.value)}>
        {newList.map((item) =>
          <option key={item.pilotID} value={item.pilotID}> {item.first} {item.last} </option>
        )}
      </Form.Control>
      </FloatingLabel>
    );
  }

  async function setPilot(event){
    setPayingPilot(event);
    const balance = await getUserBalance(club, event);
    setPilotBalance(balance);
  }

  function renderPassenger(pilots) {
    return (
      <Form.Control as="select" onChange = {(e) => setValues(e.target.value)} value = {passengerID} title={passengerFirst !== "" ? passengerFirst+" "+passengerLast : "Pilots"}>
        {(passengerFirst === "") && <option key="none" value="none">None</option>}
        { pilots.map((item) =>
          <option key={item.pilotID} value={item.pilotID}> {item.first} {item.last} </option>
        )}
      </Form.Control>
    );
  }

  function renderGliders() {
    let recentGliders = recGlider.map(g => g.SK);
    let otherGliders = gliders.map(g => g.SK);

    if(flyWith !== flightTypes.Alone) {
      recentGliders = recMultiGlider.map(g => g.SK)
      otherGliders = multiSeatGliders.map(g => g.SK)
    }

    const gliderSelect = glider.SK

    return(
      <>
        <FloatingLabel controlId="floatingSelect" label="Glider">
          <Form.Control as="select" value={gliderSelect} onChange = {(e) => setGliderValues(e.target.value)}>
            <option key="none" value={unknownGlider}> None </option>
            {recentGliders.length > 0 && 
              <optgroup label="Recently used">
                {recentGliders.map((item,i) =>
                  <option key={i} value={item}> {item}</option>
                )}
              </optgroup>
            }
            <optgroup label="Gliders">
              { otherGliders.map((item, i) => 
                <option key={i} value={item}> {item} </option>
              )}
            </optgroup>
            {flyingGliders.length > 0 && 
              <optgroup label="Already Flying">
                {flyingGliders.map((item,i) =>
                  <option key={i} disabled> {item}</option>
                )}
              </optgroup>
            }
          </Form.Control>
        </FloatingLabel> 
      </>
    );
  }

  function filterIntro(filterText, intro) {
    const lowerFilter = filterText.toLowerCase();
    if(filterText === "") {
      return true
    }

    if(intro.SN.toLowerCase().includes(lowerFilter)) {
      return true
    }

    const fullName = `${intro.recipientFirst} ${intro.recipientLast}`.toLowerCase();
    if(fullName.includes(lowerFilter)) {
      return true
    }

    if(intro.buyerEmail.toLowerCase().includes(lowerFilter)) {
      return true
    }
    return false
  }

  function selectIntro(intro){
    setIntroSelected(intro);
    setLaunchType(intro.launchType);
    setPassengerFirst(intro.recipientFirst);
    setPassengerLast(intro.recipientLast);
    setIntroPassengerFirst(intro.recipientFirst);
    setIntroPassengerLast(intro.recipientLast);
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Take Up Slack</h1>
        <p>Your Gliding Flight Line Management Tool</p>
        {/* {(club !== "") ? <h2>Welcome to {clubName}!</h2> : <h2>Please sign in </h2>} */}
      </div>
    );
  }

  function renderPages() {
    return (<>{
      !isSubmitted ? renderActivities() : renderConfirm()
    }</>)
    // return (renderActivities())
  }

  function renderActivities() {
    if(isLoading) {
      return renderLoading()
    } else if(errorMessage) {
      return renderError()
    } else {
      return renderForm()
    }
  }

  function renderLoading() {
    return (
      <Spinner className="mt-4" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }

  function renderError() {
    return (
      <p className="mt-4">Error: { errorMessage }</p>
    )
  }

  async function cancelFlight() {
    await deleteFlight(club, flightId, flightDateString);
    await updateGlider("Ready");
    setIsSubmitted(false);
  }

  function renderConfirm() {
    if (window.confirm(`Confirm ${first} ${last} in ${glider.SK} requesting ${launchType} launch.`)) {
      setIsSubmitted(true);
      navigate("/");
    } else {
      cancelFlight();
    }
  }

  function newIntro(newIntroFlag) {
    setNewMember(newIntroFlag);
    if ((newIntroFlag) && (newLaunches.length > 0)) {
      const defaultIntroLaunch = newLaunches[0];
      setLaunchType(defaultIntroLaunch.launch);
      setIntroType(defaultIntroLaunch.SK);
      setIntroLaunchPrice(defaultIntroLaunch.price);
      setPassengerFirst("");
      setPassengerLast("");
    } else {
      setIntroSelected({});
      setLaunchType("");
      setIntroType("");
      setIntroLaunchPrice(0);
      setPassengerFirst("");
      setPassengerLast("");
    }
  }

  function renderStaleTooltip(props, isStale, staleDate) {
    if(!isStale) return(<></>)
    return (
      <Tooltip id="intro-tooltip" {...props}>This intro expired on {staleDate}</Tooltip>
    )
  }


  function updateIntroPassenger(e) {
    e.preventDefault()
    e.stopPropagation() // Don't trigger the main form
    setPassengerFirst(introPassengerFirst)
    setPassengerLast(introPassengerLast)
    setShowIntroPassengerModal(false)
  }

  function cancelUpdateIntroPassenger() {
    setShowIntroPassengerModal(false)
    setIntroPassengerFirst(passengerFirst)
    setIntroPassengerLast(passengerLast)
  }

  function renderIntroPassengerModal() {
    return (
      <Modal show={showIntroPassengetModal} onHide={() => setShowIntroPassengerModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Passenger for Intro: {introSelected.SN}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form id="intro-passenger-form" onSubmit={updateIntroPassenger}>
            <Form.Group className="d-flex flex-column gap-3" controlId="tugInfo" size="lg">
              <InputGroup>
                <InputGroup.Text>First Name: </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={introPassengerFirst}
                  onChange={e => setIntroPassengerFirst(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Last Name: </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={introPassengerLast}
                  onChange={e => setIntroPassengerLast(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex flex-row gap-2">
            <Button variant="secondary" onClick={cancelUpdateIntroPassenger}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" form="intro-passenger-form">
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  function renderForm() {
    return (
      <Form id="crew-request-form" className="mt-4" onSubmit={handleSubmit}> 
        <Form.Group className="mb-2" controlId="pilotSelect" size="lg"> 
          {renderPilots(pilots)}
        </Form.Group>
        <Form.Group className="mb-4" controlId="weightForm" size="lg">
          {/*<InputGroup>
            <InputGroup.Text id="basic-addon1">Pilot Weight: </InputGroup.Text>
            <Form.Control
              type="number"
              hint = "Your weight"
              value={weight}
              onChange={e => setWeight(e.target.value)}
            />
            <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
    </InputGroup>*/}
        </Form.Group>
        <Form.Group controlId="flightType"><h4 style={{display: "inline"}}>Flight Type: &nbsp;</h4>
          <ToggleButtonGroup className="flex-wrap" name="flyRadio" type="radio" value={flyWith} onChange={e => setFlightType(e)}>
            <ToggleButton variant="outline-secondary" id="radio-1" name="flyRadio" value={flightTypes.Alone}>Alone</ToggleButton>
            <ToggleButton variant="outline-secondary" id="radio-2" name="flyRadio" value={flightTypes.Instructor}>Instructor</ToggleButton>
            <ToggleButton variant="outline-secondary" id="radio-3" name="flyRadio" value={flightTypes.Member}>Member</ToggleButton>
            <ToggleButton variant="outline-secondary" id="radio-4" name="flyRadio" value={flightTypes.Intro}>Intro</ToggleButton>
            <ToggleButton variant="outline-secondary" id="radio-5" name="flyRadio" value={flightTypes.Friend}>Friend</ToggleButton>
          </ToggleButtonGroup>
        </Form.Group>
        { (flyWith !== flightTypes.Alone) && 
          <hr className="my-4" />
        }       
        <Form.Group className="mb-2" size="lg">
        {(flyWith === "Instructor") && <InputGroup>
          <InputGroup.Text id="basic-addon1">Instructor: </InputGroup.Text>
            {renderPassenger((pilots.filter(pilot => pilot.isInstructor).filter(pilot => pilot.pilotID !== pilotID)))}
          </InputGroup> }
        </Form.Group>
          
        <Form.Group className="mb-2"  size="lg">
        {(flyWith === "Member") && <InputGroup>
          <InputGroup.Text id="basic-addon1">Member: </InputGroup.Text>
            {renderPassenger((pilots).filter(pilot => pilot.pilotID !== pilotID))}
          </InputGroup>}
        </Form.Group>

        { (flyWith === flightTypes.Friend) && 
        <>
        <Form.Group className="mb-2" controlId="passengerFirst" size="lg">Second Person
          <InputGroup>
          {<InputGroup.Text id="basic-addon1">First name: </InputGroup.Text>}
            <Form.Control
              type="text"
              value={passengerFirst}
              onChange={e => setPassengerFirst(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-2" controlId="passengerLast" size="lg">
          <InputGroup>
          {<InputGroup.Text id="basic-addon1">Last name: </InputGroup.Text>}
            <Form.Control
              type="text"
              value={passengerLast}
              onChange={e => setPassengerLast(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
        </>
        }
        {/* (flyWith !== flightTypes.Alone && flyWith !== flightTypes.Intro) &&
        <> 
        <Form.Group className="mb-2" controlId="passengerWeight" size="lg">
          <InputGroup>
          {<InputGroup.Text id="basic-addon1">Weight: </InputGroup.Text>}
            <Form.Control
              type="number"
              value={passengerWeight}
              onChange={e => setPassengerWeight(e.target.value)}
            />
            <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        </>
        */}
        { ((flyWith === "Instructor") || (flyWith === "Member") ||  (flyWith === "Friend")) &&
        <>
        <hr className="my-4" />
        <Form.Group className="mb-2" controlId="pilotInCharge" size="lg"><h4 style={{display: "inline"}}>Pilot in Command: </h4>
          <ToggleButtonGroup name="pilotFormCheck" type="radio" value={pilotInCharge} onChange={setPIC}>
            <ToggleButton variant="outline-secondary" id="pilot-1" name="pilotFormCheck" value={true} disabled={flyWith === "Instructor"}>
              {(userID === pilotID) ? "Me" : first+" "+last}
            </ToggleButton>
            <ToggleButton variant="outline-secondary" id="pilot-2" name="pilotFormCheck" value={false} disabled={flyWith === "Instructor"}>
              {(passengerID === "") ? flyWith : passengerFirst+" "+passengerLast}
            </ToggleButton>
          </ToggleButtonGroup>
        </Form.Group>
        </>
        }
        { (flyWith === "Member") &&
        <>
        <Form.Group controlId="splitCost" size="lg"><h4 style={{display: "inline"}}>Share Flight Cost? </h4>
        <ToggleButtonGroup name="splitFormCheck" type="radio" value={shareCost} onChange={setShareCost}>
          <ToggleButton variant="outline-secondary" id="split-1" name="splitFormCheck" value={false}>No</ToggleButton>
          <ToggleButton variant="outline-secondary" id="split-2" name="splitFormCheck" value={true}>Yes</ToggleButton>
        </ToggleButtonGroup>
        </Form.Group>
        </>
        }
        { (flyWith === flightTypes.Intro) && 
          <>
          <Form.Group className="mb-2">
            <Container>
              <Row>
                <Col>
                  <ToggleButtonGroup name="splitFormCheck" type="radio" value={newMember} onChange={newIntro}>
                    <ToggleButton variant="outline-secondary" id="intro-1" name="addNewMember" value = {false}>
                      Pre-purchased
                    </ToggleButton>
                    <ToggleButton variant="outline-secondary" id="intro-2" name="addNewMember" value = {true}>
                      New Intro
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Col>
              </Row>
            </Container>
          </Form.Group>
          {(newMember === false) &&
            <>
              <Form.Group className="mb-2" controlId="introSearch" size="lg">
                <InputGroup>
                <InputGroup.Text id="basic-addon1">Search for intro: </InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder="Start typing serial number, name, or email"
                    value = {searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <div className="mb-2 d-flex flex-row flex-wrap gap-2 justify-content-center">
                {intros.filter((a) => (filterIntro(searchText, a))).slice(0,5).map((item) =>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  key={item.SK}
                  overlay={(props) => renderStaleTooltip(props, item.staleDate <= today, item.staleDate)}
                >
                  <Button variant={`${item.staleDate <= today ? "warning" : "outline-secondary"}`} key={item.SK} onClick = {() => selectIntro(item)}>
                    {`${item.SN}, ${item.recipientFirst} ${item.recipientLast}${item.buyerEmail ? ', ' + item.buyerEmail : ''}`}
                  </Button>
                </OverlayTrigger>
                )}
              </div>

            </>
          }
          </>
        }

        { ((newMember === false) && (flyWith === flightTypes.Intro)) && Object.keys(introSelected).length > 0 &&
          <Container className="fs-4">
            {renderIntroPassengerModal()}
            <Row className="show-Container mb-2">
              <Col xs={6} className="text-right">
                Passenger: 
              </Col>
              <Col xs={5} className="text-left">
                <span>
                  {`${passengerFirst} ${passengerLast}`} 
                </span>
              </Col>
              <Col xs={1}>
                <Button variant="outline-primary" onClick={() => setShowIntroPassengerModal(true)}>
                  <img style={{height: "0.9rem", width: "1rem", fill: "blue"}} src="/edit-pen-icon.svg" alt="Edit passenger" />
                </Button>
              </Col>
            </Row>
            <Row className="show-Container mb-2">
              <Col xs={6} className="text-right">
                Launch Type: 
              </Col>
              <Col xs={5} className="text-left">
                {introSelected.launchType} 
              </Col>
            </Row>
            <Row className="show-Container mb-2">
              <Col xs={6} className="text-right">
                Purchase Date: 
              </Col>
              <Col xs={5} className="text-left">
                {introSelected.date !== '' ? introSelected.date : 'Unknown'} 
              </Col>
            </Row>
            <Row className="show-Container mb-2">
              <Col xs={6} className="text-right">
                Purchase Note: 
              </Col>
              <Col xs={5} className="text-left">
                {introSelected.note !== '' ? introSelected.note : 'None'}
              </Col>
            </Row>
            <Row className="show-Container mb-2">
              <Col xs={6} className="text-right">
                Serial Number: 
              </Col>
              <Col xs={5} className="text-left">
                {introSelected.SN ? introSelected.SN : 'Unknown'} 
              </Col>
            </Row>
          </Container>
        }
        {(flyWith===flightTypes.Intro && newMember === true) &&
          <>
          <Form.Group className="mb-2" size="lg">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Launch Method:</InputGroup.Text>
              <Form.Control as="select" onChange = {e => setIntroLaunchValues(e)}>
                {newLaunches.map((item)=>
                  <option key={item.SK} value={item.SK}>{item.SK}</option>
                )}
                {newLaunches.length < 1 && <option key={"None"} value={unknownString}>None</option>}
              </Form.Control>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2" size="lg">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">First Name: </InputGroup.Text>
              <Form.Control
                type="text"
                value={passengerFirst}
                onChange={e => setPassengerFirst(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          
          <Form.Group className="mb-2" size="lg">
            <InputGroup>
            <InputGroup.Text id="basic-addon1">Last Name: </InputGroup.Text>
            <Form.Control
            type="text"
            value={passengerLast}
            onChange={e => setPassengerLast(e.target.value)}
            />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-2" size="lg">
            <InputGroup>
            <InputGroup.Text id="basic-addon1">Price: </InputGroup.Text>
            <Form.Control
            type="number"
            value =  {introLaunchPrice}
            disabled
            />
            </InputGroup>
          </Form.Group>
          
          <Form.Group className="mb-2" size="lg">
            <InputGroup>
            <InputGroup.Text id="basic-addon1">Payment Method:</InputGroup.Text>
            <Form.Control as = "select" value = {payment} onChange = {(e)=>{setPayment(e.target.value)}}>
            {paymentMethods.map((item)=>
                <option key={item} value={item}>{item}</option>
            )}
            </Form.Control>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-2" size="lg">
            <InputGroup>
            <InputGroup.Text id="basic-addon1">Payment Reference:</InputGroup.Text>
            <Form.Control as = "input" 
              type = "text"
              value = {paymentRef}
              onChange = {(e)=>{setPaymentRef(e.target.value)}}
            />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-2" size="lg">
            <InputGroup>
            <InputGroup.Text id="basic-addon1">Serial Number: </InputGroup.Text>
            <Form.Control
            type="text"
            value={serialNum}
            onChange={e => setSerialNum(e.target.value)}
            />
            </InputGroup>
          </Form.Group>
          </>   
        }
        {/* (flyWith === flightTypes.Intro) &&
          <Form.Group className="mb-2" controlId="introWeight" size="lg">
            <InputGroup>
            {<InputGroup.Text id="basic-addon1">Passenger Weight: </InputGroup.Text>}
              <Form.Control
                type="number"
                value={passengerWeight}
                onChange={e => setPassengerWeight(e.target.value)}
              />
              <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
            </InputGroup>
          </Form.Group>
      */}

        <hr className="my-4" />
        {
          <Form.Group className="mb-2" controlId="gliderList" size="lg">
            {renderGliders()}  
          </Form.Group>
        }
        {/*
          <Form.Group className="mb-2" controlId="waterWeight" size="lg">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">Water Loading: </InputGroup.Text>
            <Form.Control
              type="number"
              hint = "Weight of added water"
              value={waterWeight}
              onChange={e => setWaterWeight(e.target.value)}
            />
            <InputGroup.Text id="basic-addon1">L</InputGroup.Text>
          </InputGroup>
          </Form.Group>
      */}
        {
          <Form.Group className="mb-2" controlId="towSpeed" size="lg">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Tow Speed: </InputGroup.Text>
              <Form.Control
                type="number"
                hint = "Tow Speed"
                value={towSpeed}
                onChange={e => setTowSpeed(e.target.value)}
              />
              <InputGroup.Text id="basic-addon1">knots</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        }
        {(flyWith !== flightTypes.Intro || newMember !== true) &&
          <Form.Group controlId="launchType" size="lg"><h4 style={{display: "inline"}}>Launch Type: </h4>
              {renderLaunches((launches))}    
          </Form.Group>
        }
        <hr className="my-4" />
        {(launchType !== "") &&
        <>
          <p>{first} {last} in {glider.SK} requesting {launchType} launch.</p>
          {(flyWith !== "Alone")&&<p>Flying with {flyWith.toLowerCase()} {passengerFirst} {passengerLast} </p>}
          <LoaderButton 
            type="submit"
            as="button"
            size="lg"
            isLoading={isLoading}
            form="crew-request-form"
            disabled = {
              (glider.SK === unknownGlider.SK) || (launchType === unknownString) || 
              ((flyWith !== "Alone") && (passengerFirst === "")) || (flyWith !== "Intro" && pilotBalance<clubMinLevel) ||
              ((flyWith === "Intro") && (newMember === true) && (introType === ""))
            }
          >
            {(pilotBalance > clubMinLevel && pilotBalance < clubWarnLevel) && <p>Warning: Low funds</p>}
            {(pilotBalance < clubMinLevel && flyWith !== "Intro") ? <>Insufficient funds</>: <>Place on flight line</>}
          </LoaderButton>
        </>
        }
      </Form>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderPages() : renderLander()}
    </div>
  );
}
