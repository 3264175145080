import {useCallback, useMemo, useState} from "react";
import {Button, Form, InputGroup, OverlayTrigger, Popover, Spinner, Table, CloseButton} from "react-bootstrap";
import {truncate} from "lodash"
import "../containers/Accounting.css"
import { fuzzify, toCurrencyString } from "../libs/utils";

const FilterAny = "Any"

export const AccountingTableTypes = {
  "Overview": "Account Overview",
  "CashIn": "Cash In",
  "MembersPayable": "Member Accounts Payable",
  "IntrosPayable": "Intro Accounts Payable",
  "TaxesPayable": "Sales Tax Accounts Payable",
  "Revenue": "Revenue",
  "MemberTransactions": "MemberTransactions",
  "TaxTransactions": "TaxTransactions",
  "DepositTransactions": "DepositTransactions",
  "TowRevenue": "Tows Revenue",
  "RentalRevenue": "Rental Revenue",
  "IntroRevenue": "Intros Revenue",
  "OtherRevenue": "Other Revenue",
}

const RevenueAccounts = {
  [AccountingTableTypes.TowRevenue]: "towRevenue",
  [AccountingTableTypes.RentalRevenue]: "rentalRevenue",
  [AccountingTableTypes.IntroRevenue]: "introRevenue",
  [AccountingTableTypes.OtherRevenue]: "otherRevenue",
};

const DefaultSort = {
  [AccountingTableTypes.CashIn]: {column: 0, asc: false},
  [AccountingTableTypes.MembersPayable]: {column: 0, asc: false},
  [AccountingTableTypes.IntrosPayable]: {column: 0, asc: false},
  [AccountingTableTypes.Revenue]: {column: 0, asc: false},
  [AccountingTableTypes.MemberTransactions]: {column: 0, asc: false},
  [AccountingTableTypes.TaxTransactions]:  {column: 0, asc: false},
  [AccountingTableTypes.DepositTransactions]: {column: 0, asc: false},
}

const getSortingValue = (col) => {
  if(col.sort != null) {
    return col.sort;
  }
  if(col.display != null) {
    return col.display
  }
  return col
}

const getDisplayValue = (col) => {
  if(col.display != null) {
    return col.display
  }
  return col
}

const sortRows = (rows, sortColumn, sortAscending) =>{
  if(rows.length < 1) return []
  const typeCheck = getSortingValue(rows[0][sortColumn])
  if(typeof typeCheck === 'string'){
    return rows.sort((a,b) => {
      const aSort = getSortingValue(a[sortColumn])
      const bSort = getSortingValue(b[sortColumn])
      return (sortAscending ? 1 : -1) * bSort.localeCompare(aSort)
    })
  } else if(typeof typeCheck === 'number'){
    return rows.sort((a,b) => {
      const aSort = getSortingValue(a[sortColumn])
      const bSort = getSortingValue(b[sortColumn])
      return (sortAscending ? -1 : 1) * (bSort - aSort)
    })
  }
  return rows
}

const TableEntry = ({account, summary, onDrill, rowClass}) => {
  return (
    <>
      {summary ?
        <>
          <tr className={`text-start ${rowClass ? rowClass : ""}`}>
            <td
              role={onDrill ? "button" : "cell"}
              onClick={onDrill}
            >
              {account}
            </td>
            <td className="text-end">{toCurrencyString(summary.start, "CAD") ?? "Unknown"}</td>
            <td className="text-end">{toCurrencyString(summary.debit, "CAD") ?? "Unknown"}</td>
            <td className="text-end">{toCurrencyString(summary.credit, "CAD") ?? "Unknown"}</td>
            <td className="text-end">{toCurrencyString(summary.end, "CAD")}</td>
          </tr>
        </> :
        <tr colSpan="5"></tr>
      }
    </>
  )
}

const filterRow = (row, filters) => {
  for(const filter of filters) {
    const colValue = getDisplayValue(row[filter.col])
    if(!filter.fuzzy && filter.value !== FilterAny) {
      if(colValue !== filter.value) {
        return false
      }
    } else if(filter.fuzzy && filter.value) {
      const fuzzyCol = fuzzify(colValue)
      if(!fuzzyCol.includes(filter.value)) {
        return false
      }
    }
  }
  
  return true
}

const BreakdownTable = ({breakdown, summary, sortAscending, sortColumn, mapper, isTransactionHistory, filters, onDrill, width}) => {
  const rows = useMemo(() => {
    if(!breakdown) return []
    const rows = breakdown.map(mapper)
    return rows
  }, [breakdown, mapper])

  const organizedRows = useMemo(() => {
    let newRows = rows;

    if(Array.isArray(filters) && filters.length > 0) {
      newRows = newRows.filter((r) => filterRow(r, filters))
    }

    return sortRows(newRows, sortColumn, sortAscending)
  }, [rows, sortColumn, sortAscending, filters])

  const tableWidth = useMemo(() => {
    return width ? parseInt(width) : (organizedRows[0] ?? [0,0]).length
  }, [width, organizedRows])

  return (
    <tbody>
      {organizedRows.map((row, i) => {
        const rowElements = [];

        for(const j in row) {
          let tooltipText;
          const col = row[j]
          const drillable = (onDrill && j === "0")
          
          let align = col.align
          if(!align) {
            align = j === "0" ? "text-start" : 'text-end'
            
          }
          let cellText = col.display !== undefined ? col.display : col
          if(cellText.length > 60 && !drillable) {
            tooltipText = cellText
            cellText = truncate(cellText, {
              length: 60,
              separator: /\s/g
            })
          }

          const element = ( 
            <td
              className={align}
              key={j}
              role={(drillable || tooltipText) ? "button" : "cell"}
              onClick={drillable? () => onDrill(row) : () => {}}
            >
              {cellText}
            </td>
          )

          if(tooltipText) {
            const tooltip = (
              <Popover className="py-2 px-3" id={`${i}-${j}`}>{tooltipText}</Popover>
            )

            rowElements.push((
              <OverlayTrigger trigger="click" key={`${i}-${j}`} overlay={tooltip}>
                {element}
              </OverlayTrigger>
            ))
          } else {
            rowElements.push(element)
          }
        }

        return (
          <tr key={i}>
            {rowElements.map((element) => element)}
          </tr>
        )
      })}
      { isTransactionHistory ?
        <tr>
          <td className="text-start" colSpan={tableWidth - 1}>Starting Balance</td>
          <td className="text-end">{toCurrencyString(summary.start, "CAD")}</td>
        </tr> :
        <TableEntry summary={summary} account="Total" />
      }
    </tbody>
  )
}

const SummaryTable = ({summaries, onDrill}) => {
  return (
    <tbody>
      <TableEntry account="Cash In" onDrill={() => onDrill(AccountingTableTypes.CashIn)} summary={summaries.cashIn} />
      <TableEntry account="Members A/P" onDrill={() => onDrill(AccountingTableTypes.MembersPayable)} summary={summaries.membersPayable} />
      <TableEntry account="Intro A/P" onDrill={() => onDrill(AccountingTableTypes.IntrosPayable)} summary={summaries.introsPayable} />
      <TableEntry account="Sales Tax A/P" onDrill={() => onDrill(AccountingTableTypes.TaxesPayable)} summary={summaries.taxesPayable} />
      <TableEntry account="Tows Revenue" onDrill={() => onDrill(AccountingTableTypes.TowRevenue)} summary={summaries.towRevenue} />
      <TableEntry account="Rental Revenue" onDrill={() => onDrill(AccountingTableTypes.RentalRevenue)} summary={summaries.rentalRevenue} />
      <TableEntry account="Intros Revenue" onDrill={() => onDrill(AccountingTableTypes.IntroRevenue)} summary={summaries.introRevenue} />
      <TableEntry account="Other Revenue" onDrill={() => onDrill(AccountingTableTypes.OtherRevenue)} summary={summaries.otherRevenue} />
    </tbody>
  )
}

export default function AccountingSummaryTable({currentTable, setCurrentTable, summaries, breakdowns, transactions, getTransactions, isLoading, feeList}) {
  const [tableName, setTableName] = useState(AccountingTableTypes.Overview)
  const [sortColumn, setSortColumn] = useState(0)
  const [sortAscending, setSortAscending] = useState(false)
  const [feeFilter, setFeeFilter] = useState(FilterAny)
  const [noteFilter, setNoteFilter] = useState("")

  const fuzzyNoteFilter = useMemo(() => fuzzify(noteFilter), [noteFilter])


  // OnDrill functions - update state when a user drills down
  const onDrill = useCallback((table) => {
    setCurrentTable(table)
    setTableName(table)
    const defaultSort = DefaultSort[table]
    if(defaultSort) {
      setSortColumn(defaultSort.column)
      setSortAscending(defaultSort.asc)
    }

    if(table === AccountingTableTypes.OtherRevenue) {
      setFeeFilter(FilterAny)
    }
  }, [setCurrentTable])

  const feeFilterOptions = useMemo(() => {
    return [FilterAny, ...feeList]
  }, [feeList])

  const drillMember = useCallback((data) => {
    getTransactions("membersPayable", data[0].ref)
    setTableName(`${data[0].display} Transaction History`)
    const table = AccountingTableTypes.MemberTransactions
    setCurrentTable(table)
    setSortColumn(DefaultSort[table].column)
    setSortAscending(DefaultSort[table].asc)
  }, [getTransactions, setCurrentTable])
  
  const drillTaxes = useCallback((data) => {
    getTransactions("taxesPayable", data[0])
    setTableName(`${data[0]} Transaction History`)
    const table = AccountingTableTypes.TaxTransactions
    setCurrentTable(table)
    setSortColumn(DefaultSort[table].column)
    setSortAscending(DefaultSort[table].asc)
  }, [getTransactions, setCurrentTable])

  const drillDeposit = useCallback((data) => {
    getTransactions("cashIn", data[0])
    setTableName(`${data[0]} Transaction History`)
    const table = AccountingTableTypes.DepositTransactions
    setCurrentTable(table)
    setSortColumn(DefaultSort[table].column)
    setSortAscending(DefaultSort[table].asc)    
  }, [getTransactions, setCurrentTable])

  // Mappers for row layouts
  const introMapper = (t) => [
    {display: t.dateStr, sort: t.timestamp},
    {display: t.soldTo ?? "", align: "text-start"},
    {display: t.SN ?? "", align: "text-start"},
    {display: t.note ?? "", align: "text-start"},
    {display: toCurrencyString(t.change, "CAD"), sort: t.change},
    {display: toCurrencyString(t.balance, "CAD"), sort: t.balance},
  ]

  const memberTransactionMapper = (t) => [
    {display: t.dateStr, sort: t.timestamp},
    {display: t.description ?? "", align: "text-center"},
    {display: toCurrencyString(t.amount, "CAD"), sort: t.amount},
    {display: toCurrencyString(t.balance, "CAD"), sort: t.balance},
  ]

  const taxTransactionMapper = (t) => [
    {display: t.dateStr, sort: t.timestamp},
    {display: t.user ?? "", align: "text-start"},
    {display: t.description ?? "", align: "text-center"},
    {display: toCurrencyString(t.amount, "CAD"), sort: t.amount},
    {display: toCurrencyString(t.balance, "CAD"), sort: t.balance},
  ]

  const revenueMapper = (t) => [
    {display: t.dateStr, sort: t.timestamp},
    {display: t.member ?? "", align: "text-start"},
    {display: t.fee ?? "", align: "text-center"},
    {display: toCurrencyString(t.change, "CAD"), sort: t.change},
    {display: toCurrencyString(t.balance, "CAD"), sort: t.balance},
  ]

  const otherRevenueMapper = (t) => [
    {display: t.dateStr, sort: t.timestamp},
    {display: t.member ?? "", align: "text-start"},
    {display: t.fee ?? "", align: "text-center"},
    {display: t.note ?? "", align: "text-center"},
    {display: toCurrencyString(t.change, "CAD"), sort: t.change},
    {display: toCurrencyString(t.balance, "CAD"), sort: t.balance},
  ]

  const membersPayableMapper = (t) => [
    {display: t.account, ref: t.userId},
    {display: toCurrencyString(t.start, "CAD"), sort: t.start},
    {display: toCurrencyString(t.debit, "CAD"), sort: t.debit},
    {display: toCurrencyString(t.credit, "CAD"), sort: t.credit},
    {display: toCurrencyString(t.end, "CAD"), sort: t.end},
  ]

  const debitCreditMapper = (t) => [
    t.account,
    {display: toCurrencyString(t.start, "CAD"), sort: t.start},
    {display: toCurrencyString(t.debit, "CAD"), sort: t.debit},
    {display: toCurrencyString(t.credit, "CAD"), sort: t.credit},
    {display: toCurrencyString(t.end, "CAD"), sort: t.end},
  ]

  const depositTransactionMapper = (t) => [
    {display: t.dateStr, sort: t.timestamp},
    {display: t.user ?? "", align: "text-start"},
    {display: t.note, align: "Text-center"},
    {display: toCurrencyString(t.amount, "CAD"), sort: t.amount},
    {display: toCurrencyString(t.balance, "CAD"), sort: t.balance},
  ]

  // Table State - Memos for holding the state of the body and headers of the table
  const tableBody = useMemo(() => {
    switch(currentTable){
      default:
      case AccountingTableTypes.Overview:
        return <SummaryTable summaries={summaries} onDrill={onDrill} />
      case AccountingTableTypes.CashIn:
        return (
          <BreakdownTable
            breakdown={breakdowns.cashIn}
            summary={summaries.cashIn}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            onDrill={drillDeposit}
            mapper={debitCreditMapper}
            width="5"
          />
        )
      case AccountingTableTypes.DepositTransactions:
        return (
          <BreakdownTable 
            breakdown={transactions.cashIn.transactions ?? []}
            summary={{start: transactions.cashIn.start ?? 0}}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            mapper={depositTransactionMapper}
            width="5"
            isTransactionHistory
          />
        )
      case AccountingTableTypes.MembersPayable:
        return (
          <BreakdownTable
            breakdown={breakdowns.membersPayable}
            summary={summaries.membersPayable}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            mapper={membersPayableMapper}
            onDrill={drillMember}
            width="5"
          />
        )
      case AccountingTableTypes.TaxesPayable:
        return (
          <BreakdownTable
            breakdown={breakdowns.taxesPayable}
            summary={summaries.taxesPayable}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            mapper={debitCreditMapper}
            onDrill={drillTaxes}
            width="5"
          />
        )
      case AccountingTableTypes.IntrosPayable:
        return (
          <BreakdownTable 
            breakdown={breakdowns.introsPayable}
            summary={summaries.introsPayable}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            mapper={introMapper}
            width="6"
            isTransactionHistory
          />
        )
      case AccountingTableTypes.MemberTransactions:
        return (
          <BreakdownTable 
            breakdown={transactions.membersPayable.transactions ?? []}
            summary={{start: transactions.membersPayable.start ?? 0}}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            mapper={memberTransactionMapper}
            width="4"
            isTransactionHistory
          />
        )
      case AccountingTableTypes.TaxTransactions:
        return (
          <BreakdownTable 
            breakdown={transactions.taxesPayable.transactions ?? []}
            summary={{start: transactions.taxesPayable.start ?? 0}}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            mapper={taxTransactionMapper}
            width="5"
            isTransactionHistory
          />
        )
      case AccountingTableTypes.Revenue:
      case AccountingTableTypes.TowRevenue:
      case AccountingTableTypes.RentalRevenue:
      case AccountingTableTypes.IntroRevenue:
      case AccountingTableTypes.OtherRevenue:
        const accountName = RevenueAccounts[currentTable]
        if(!accountName) return (<></>)

        const isOtherRevenue = currentTable === AccountingTableTypes.OtherRevenue

        return (
          <BreakdownTable
            breakdown={breakdowns[accountName]}
            summary={summaries[accountName]}
            sortColumn={sortColumn}
            sortAscending={sortAscending}
            mapper={isOtherRevenue ? otherRevenueMapper : revenueMapper}
            width={isOtherRevenue ? 6 : 5}
            filters={[{col: 2, value: feeFilter}, {col: 3, value: fuzzyNoteFilter, fuzzy: true}]}
            isTransactionHistory
          />
        )
    }
  }, [
    currentTable,
    summaries,
    breakdowns,
    sortAscending,
    sortColumn,
    feeFilter,
    fuzzyNoteFilter,
    drillMember,
    drillTaxes,
    drillDeposit,
    onDrill,
    transactions
  ])

  const tableHeaders = useMemo(() => {
    switch(currentTable){
      default:
      case AccountingTableTypes.CashIn:
      case AccountingTableTypes.MembersPayable:
        return [
          {title: "Account", sort: "string"},
          {title: "Starting Balance", sort: "number"},
          {title: "Debits", sort: "number"},
          {title: "Credits", sort: "number"},
          {title: "Ending Balance", sort: "number"}
        ]
      case AccountingTableTypes.IntrosPayable:
        return [
          {title: "Date", sort: "number"},
          {title: "Buyer", sort: "string"},
          {title: "Serial No.", sort: "string"},
          {title: "Note", sort: "string"},
          {title: "Change", sort: "number"},
          {title: "Balance", sort: "number"}
        ]
      case AccountingTableTypes.Revenue:
      case AccountingTableTypes.TowRevenue:
      case AccountingTableTypes.RentalRevenue:
      case AccountingTableTypes.IntroRevenue:
        return [
          {title: "Date", sort: "number"},
          {title: "Member", sort: "string"},
          {title: "Fee Name", sort: "string"},
          {title: "Value", sort: "number"},
          {title: "Balance", sort: "number"}
        ]
      case AccountingTableTypes.OtherRevenue:
        return [
          {title: "Date", sort: "number"},
          {title: "Member", sort: "string"},
          {title: "Fee Name", sort: "string"},
          {title: "Note", sort: "string"},
          {title: "Value", sort: "number"},
          {title: "Balance", sort: "number"}
        ]
      case AccountingTableTypes.Overview:
        return [
          {title: "Account"},
          {title: "Starting Balance"},
          {title: "Debits"},
          {title: "Credits"},
          {title: "Ending Balance"}
        ]
      case AccountingTableTypes.MemberTransactions:
        return [
          {title: "Date", sort: "number"},
          {title: "Activity"},
          {title: "Change", sort: "number"},
          {title: "Balance", sort: "number"},
        ]
      case AccountingTableTypes.TaxTransactions:
        return [
          {title: "Date", sort: "number"},
          {title: "Member"},
          {title: "Transaction Type"},
          {title: "Change", sort: "number"},
          {title: "Balance", sort: "number"},
        ]
      case AccountingTableTypes.DepositTransactions:
        return [
          {title: "Date", sort: "number"},
          {title: "Member"},
          {title: "Note"},
          {title: "Change", sort: "number"},
          {title: "Balance", sort: "number"},
        ]
    }
  }, [currentTable])

  const updateSort = (index, sort) => {
    if(sortColumn === index){
      setSortAscending(s => !s)
    } else {
      setSortColumn(index)
      setSortAscending(sort === 'string' ? false : true)
    }
  }

  const backToButton = useMemo(() => {
    let label = "Back to Overview"
    let link = AccountingTableTypes.Overview
    if(currentTable === AccountingTableTypes.MemberTransactions) {
      label = "Back to Members A/P"
      link = AccountingTableTypes.MembersPayable
    } else if(currentTable === AccountingTableTypes.TaxTransactions) {
      label = "Back to Sales Tax A/P"
      link = AccountingTableTypes.TaxesPayable
    } else if(currentTable === AccountingTableTypes.DepositTransactions) {
      label = "Back to Cash In"
      link = AccountingTableTypes.CashIn
    }

    return (
      <Button onClick={() => onDrill(link)} variant="secondary" className="py-1">
        {label}
      </Button>
    )
  }, [currentTable, onDrill])

  return (
    <Table bordered>
      <thead>
        <tr>
          <td colSpan="6">
            <div className="table-title">
              <div className="d-flex flex-row">
                {currentTable !== AccountingTableTypes.Overview && backToButton}
              </div>
              <h2 className="fs-4 my-1">{tableName}</h2>
              <div className="flex-fill-sm d-flex flex-row" aria-hidden>
              </div>

              {currentTable === AccountingTableTypes.OtherRevenue &&
                <div className="note-filter">
                  <InputGroup>
                    <InputGroup.Text>Search Notes:</InputGroup.Text>
                    <Form.Control value={noteFilter} onChange={(e) => setNoteFilter(e.target.value)} />
                    { noteFilter &&
                      <InputGroup.Text className="px-1 bg-danger text-white">
                        <CloseButton variant="white" onClick={() => setNoteFilter("")} />
                      </InputGroup.Text>
                    }
                  </InputGroup>

                  <InputGroup>
                    <InputGroup.Text>Filter by:</InputGroup.Text>
                    <Form.Select aria-label="Filter list" value={feeFilter} onChange={(e) => setFeeFilter(e.target.value)}>
                      {feeFilterOptions.map((option) => 
                        <option key={option} value={option}>{option}</option>
                      )}
                    </Form.Select>
                  </InputGroup>
                </div>
              } 
            </div>
          </td>
        </tr>
        {!isLoading &&
          <tr className="text-start">
            {tableHeaders.map((h, i) => 
              <td role={h.sort ? "button" : "cell"} onClick={() => {if(h.sort) updateSort(i, h.sort)}} className="bg-dark text-white" key={i}>
                <div className="d-flex flex-row justify-content-between no-user-select">
                  {h.title}
                  {h.sort && 
                    <span className={`${sortAscending ? '' : 'sort-arrow-descending'} ${sortColumn === i ? 'opacity-100' : 'opacity-0'}`} >
                      ▲
                    </span>
                  }
                </div>
              </td>
            )}
          </tr>
        }
      </thead>
      {isLoading ?
        <tbody><tr><td><Spinner /></td></tr></tbody> :
        tableBody
      }
    </Table>
  );
}
