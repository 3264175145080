import React, { useState, useEffect } from "react";
import { FormControl, FormGroup, ToggleButton, ToggleButtonGroup, FormLabel } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { RequestType, apiRequest, loadPilots } from "../libs/databaseAccess";
import LoaderButton from "../components/LoaderButton";
import "./Test.css";
import {CSVLink} from "react-csv";
import { DateTime } from "luxon";

export default function Export(){
  const { club, isAuthenticated, userIsAdmin, clubTimeZone } = useAppContext();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportType, setExportType] = useState("Flights");
  const [loading, setLoading] = useState(false);
  const [csvReport, setCSVReport] = useState(null);
  const [pilots, setPilots] = useState([]);

  useEffect(()=>{
    if(!isAuthenticated || !userIsAdmin){
      return;
    } 
    const today = DateTime.fromObject({}, {zone: clubTimeZone})
    setStartDate(today.toISODate());
    setEndDate(today.toISODate());
  },[club, userIsAdmin, isAuthenticated, clubTimeZone]);

  async function loadData(){
    setCSVReport(null);
    setLoading(true);
    
    let pilotList = pilots;
    if(pilots.length < 1) {
      pilotList = await loadPilots(club);
      setPilots(pilotList); 
    }

    if(exportType === "Flights"){
      loadFlightData(false, pilotList);
    }
    else if (exportType === "All Flights"){
      loadFlightData(true, pilotList);
    }
    else {
      loadTransactionData(pilotList);
    }
  }

  async function loadFlightData(includeCancelled, pilotList){
    let startDateTime, endDateTime;
    if(startDate!==""){
      const start = DateTime.fromISO(startDate, {zone: clubTimeZone}).startOf("day");
      const end = DateTime.fromISO(endDate, {zone: clubTimeZone}).endOf("day");
      startDateTime = start.toMillis();
      endDateTime = end.toMillis();
      if(startDateTime>endDateTime){
        startDateTime = end.startOf("day").toMillis();
        setStartDate(end.toISODate());
      }
    }
    else{
      return;
    }
    const params = {
      club: club,
      startDate: startDateTime,
      endDate: endDateTime,
    }
    let flights = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-flights/", options: {queryParams:params}});
    if(!includeCancelled) {
      flights.body = flights.body.filter(item => !item.cancelledAt)
    }

    const data = flights.body.map((item)=>{
      const flightTime = item.launchedAt & item.landedAt ? (item.landedAt - item.launchedAt)/1000/3600 : 0;
      const towPilot = pilotList.find((a)=>(a.SK===item.towPilot))
      return({
      "date":item.PK.slice(-10),
      "first":item.first,
      "last":item.last,
      "glider":item.glider,
      "flyWith":item.flyWith,
      "passengerFirst":item.passengerFirst,
      "passengerLast":item.passengerLast,
      "cancelledAt": item.cancelledAt,
      "launchedAt": item.launchedAt,
      "landedAt":item.landedAt,
      "flightTime": flightTime,
      "launchType":item.launchType,
      "launchHeight": item.releaseHeight ? item.releaseHeight : 0,
      "pilotInCharge":item.pilotInCharge ? item.pilotInCharge.toString() : "true",
      "shareCost":item.shareCost ? item.shareCost.toString() : "false",
      "towPilot": towPilot ? `${towPilot.first} ${towPilot.last}` : item.towPilot,
      "tug":item.tug,
    })});
    let headers = [
      {label: "Date", key: "date"},
      {label: "Pilot First Name", key: "first"},
      {label: "Pilot Last Name", key: "last"},
      {label: "Glider", key: "glider"},
      {label: "Flying With", key: "flyWith"},
      {label: "Passenger First Name", key: "passengerFirst"},
      {label: "Passenger Last Name", key: "passengerLast"},
      {label: "Launch Time", key: "launchedAt"},
      {label: "Landed At", key: "landedAt"},
      {label: "Flight Duration [hr]", key: "flightTime"},
      {label: "Launch Type", key: "launchType"},
      {label: "Launch Height", key: "launchHeight"},
      {label: "Pilot in Charge?", key: "pilotInCharge"},
      {label: "Sharing Cost?", key: "shareCost"},
      {label: "Tow Pilot", key: "towPilot"},
      {label: "Tug", key: "tug"},
    ]
    if(includeCancelled) {
      headers.push({label: "Cancelled At", key: "cancelledAt"})
    }

    const report = {
      data: data,
      headers: headers,
      filename: exportType+"_"+startDate+"_"+endDate+".csv"
    }
    setCSVReport(report);
    setLoading(false);
  }

  async function loadTransactionData(pilotList){
    let startDateTime, endDateTime;
    if(startDate!==""){
      const start = DateTime.fromISO(startDate, {zone: clubTimeZone}).startOf("day");
      const end = DateTime.fromISO(endDate, {zone: clubTimeZone}).endOf("day");
      startDateTime = start.toMillis();
      endDateTime = end.toMillis();
      if(startDateTime>endDateTime){
        startDateTime = end.startOf("day").toMillis();
        setStartDate(end.toISODate());
      }
    }
    else{
      return;
    }
    const params = {
      club: club,
      startDate: startDateTime,
      endDate: endDateTime
    }
    const allTransactionsResponse = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-all-transactions/", options: {queryParams:params}});
    const chartOfAccounts = allTransactionsResponse.body.chartOfAccounts
    const data = allTransactionsResponse.body.transactions.map((item)=>{
      const dateObj = DateTime.fromMillis(item.timestamp, {zone: clubTimeZone});
      let userName = item.userId
      if(userName !== "Intro") {
        const pilot = pilotList.find((p) => p.SK === userName)
        userName = pilot ? `${pilot.first} ${pilot.last}` : item.userId
      }
      return ({
      "date":dateObj.toISODate(),
      "rawTime": item.timestamp,
      "accountName":chartOfAccounts[item.account].name,
      "account":item.account,
      "debit":item.direction > 0 ? item.amount : 0,
      "credit":item.direction < 0 ? item.amount : 0,
      "userID":userName,
      "note":item.note,
      "reference":item.transactionRef,
      "reason": item.transactionType,
      "type":item.transferType,
    })});
    const headers = [
      {label: "Date", key: "date"},
      {label: "Account", key: "accountName"},
      {label: "Account Number", key: "account"},
      {label: "Debit Amount", key: "debit"},
      {label: "Credit Amount", key: "credit"},
      {label: "Transaction Reason", key: "reason"},
      {label: "Epoch time", key: "rawTime"},
      {label: "User", key: "userID"},
      {label: "Transaction Type", key: "type"},
      {label: "Transaction Reference", key: "reference"},
      {label: "Note", key: "note"}
    ]
    const report = {
      data: data,
      headers: headers,
      filename: exportType+"_"+startDate+"_"+endDate+".csv"
    }
    setCSVReport(report);
    setLoading(false);
  }

  return(
    <>
      <h2>Export Club Data</h2>
      <FormGroup controlId="startDate" style={{display:'inline-block', maxWidth:'50%'}} size="lg">
        <FormLabel>Start Date</FormLabel>
        <FormControl
          autoFocus
          type="date"
          value={startDate}
          disabled={startDate === ""}
          onChange={e => setStartDate(e.target.value)}
        />
      </FormGroup>
      <FormGroup controlId="endDate" style={{display:'inline-block', maxWidth:'50%'}} size="lg">
        <FormLabel>End Date</FormLabel>
        <FormControl
          type="date"
          value={endDate}
          disabled={endDate === ""}
          onChange={e => setEndDate(e.target.value)}
        />
      </FormGroup>
      <br></br>
      <FormGroup controlId="splitCost" size="lg"><h4>Choose Data to Export:  </h4>
        <ToggleButtonGroup name="splitFormCheck" type="radio" value={exportType} onChange={setExportType}>
          <ToggleButton id="all" value={"All Flights"}>Flights (including cancelled)</ToggleButton>
          <ToggleButton id="completed" value={"Flights"}>Completed Flights</ToggleButton>
          <ToggleButton id="transactions" value={"Transactions"}>Transactions</ToggleButton>
        </ToggleButtonGroup>
      </FormGroup>
      <LoaderButton size="lg" onClick={() => loadData()} isLoading={loading}>Load Data for Export</LoaderButton>
      <br></br>
      {!loading && csvReport && <CSVLink {...csvReport}>Download Exported Data</CSVLink>}
    </>
  );
}
