import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, FormControl, FormGroup, InputGroup, Modal, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { RequestType, apiRequest, deleteLaunch } from "../libs/databaseAccess";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { MultiSelect } from "react-multi-select-component";
import { useAppContext } from "../libs/contextLib";
import "./EditModal.css"
import { isEqual } from "lodash";
import { formatTaxOptions, toCurrencyString } from "../libs/utils";

const LaunchTypes = {
  Variable: 'Variable Height',
  Known: 'Known Height'
}

const FootDefaults = {
  size: 1000,
  per: 100
}

const MeterDefaults = {
  size: 300,
  per: 30
}

export default function EditLaunchModal({showModal, closeModal, launch, refreshLaunchList}) {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const {club, clubTaxRates, clubUnits} = useAppContext()

  const [launchID, setLaunchID] = useState("");
  const [launchPrice, setLaunchPrice] = useState(0);
  const [launchName, setLaunchName] = useState("");
  const [launchHeight, setLaunchHeight] = useState(0);
  const [launchHeightUnit, setLaunchHeightUnit] = useState("");
  const [isDeleting, setIsDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [taxStatus, setTaxStatus] = useState("exempt")
  const [taxList, setTaxList] = useState([])
  const [nextRangeId, setNextRangeId] = useState(0)

  const [launchType, setLaunchType] = useState(LaunchTypes.Known)
  const [variableHeights, setVariableHeights] = useState([{id: 0, start: 0, end: FootDefaults.size, cost: 0, per: FootDefaults.per}])

  const modalTitle = useMemo(() => {
    if(!launch) return "Edit Launch";
    if(launch.SK === "new") return "Create New Launch"
    return `Edit ${launch.SK}`
  }, [launch])

  const heightUnit = useMemo(() => {
    if(!clubUnits) return "ft"
    return clubUnits.height ? clubUnits.height.S : "ft"
  }, [clubUnits])

  const taxOptions = useMemo(() => {
    return formatTaxOptions(clubTaxRates)
  }, [clubTaxRates])
  
  const taxSelectRenderer = (selected) => {
    if(selected.length < 1) return "None";
    let str = "";
    for(const i in selected) {
      str += selected[i].value
      if(i < selected.length - 1) {
        str += ", "
      }
    }
    return str
  }

  const confirmDelete = async () => {
    const result = await deleteLaunch(club, launchID);

    if(result.statusCode !== 200) {
      await refreshLaunchList()
      sendToast({
        status: NotificationStatus.Success,
        message: "Launch deleted successfully"
      })
      closeModal()
    } else {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not delete launch"
      })
    }

    setIsDeleting(false); 
  }

  async function deleteRecord() {
    setIsDeleting(true);     // Start button spinner
    sendConfirmModal({
      heading: "Delete Launch?",
      body: "Last chance - Are you sure you want to delete this record?",
      confirmText: "Delete",
      rejectText: "Cancel",
      confirmColor: "danger",
      confirm: confirmDelete,
      reject: () => setIsDeleting(false)
    })
  }

  async function updateLaunchRecord() {
    setIsLoading(true);
    // Build up body for API Call
    const parsedFloat = !isNaN(parseFloat(launchPrice)) ? parseFloat(launchPrice) : launch.price;
    const data = {
      price: parsedFloat,
      units: launchHeightUnit
    }

    if(taxStatus !== launch.taxStatus) {
      data.taxStatus = taxStatus
    }
    const taxRates = taxList.map((e) => e.value)
    if(!isEqual(taxRates, launch.taxRates)) {
      data.taxRates = taxRates
    }

    if(launchType === LaunchTypes.Known) {
      data.height = launchHeight
      delete data.heightRanges
    }

    if(launchType === LaunchTypes.Variable) {
      delete data.height
      data.heightRanges = variableHeights.sort((a, b) => a.start - b.start)
        .map((range) => {
          const newRange = {...range}
          delete newRange.id
          return newRange
        })
    }

    const body = {
      club: club,
      type: "LAUNCH",
      id: (launchID !== "new" ? launchID : launchName),
      data: data,
    };

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {"body": body}}
    )
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not update launch"
      })
    } else {
      await refreshLaunchList()
      sendToast({
        status: NotificationStatus.Success,
        message: launchID !== "new" ? "Launch updated successfully" : "Launch created successfully"
      })
      closeModal()
    }
  }

  useEffect(() => {
    if(!launch || !launch.SK) return;

    setLaunchID(launch.SK)
    if (launch.SK !== "new") {
      setLaunchPrice(("price" in launch) ? launch.price : 0);
      setTaxStatus(launch.taxStatus ?? "exempt");

      const taxRates = launch.taxRates ?? []
      setTaxList(taxRates.map((r) => 
        taxOptions.find((o) => o.value === r) ?? {label: "Unknown", value: r}
      ))

      if(Array.isArray(launch.heightRanges)) {
        setLaunchType(LaunchTypes.Variable)
        setLaunchHeight(launch.height ?? "");
        setLaunchHeightUnit("");

        let rangeId = 0
        setVariableHeights(launch.heightRanges.map((range) => {
          // Inject ids into the api response so we can check for changes
          range.id = rangeId
          rangeId++
          return range
        }))
        setNextRangeId(rangeId)
      } else {
        setLaunchType(LaunchTypes.Known)
        setLaunchHeight(("height" in launch) ? launch.height : 0);
        setLaunchHeightUnit(("units" in launch) ? launch.units: "");
        setVariableHeights([{start: 0, end: 1000, cost: 0, per: 10}])
      }

    } else {
      setLaunchPrice(0);
      setLaunchHeight(0);
      setLaunchHeightUnit("");
      setTaxStatus("exempt")
      setLaunchType(LaunchTypes.Known)
      if(heightUnit === "m"){
        setVariableHeights([{start: 0, end: MeterDefaults.size, cost: 0, per: MeterDefaults.per}])
      } else {
        setVariableHeights([{start: 0, end: FootDefaults.size, cost: 0, per: FootDefaults.per}])
      }
    }

    setIsLoading(false);
    setIsDeleting(false);
  }, [launch, taxOptions, heightUnit])


  const taxStatusChanged = useMemo(() => taxStatus !== launch.taxStatus, [taxStatus, launch])
  const taxRatesChanged = useMemo(() => {
    const taxRates = taxList.map((e) => e.value)
    const launchTaxRates = launch.taxRates ?? []
    return !isEqual(taxRates, launchTaxRates)
  }, [taxList, launch])

  const launchTypeChanged = useMemo(() => {
    return (Array.isArray(launch.heightRanges) && launchType === LaunchTypes.Known) ||
    (!Array.isArray(launch.heightRanges) && launchType === LaunchTypes.Variable)
  }, [launchType, launch])

  const heightRangesChanged = useMemo(() => {
    return !isEqual(launch.heightRanges, variableHeights) && launchType === LaunchTypes.Variable
  }, [launch, variableHeights, launchType])

  const launchUpdated = useMemo(() => {
    return (
      (!isNaN(parseFloat(launchPrice)) ? parseFloat(launchPrice) : launch.price) !== launch.price) ||
      (launchID === "new") ||
      (launchHeight !== launch.height) ||
      (launchHeightUnit !== launch.units) ||
      taxStatusChanged || taxRatesChanged ||
      heightRangesChanged ||
      launchTypeChanged
  }, [launch, launchPrice, launchHeight, launchID, launchHeightUnit, taxStatusChanged, taxRatesChanged, heightRangesChanged, launchTypeChanged])

  const addVariableHeightRow = (i) => {
    const defaults = heightUnit === "m" ? MeterDefaults : FootDefaults 
    setVariableHeights(h => {
      const heights = [...h]
      let start = heights[i].end
      let end = start + defaults.size

      heights.splice(i + 1, 0, {id: nextRangeId, start, end, cost: 0, per: defaults.per})
      return [...heights]
    })
    setNextRangeId((i) => i + 1)
  }
  
  const removeVariableHeightRow = (i) => {
    setVariableHeights(h => {
      const heights = [...h]
      heights.splice(i, 1)
      return heights
    })
  }

  const updateListItem = (index, field, value) => {
    let formattedValue = value;

    if(field !== "cost" && value !== "") {
      formattedValue = parseInt(value)
    }

    if(formattedValue < 0) {
      formattedValue = Math.abs(formattedValue)
    }

    setVariableHeights(hs => {
      return hs.map((r, i) => {
        let newR = r
        if(i === index) {
          newR = {...r}
          newR[field] = formattedValue
        }
        return newR
      })
    })
  }

  const heightErrors = useMemo(() => {
    const points = []
    const errors = {}
    let errorMsg = ""
    const overlapErrorMsg = "Height ranges can only overlap by one"

    for(const i in variableHeights) {
      points.push({p: variableHeights[i].start, i, d: "start"})
      points.push({p: variableHeights[i].end, i, d: "end"})

      if(variableHeights[i].end <= variableHeights[i].start) {
        errors[i] = "range"
        errorMsg = '"To" height must be greater than "From" height'
      }
      if(Math.floor((variableHeights[i].end - variableHeights[i].start) / variableHeights[i].per) < 1) {
        errors[i] = "per"
        errorMsg = `"Per" segment cannot be larger than the range`
      }
      if(variableHeights[i].per < 1) {
        errors[i] = "per"
        errorMsg = `Cost must be "per" at least 1 ${heightUnit}`
      }
    }

    points.sort((a,b) => {
      if(a.p === b.p) {
        return a.d === b.d ? 0 : a.d === "start" ? -1 : 1
      } else {
        return a.p - b.p
      }
    })

    let current = -1
    for(let i = 0; i < points.length; i++) {
      const point = points[i]

      if(point.d === "start") {
        if(current === -1) {
          current = point.i
        } else if(variableHeights[current].end === point.p) {
          current  = point.i
        } else {
          errorMsg = overlapErrorMsg
          errors[point.i] = "range"
          errors[current] = "range"

          if(variableHeights[point.i].end > variableHeights[current].end) {
            current = point.i
          }
        }
      } else if(point.i === current) {
        current = -1
      }
    }
    return {positions: errors, message: errorMsg}
  }, [variableHeights, heightUnit])

  const variableHeightChanges = useMemo(() => {
    const changeList = []
    if(!launch || !launch.heightRanges || isEqual(variableHeights, launch.heightRanges)){
      return changeList
    }

    let oldIdx = 0;
    let newIdx = 0;
    while(newIdx < variableHeights.length || oldIdx < launch.heightRanges.length) {
      const oldRange = launch.heightRanges[oldIdx] ?? {}
      const newRange = variableHeights[newIdx] ?? {}
      
      if(isEqual(oldRange, newRange)){
        newIdx++
        oldIdx++
        continue
      }

      if(oldRange.id === newRange.id) {
        if(oldRange.start !== newRange.start) {
          changeList.push(
            `Changed height range #${newIdx + 1} start from ${oldRange.start} ${heightUnit} to ${newRange.start} ${heightUnit}`
          )
        }
        if(oldRange.end !== newRange.end) {
          changeList.push(
            `Changed height range #${newIdx + 1} end from ${oldRange.end} ${heightUnit} to ${newRange.end} ${heightUnit}`
          )
        }
        if(oldRange.cost !== newRange.cost) {
          changeList.push(
            `Changed height range #${newIdx + 1} price from ${toCurrencyString(oldRange.cost, "CAD", true)} to ${toCurrencyString(newRange.cost, "CAD", true)}`
          )
        }
        if(oldRange.per !== newRange.per) {
          changeList.push(
            `Changed height range #${newIdx + 1} per unit from ${oldRange.per} ${heightUnit} to ${newRange.per} ${heightUnit}`
          )
        }
        newIdx++
        oldIdx++
        continue
      }

      if(oldRange.id < newRange.id || (oldRange.id !== undefined && newRange.id === undefined)){
        changeList.push(`Removed height range from ${oldRange.start}${heightUnit} to ${oldRange.end}${heightUnit}`)
        oldIdx++
        continue
      }

      if(launch.heightRanges.length - 1 < newRange.id){
        changeList.push(`Added height range from ${newRange.start}${heightUnit} to ${newRange.end}${heightUnit}`)
        newIdx++
        continue
      }
      newIdx++
      oldIdx++
    }

    return changeList
  }, [variableHeights, launch, heightUnit])

  const renderVariableHeightPicker = () => {
    return (
      <InputGroup>
        <ul className="d-flex flex-column gap-1 list-unstyled mb-0">
          {variableHeights.map((h, i) => 
            <li key={i} >
              <div className="d-flex flex-row gap-2">
                <InputGroup >
                  <InputGroup.Text>From:</InputGroup.Text>
                  <FormControl
                    type="number"
                    inputMode="numeric"
                    as="input"
                    value={h.start}
                    min="0"
                    className={`${heightErrors.positions[i] === "range" ? 'bg-danger text-white' : ""}`}
                    onChange={e => updateListItem(i, "start", e.target.value)}
                  />
                  <InputGroup.Text>{heightUnit}</InputGroup.Text>
                  <InputGroup.Text>To:</InputGroup.Text>
                  <FormControl
                    type="number"
                    inputMode="numeric"
                    as="input"
                    value={h.end}
                    className={`${heightErrors.positions[i] === "range" ? 'bg-danger text-white' : ""}`}
                    onChange={e => updateListItem(i, "end", e.target.value)}
                  />
                  <InputGroup.Text>{heightUnit}</InputGroup.Text>
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <FormControl
                    type="number"
                    inputMode="decimal"
                    as="input"
                    value={h.cost}
                    onChange={e => updateListItem(i, "cost", e.target.value)}
                    />
                  <InputGroup.Text>per</InputGroup.Text>
                  <FormControl
                    type="number"
                    inputMode="numeric"
                    as="input"
                    value={h.per}
                    className={`${heightErrors.positions[i] === "per" ? 'bg-danger text-white' : ""}`}
                    onChange={e => updateListItem(i, "per", e.target.value)}
                  />
                  <InputGroup.Text>{heightUnit}</InputGroup.Text>
                </InputGroup>
                { variableHeights.length > 1 &&
                  <Button
                    aria-label="Remove Row"
                    variant="danger"
                    className="fw-bold fs-2 py-0 px-2 lh-1"
                    onClick={() => removeVariableHeightRow(i)}
                  >−</Button>
                }
              </div>
            </li>
          )}
          <Form.Text className="text-danger m-0">{heightErrors.message}</Form.Text>
          <Button
            className="mt-0"
            variant="success"
            onClick={() => addVariableHeightRow(variableHeights.length - 1)}
          >Add new Height Range</Button>
        </ul>
      </InputGroup>
    )
  }

  return (
    <Modal show={showModal} size="lg" fullscreen="lg-down" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{minWidth: launchType === LaunchTypes.Variable ? "44rem" : "2rem"}}>
          <FormGroup className="d-flex flex-column gap-3" controlId="launchInfo" size="lg">
            { launchID === "new" && 
              <>
              <h4><b>WARNING:</b> Launch label cannot be changed once entered</h4>
              <InputGroup>
              <InputGroup.Text>Launch label</InputGroup.Text>
              <FormControl
                type="text"
                value={launchName}
                onChange={e => setLaunchName(e.target.value)}
              />
              </InputGroup>
              </>
            }

            <ToggleButtonGroup  name="launchTypeCheck" type="radio" value={launchType} onChange={(e) => setLaunchType(e)}>
              <InputGroup.Text>Launch Type:</InputGroup.Text>
              <ToggleButton id={LaunchTypes.Known} variant="outline-primary" name="launchTypeCheck" value={LaunchTypes.Known}>Fixed</ToggleButton>
              <ToggleButton id={LaunchTypes.Variable} variant="outline-primary" name="launchTypeCheck" value={LaunchTypes.Variable}>Variable</ToggleButton>
            </ToggleButtonGroup>

            <InputGroup>
              <InputGroup.Text>{launchType === LaunchTypes.Known ? "Price per launch" : "Connection Cost"}</InputGroup.Text>
              <InputGroup.Text>$</InputGroup.Text>
              <FormControl
                type="number"
                inputMode="decimal"
                as="input"
                value={launchPrice}
                onChange={e => setLaunchPrice(e.target.value)}
              />
            </InputGroup>

            {launchType === LaunchTypes.Variable && renderVariableHeightPicker()}
              
            { launchType === LaunchTypes.Known &&
              <InputGroup>
                <InputGroup.Text>Expected Height</InputGroup.Text>
                <FormControl
                  type="number"
                  inputMode="numeric"
                  as="input"
                  value={launchHeight}
                  onChange={e => setLaunchHeight(e.target.value)}
                />
                <InputGroup.Text>{heightUnit}</InputGroup.Text> 
              </InputGroup>
            }

            <ToggleButtonGroup  name="taxStatusCheck" type="radio" value={taxStatus} onChange={(e) => setTaxStatus(e)}>
              <InputGroup.Text>Tax Status:</InputGroup.Text>
              <ToggleButton id="exempt" variant="outline-primary" name="taxStatusCheck" value="exempt">Tax Exempt</ToggleButton>
              <ToggleButton id="inclusive" variant="outline-primary" name="taxStatusCheck" value="inclusive">Tax Inclusive</ToggleButton>
              <ToggleButton id="exclusive" variant="outline-primary" name="taxStatusCheck" value="exclusive">Tax Exclusive</ToggleButton>
            </ToggleButtonGroup>

            {taxStatus !== "exempt" && taxStatus !== undefined &&
              <InputGroup>
                <InputGroup.Text>Applicable Taxes:</InputGroup.Text>
                <MultiSelect
                  className="flex-grow-1 flex-shrink-1 multi-select"
                  disableSearch
                  hasSelectAll={false}
                  options={taxOptions}
                  value={taxList}
                  onChange={setTaxList}
                  valueRenderer={taxSelectRenderer}
                />
              </InputGroup>
            }
          </FormGroup> 

          {launchUpdated && 
            <>
              <hr></hr>
              <div className="fs-5 mb-2 fw-semibold">Confirm the following changes to launch: {launchID}</div>
              <ol className="mb-0">
                {((!isNaN(parseFloat(launchPrice)) ? parseFloat(launchPrice) : launch.price) !== launch.price) && 
                  <li>{launchType === LaunchTypes.Variable ? "Connection Cost" : "Price"} changed to {(!isNaN(parseFloat(launchPrice)) ? parseFloat(launchPrice) : launch.price)}</li>
                }
                {(launchHeight !== launch.height || launchHeightUnit !== launch.units) && launchType === LaunchTypes.Known &&
                  <li>Height changed to {launchHeight} {launchHeightUnit}</li>
                }
                {(taxStatusChanged) && <li>Tax status changed to {taxStatus}</li>}
                {(taxRatesChanged) && <li>Applicable taxes changed to {taxSelectRenderer(taxList)}</li>}
                {(launchID === "new") && <li>New launch record</li>}
                {(variableHeightChanges.length > 0 && launchType === LaunchTypes.Variable) && 
                  variableHeightChanges.map((change, i) => (
                    <li key={i}>{change}</li>
                  ))
                }
                {launchTypeChanged && <li>Launch type changed to {launchType}</li>}
              </ol>
            </>
          }
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-row justify-content-between w-100">
          <div>
            { (launchID !== "new") &&
              <LoaderButton type="button" variant="danger" isLoading={isDeleting} onClick={deleteRecord}>
                Delete Launch
              </LoaderButton>
            }
          </div>
          <div className="d-flex flex-row gap-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <LoaderButton type="button" variant="primary" isLoading={isLoading} disabled={heightErrors.message} onClick={updateLaunchRecord}>
              Save Changes
            </LoaderButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}