import React, { useState, useEffect, useCallback } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { RequestType, apiRequest, loadIntroList, loadPaymentMethods } from "../libs/databaseAccess";
import "./Test.css";
import IntroSearch from "../components/IntroSearch";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";

export default function IntroRefund(){
  const { club, userID, isAuthenticated } = useAppContext();
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();

  const [intros, setIntros] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [refundAmount, setRefundAmount] = useState("")
  const [refundMethod, setRefundMethod] = useState("")
  const [refundRef, setRefundRef] = useState("")
  const [refundFirst, setRefundFirst] = useState("")
  const [refundLast, setRefundLast] = useState("")
  const [selectedIntro, setSelectedIntro] = useState(null)

  const sendRefund = async () => {
    const price = parseFloat(refundAmount)
    if(Number.isNaN(price) || refundAmount == null || refundAmount === "" || price < 0) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Invalid refund amount"
      })
      return
    }

    const body = {
      club,
      by: userID,
      type: "INTRO",
      action: "Refund",
      data: {
        id: selectedIntro.SK,
        payment: refundMethod,
        refundFirst,
        refundLast,
        refundRef,
        price
      }
    }

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {body}}
    )
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not refund intro"
      })
    } else {
      await loadIntros()
      sendToast({
        status: NotificationStatus.Success,
        message: "Intro refunded successfully"
      })
      clearFields()
    }
  }

  const confirmRefund = (e) => {
    e.preventDefault()
    sendConfirmModal({
      heading: "Refund Intro",
      body: (
        <>
          <p>
            By submitting this refund request, this flight will not be
            searchable to be redeemed, and the Intro Account Payable
            account will be reduced by the refunded amount.
          </p>
          <p>
            Refund intro <strong>{selectedIntro.SN}</strong> to <strong>{refundFirst} {refundLast}</strong> for <strong>{refundAmount}?</strong>
          </p>
        </>
      ),
      confirmText: "Refund Intro",
      confirmColor: "danger",
      confirm: sendRefund,
      rejectText: "Go Back",
      rejectColor: "secondary",
    })
  }

  const selectIntro = (intro) => {
    setSelectedIntro(intro)
    setRefundAmount(intro.price)
    setRefundMethod(intro.payment)
    setRefundRef("")
    setRefundFirst(intro.buyerFirst)
    setRefundLast(intro.buyerLast)
  }

  const clearFields = () => {
    setSelectedIntro(null)
    setRefundAmount("")
    setRefundMethod("")
    setRefundRef("")
    setRefundFirst("")
    setRefundLast("")
  }

  const loadIntros = useCallback(async () => {
    const introList = await loadIntroList(club)
    setIntros(introList)
  }, [club])

  const loadPayments = useCallback(async () => {
    const paymentMethods = await loadPaymentMethods(club)
    setPaymentMethods(paymentMethods)
  }, [club])

  useEffect(() => {
    if(!club || !isAuthenticated) return
    loadIntros()
    loadPayments()
  }, [club, isAuthenticated, loadIntros, loadPayments])

  return (
    <main className="d-flex flex-column gap-3 pt-3">
      <h2>Refund Intro</h2>
      <IntroSearch selectIntro={selectIntro} intros={intros} />
      <hr />
      <Form onSubmit={confirmRefund} className="d-flex flex-column gap-3">
        <InputGroup>
          <InputGroup.Text>Refund Amount:</InputGroup.Text>
          <Form.Control as="input" value={refundAmount} onChange={(e)=>{setRefundAmount(e.target.value)}} disabled={selectedIntro === null} />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Refund Method:</InputGroup.Text>
          <Form.Control 
            as="select"
            placeholder="select"
            disabled={selectedIntro === null}
            value={refundMethod}
            onChange={(e)=>{setRefundMethod(e.target.value)}}
          >
            {paymentMethods.map((item)=>
              <option key={item} value={item}>{item}</option>
            )}
          </Form.Control>
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Refund Reference:</InputGroup.Text>
          <Form.Control as="input" value={refundRef} onChange={(e)=>{setRefundRef(e.target.value)}} disabled={selectedIntro === null} />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Refund To:</InputGroup.Text>
          <InputGroup.Text>First Name:</InputGroup.Text>
          <Form.Control as="input" value={refundFirst} onChange={(e)=>{setRefundFirst(e.target.value)}} disabled={selectedIntro === null} />
          <InputGroup.Text>Last Name:</InputGroup.Text>
          <Form.Control as="input" value={refundLast} onChange={(e)=>{setRefundLast(e.target.value)}} disabled={selectedIntro === null} />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Serial Number:</InputGroup.Text>
          <Form.Control as="input" value={selectedIntro?.SN ?? ""} disabled />
        </InputGroup>
        <Button className="w-100" variant="danger" type="submit" disabled={selectedIntro === null}>Submit Refund</Button>
      </Form>
    </main>
  );
}