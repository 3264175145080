import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NotFound from "./containers/NotFound";
import NewPilot from "./containers/NewPilot";
import Crew from "./containers/Crew";
import Admin from "./containers/Admin";
import Ground from "./containers/Ground";
import Logs from "./containers/Logs";
import Settings from "./containers/Settings";
import Accounts from "./containers/Accounting";
import Intro from "./containers/Intro";
import ChangePassword from "./containers/ChangePassword";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./containers/ResetPassword";
import Emergency from "./containers/Emergency";
import Profile from "./containers/Profile";
import Export from "./containers/Export";
import UserAccount from "./containers/userAccount";
import Fuel from "./containers/Fuel";
import Awaiting from "./containers/Awaiting";
import AccountingAdmin from "./containers/AccountingAdmin";
import IntroRefund from "./containers/IntroRefund";
import IntroCancel from "./containers/IntroCancel";

export default function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/index" element={<Home />} />
      <Route exact path="/index.html" element={<Home />} />
      <Route element={<UnauthenticatedRoute />}>
        <Route exact path="/login/" element={<Login />} />
        <Route exact path="/signup/*" element={<Signup />} />
        <Route exact path="/login/reset" element={<ResetPassword />} />
      </Route>
      <Route element={<AuthenticatedRoute />}>
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/new pilot/" element={<NewPilot />} />
        <Route exact path="/ground/" element={<Ground />} />
        <Route exact path="/logs/" element={<Logs />} />
        <Route exact path="/emergency/" element={<Emergency />} />
        <Route exact path="/fuel/" element={<Fuel />} />
        <Route exact path="/awaiting/" element={<Awaiting />} />
        <Route exact path="/settings/profile" element={<Profile />} />
        <Route exact path="/crew/" element={<Crew />} />
        <Route exact path="/admin/" element={<Admin />} />
        <Route exact path="/export/" element={<Export />} />
        <Route exact path="/intro/" element={<Intro />} />
        <Route exact path="/intro/refund/" element={<IntroRefund />} />
        <Route exact path="/intro/cancel/" element={<IntroCancel />} />
        <Route exact path="/settings/password" element={<ChangePassword />} />
        <Route exact path="/accounts" element={<Accounts />} />
        <Route exact path="/accountingAdmin" element={<AccountingAdmin />} />
        <Route exact path="/userAccount" element={<UserAccount />} />
      </Route>
      {/* Finally, catch all unmatched routes */}
      <Route element={<NotFound />} />
    </Routes>
  );
}