import React from 'react';
import { isoTimeToLocaleTime } from '../libs/utils';

const SelectableTable = (props) => {
  const { handler, headers, items, rows } = props;
  return (
    <div>
      <table className="table table-bordered table-hover">
      <TableHeader headers={headers}></TableHeader>
      <TableBody handler={handler} headers={headers} items={items} rows={rows}></TableBody>
      </table>
    </div>
  );
}

const TableHeader = (props) => {
  const { headers } = props;
  return(
    <thead className="thead-dark" key="header-1">
        <tr key="header-0">
          { headers && headers.map((value, index) => {
              return <th className="text-center" key={index}><div>{value}</div></th>
          })}
        </tr>
    </thead>
  );
}

const TableBody = (props) => {
  const { handler, headers, items, rows } = props;
  const columns = headers ? headers.length : 0;
  const showSpinner = rows === null;

  function buildRow(row, items, i) {
    if("isDateDivider" in row){
      return(
      <tr key={i} >
        <td colSpan={items.length}>{row["dateString"]}</td>
      </tr>
      );
    }
    else{
      const needsReleaseHeight = row.releaseHeight === 0;
      return (
         <tr role='button' key={i}  onClick={() => handler(row, i)} >
         { items.map((value, index) => {
             return(
              <td className={needsReleaseHeight ? 'bg-warning' : ''} key={index}>
                {["takeoff", "landing"].includes(value) ? isoTimeToLocaleTime(row[value]) : row[value]}
              </td>
            )
          })}
         </tr>
       )
      }
  };

  return(
    <tbody>
        {showSpinner &&
          <tr key="spinner-0">
              <td colSpan={columns} className="text-center">
                  <div className="spinner-border" role="status">
                    
                  </div>
              </td>
          </tr>
          }
        { !showSpinner && rows && rows.map((value, i) => {
              return buildRow(value, items, i);
          })}
    </tbody>
  );
}


export default SelectableTable;