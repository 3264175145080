import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import "./NewPilot.css";
import { RequestType, apiRequest } from "../libs/databaseAccess";

export default function NewPilot() {
  const navigate = useNavigate();
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (first.length > 0 && last.length > 0);
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await createPilot({ first, last });
      navigate("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function createPilot(first, last) {
    return apiRequest(RequestType.POST, {apiName: "flightline", path: "/pilot", options: {
      body: {first, last}
    }});
  }

  return (
    <div className="NewPilot">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="first name">
          <FormControl
            value={first}
            as="textarea"
            onChange={e => setFirst(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="last name">
          <FormControl
            value={last}
            as="textarea"
            onChange={e => setLast(e.target.value)}
          />
        </FormGroup>
        <LoaderButton
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create Pilot
        </LoaderButton>
      </form>
    </div>
  );
}