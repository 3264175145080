import React, { useState, useEffect, useCallback } from "react";
import { updateUserAttributes } from "aws-amplify/auth";
import { Dropdown, Form} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { loadClubs, returnClub, getUserData, loadPilotClubs, RequestType, apiRequest } from "../libs/databaseAccess";

import LoaderButton from "../components/LoaderButton";

export default function Settings() {
  const {userID, club, isAuthenticated, loadClubInfo } = useAppContext();
  const [allClubs, setAllClubs] = useState([]);
  const [newClubs, setNewClubs] = useState([]);
  const [pilotClubs, setPilotClubs] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const navigate = useNavigate();

  const refreshClubList = useCallback(async () => {
    if (!isAuthenticated || userID === "") {
      return;
    }

    try {
      // Pull club specific info from DynamoDB
      let pilotClubList = await loadPilotClubs(userID);
      // Remove base (no associated club) record and sort
      pilotClubList = pilotClubList.filter((a) => (a.clubId !== "None"));

      // Generate a list of club keys. Note that keys() is not supported by IE, so using .map instead
      const pilotClubIds = pilotClubList.map(function(item) {
        return item.clubId;
      });
      
      // Load full list of clubs
      let clubList = await loadClubs();
      setAllClubs(clubList);    // Save full club list, including the None placeholder for new signups
      clubList = clubList.filter((a) => (a.SK !== "None")).sort((a,b) => (a.shortName > b.shortName) ? 1 : -1);

      // Add club names into the pilotClubList
      pilotClubList = pilotClubList.map(function(item) {
        const clubRecord = returnClub(item.clubId, clubList);
        return clubRecord
      })

      setNewClubs(clubList.filter((a) => !(pilotClubIds.includes(a.SK))));
      setPilotClubs(pilotClubList.sort((a,b) => (a.shortName > b.shortName) ? 1 : -1));
      // And get user data
      const userData = await getUserData("None", userID);
      setUserInfo(userData);
    } catch (e) {
      onError(e);        
    }
  }, [userID, isAuthenticated])

  useEffect(() => {
    refreshClubList()
  }, [refreshClubList]);

  function createPilotBody(clubId) {
    // Build up body for API Call
    const data = {
      first: userInfo.first,
      last: userInfo.last,
      createdAt: Date.now(),
      clubId: clubId,
      pilotId: userID,
    };
    const body = {
      club: clubId,
      type: "PILOT",
      id: userID,
      data: data,
    };
    return body
  }

  async function setUserClub(newClub){
    try {
      await updateUserAttributes({
        userAttributes: {'custom:club': newClub}
      });
    } catch (err) {
      console.log('error updating user club ', err);
    }
  }

  // TODO: This is not forcing an overall refresh, so it is not loading the new club.
  async function switchClub(newClub){
    const currentClub = returnClub(club, allClubs);
    // Update Cognito data with selected club
    if (window.confirm(`Do you wish to switch your active club from ${currentClub.name} to ${newClub.name}?`)) {
      await setUserClub(newClub.SK);
      await loadClubInfo()
      navigate("/");
    }
  }

  async function requestClub(addClub){
    if (window.confirm(`Do you wish to request an active membership at ${addClub.name}?`)) {
      const bodyContent = createPilotBody(addClub.SK);
      await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-item/", options: {
        "body": bodyContent
      }});
      refreshClubList()
    }
  }


  // Must use ForwardRef, Dropdown needs access to the DOM of the Menu to measure it
  const CustomClubMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [searchStr, setSearchStr] = useState('');

      const normalizeString = (str) => {
        if(typeof str !== 'string') return ''
        return str.replace(/\s/g,'').toLowerCase()
      }

      const filterChild = (child) =>{
        if(!searchStr) return true
        const childStr = Array.isArray(child.props.children) ? child.props.children.join() : child.props.children
        if(normalizeString(childStr).includes(normalizeString(searchStr))) return true
        return false
      }

      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          <div className="mx-3 my-2">
            <Form.Control
              autoFocus
              className="w-100"
              placeholder="Type to filter..."
              onChange={(e) => setSearchStr(e.target.value)}
              value={searchStr}
            />
          </div>
          <ul className="list-unstyled mb-0">
            {React.Children.toArray(children).filter(
              (child) => filterChild(child)
            )}
          </ul>
        </div>
      );
    },
  );

  function selectClubDropdown(buttonText, clubList, selectClub) {
    return (
      <div className="d-flex gap-2 justify-content-center">
        <Dropdown drop="down-centered">
          <Dropdown.Toggle variant="secondary">
            {buttonText}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomClubMenu} className="scrollable-dropdown">
            {clubList.map((item) => 
              <Dropdown.Item key={item.SK} onClick = {() => selectClub(item)} as="button">
                {item.name}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className="Settings d-flex flex-column gap-3 py-4">
      <h1>User Settings</h1>
      <LinkContainer to="/settings/password">
        <LoaderButton variant="outline-secondary" size="lg">
          Change Password
        </LoaderButton>
      </LinkContainer>
      <LinkContainer to="/settings/profile">
        <LoaderButton variant="outline-secondary" size="lg">
          Profile
        </LoaderButton>
      </LinkContainer>
      <hr className="my-1" />
      <h2>Club Membership Management</h2>
      <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center">
        {selectClubDropdown("Switch Active Club", pilotClubs, switchClub)}
        {selectClubDropdown("Request Membership at Club", newClubs, requestClub)}
      </div>
      <div className="fs-4 fw-semibold">Active Club: {club ?? "None"}</div>
    </div>
  );
}