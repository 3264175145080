import React, { useState, useEffect, useCallback } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { RequestType, apiRequest, loadIntroList } from "../libs/databaseAccess";
import "./Test.css";
import IntroSearch from "../components/IntroSearch";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";

export default function IntroCancel(){
  const { club, userID, isAuthenticated } = useAppContext();
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();

  const [intros, setIntros] = useState([])
  const [writtenDown, setWrittenDown] = useState(false)
  const [selectedIntro, setSelectedIntro] = useState(null)

  const sendCancel = async () => {
    const body = {
      club,
      by: userID,
      type: "INTRO",
      action: "Cancel",
      data: {
        id: selectedIntro.SK,
        payment: selectedIntro.payment,
        price: selectedIntro.price,
        writtenDown
      }
    }

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {body}}
    )
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not cancel intro"
      })
    } else {
      await loadIntros()
      sendToast({
        status: NotificationStatus.Success,
        message: "Intro cancelled successfully"
      })
      setSelectedIntro(null)
    }
  }

 const confirmCancel = (e) => {
    e.preventDefault()
    sendConfirmModal({
      heading: "Cancel Intro",
      body: (
        <>
          <p>
            After submitting the request to cancel this intro it will no longer be searchable to be redeemed.
          </p>
          { writtenDown && 
            <p>
              This intro <strong>WAS</strong> previously written down so <strong>NO</strong> adjustment will be made to the liability account for intro flights.
            </p>
          }
          { !writtenDown && 
            <p>
              This intro was <strong>WAS NOT</strong> previously written down so the liability account for Intro Flights <strong>WILL</strong> be reduced by the amount originally paid.
            </p>
          }
          <p>
            Cancel intro <strong>{selectedIntro.SN}</strong> purchased by <strong>{selectedIntro.buyerFirst} {selectedIntro.buyerLast}</strong> for <strong>{selectedIntro.price}</strong>?
          </p>
        </>
      ),
      confirmText: "Cancel Intro",
      confirmColor: "danger",
      confirm: sendCancel,
      rejectText: "Go Back",
      rejectColor: "secondary",
    })
  }

  const loadIntros = useCallback(async () => {
    const introList = await loadIntroList(club)
    setIntros(introList)
  }, [club])

  useEffect(() => {
    if(!club || !isAuthenticated) return
    loadIntros()
  }, [club, isAuthenticated, loadIntros])

  return (
    <main className="d-flex flex-column gap-3 pt-3">
      <h2>Cancel Intro</h2>
      <IntroSearch selectIntro={setSelectedIntro} intros={intros} highlightExpired />
      <hr />
      <Form onSubmit={confirmCancel} className="d-flex flex-column gap-3">
        <InputGroup>
          <InputGroup.Text>Amount Paid:</InputGroup.Text>
          <Form.Control as="input" value={selectedIntro?.price ?? ""} disabled />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Payment Method:</InputGroup.Text>
          <Form.Control as="input" value={selectedIntro?.payment ?? ""} disabled />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Purchased By:</InputGroup.Text>
          <Form.Control as="input" value={`${selectedIntro?.buyerFirst ?? ""} ${selectedIntro?.buyerLast ?? ""}`} disabled />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Serial Number:</InputGroup.Text>
          <Form.Control as="input" value={selectedIntro?.SN ?? ""} disabled />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text className="gap-2">
            <label htmlFor="writtenDownCheck">Previously Written Down?</label>
            <Form.Check
              value={writtenDown}
              onChange={() => setWrittenDown((w) => !w)}
              id="writtenDownCheck"
            />
          </InputGroup.Text>
        </InputGroup>
        <Button variant="danger" type="submit" disabled={selectedIntro === null}>Cancel Intro</Button>
      </Form>
    </main>
  );
}