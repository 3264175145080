import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, FormCheck, FormControl, FormGroup, InputGroup, Modal, Spinner, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { RequestType, apiRequest, deletePilot, loadRecentPilotTransactions } from "../libs/databaseAccess";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { MultiSelect } from "react-multi-select-component";
import { useAppContext } from "../libs/contextLib";
import { DateTime } from "luxon";
import accountsMemberSchema from "../containers/accounts member schema.json";
import LoaderButton from "./LoaderButton";
import Table from "./Table";
import "./EditModal.css"
import { formatTaxOptions } from "../libs/utils";

const transactionTypes = {
  Deposit: "DEPOSIT",
  Expense: "Expense"
}


export default function EditPilotModal({showModal, closeModal, pilot, paymentMethods, refreshPilotList}) {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const {userID, club, userIsAdmin, clubMembershipYearEnd, clubFeeList, userCanAddFunds, clubTaxRates, clubTimeZone} = useAppContext()

  const [pilotID, setPilotID] = useState("");
  const [pilotFirst, setPilotFirst] = useState("");
  const [pilotLast, setPilotLast] = useState("");
  const [fundAdd, setFundAdd] = useState(0);
  const [fundNote, setFundNote] = useState("");
  const [expenseAdd, setExpenseAdd] = useState(0);
  const [expenseNote, setExpenseNote] = useState("");
  const [isInstructor, setIsInstructor] = useState(false);
  const [isTowPilot, setIsTowPilot] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [canAddFunds, setCanAddFunds] = useState(false);
  const [canUpdateGliders, setCanUpdateGliders] = useState(false);
  const [canAddIntros, setCanAddIntros] = useState(false);
  const [canEditLogs, setCanEditLogs] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [flatRate, setFlatRate] = useState(false);
  const [transactionRef, setTransactionRef] = useState("");
  const [transferType, setTransferType] = useState("");
  const [expenseType, setExpenseType] = useState("");
  const [taxStatus, setTaxStatus] = useState("exempt")
  const [taxList, setTaxList] = useState([])
  const [feeType, setFeeType] = useState("")

  const [activeExpiration, setActiveExpiration] = useState("")
  const [expirationEnabled, setExpirationEnabled] = useState(true)

  const [balance, setBalance] = useState(0);
  const [pilotTransactions, setPilotTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [submittingFunds, setSubmittingFunds] = useState(false);
  const [submittingExpenses, setSubmittingExpenses] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const modalTitle = useMemo(() => {
    if(!pilot) return "Edit Pilot";
    if(pilot.SK === "new") return "Create New Pilot"
    return `Edit ${pilot.first} ${pilot.last}`
  }, [pilot])


  const confirmDelete = async () => {
    const result = await deletePilot(club, pilotID);

    if(result.statusCode !== 200) {
      await refreshPilotList()
      sendToast({
        status: NotificationStatus.Success,
        message: "Launch deleted successfully"
      })
      closeModal()
    } else {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not delete launch"
      })
    }

    setIsDeleting(false); 
  }

  async function deleteRecord() {
    setIsDeleting(true);     // Start button spinner
    sendConfirmModal({
      heading: "Delete Pilot?",
      body: "Last chance - Are you sure you want to delete this record?",
      confirmText: "Delete",
      rejectText: "Cancel",
      confirmColor: "danger",
      confirm: confirmDelete,
      reject: () => setIsDeleting(false)
    })
  }

  async function updateRecord() {
    setIsLoading(true);

    // Build up body for API Call
    let data = {}
    if (isInstructor !== pilot.isInstructor) {
      data.isInstructor = isInstructor;
    }
    if (isTowPilot !== pilot.isTowPilot) {
      data.isTowPilot = isTowPilot;
    }
    if (isAdmin !== pilot.isAdmin) {
      data.isAdmin = isAdmin;
    }
    if (isActive !== pilot.isActive) {
      data.isActive = isActive;
    }
    if (canEditLogs !== pilot.canEditLogs) {
      data.canEditLogs = canEditLogs;
    }
    if (canAddFunds !== pilot.canAddFunds) {
      data.canAddFunds = canAddFunds;
    }
    if (canAddIntros !== pilot.canAddIntros) {
      data.canAddIntros = canAddIntros;
    }
    if (canUpdateGliders !== pilot.canUpdateGliders) {
      data.canUpdateGliders = canUpdateGliders;
    }      
    if (flatRate !== pilot.flatRate) {
      data.flatRate = flatRate;
    }

    const activeExpirationDate = DateTime.fromISO(activeExpiration, {zone: clubTimeZone})
    if(activeExpirationDate.isValid && expirationEnabled && activeExpirationDate.toMillis() !== pilot.activeExpiration) {
      data.activeExpiration = activeExpirationDate.toMillis()
    } else if(!activeExpirationDate.isValid || !expirationEnabled){
      data.activeExpiration = null
    }

    const body = {
      club: club,
      type: "PILOT",
      id: pilotID,
      data: data,
    };
    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: { "body": body}}
    )
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not update pilot"
      })
    } else {
      await refreshPilotList()
      sendToast({
        status: NotificationStatus.Success,
        message: pilotID !== "new" ? "Pilot updated successfully" : "Pilot created successfully"
      })
      closeModal()
    }
    setIsLoading(false)
  }

  const submitTransaction = async (e, type) => {
    e.preventDefault()
    if(type === transactionTypes.Deposit) {
      setSubmittingFunds(true)
    }
    if(type === transactionTypes.Expense) {
      setSubmittingExpenses(true)
    }

    const amount = type === transactionTypes.Deposit ? fundAdd : expenseAdd
    const note = type === transactionTypes.Deposit ? fundNote : expenseNote

    // If adding money to the account record as a transaction record
    const amountNum = !isNaN(parseFloat(amount)) ? parseFloat(amount) : 0; 
    if (amountNum > 0) {
      const body = {
        club: club,
        time: DateTime.fromObject({}, {zone: clubTimeZone}).toMillis(),
        by: userID,
        transactionType: type,
        note,
        amount: amountNum,
        pilot: pilot.SK,
      }

      if(type === transactionTypes.Deposit) {
        body.transferType = transferType
        body.transactionRef = transactionRef
      }

      if(type === transactionTypes.Expense) {
        body.feeType = feeType
        body.taxStatus = taxStatus
        const taxRates = taxList.map((e) => e.value)
        body.taxRates = taxRates
      }

      const result = await apiRequest(
        RequestType.POST,
        {apiName: "flightline", path: "/add-transaction/", options: {"body": body}}
      )
      if(result.statusCode !== 200) {
        const message = type === transactionTypes.Deposit ?
          "Failed to add funds" :
          "Failed to record expense"
        sendAlert({
          status: NotificationStatus.Danger,
          message
        })
      } else {
        const message = type === transactionTypes.Deposit ?
          "Funds added" :
          "Expense recorded"
        sendToast({
          status: NotificationStatus.Success,
          message
        })
        resetForm(type)
        loadTransactionsForPilot(pilot.SK)
      }
    } else if(amountNum < 0 ) {
      const message = type === transactionTypes.Deposit ?
          "You cannot add negative funds to an account" :
          "You cannot record a negative expense on an account"
      sendAlert({
        status: NotificationStatus.Danger,
        message
      })
    }
    if(type === transactionTypes.Deposit) {
      setSubmittingFunds(false)
    }
    if(type === transactionTypes.Expense) {
      setSubmittingExpenses(false)
    }
  }

  const resetForm = (type) => {
    if(type === transactionTypes.Deposit){
      setFundAdd(0)
      setFundNote("")
      setTransactionRef("")
    } else if (type === transactionTypes.Expense) {
      setExpenseAdd(0)
      setExpenseNote("")
      setTransactionRef("")
    }
  }
  
  const loadTransactionsForPilot = useCallback(async (id) => {
    if(!id) return

    setLoadingTransactions(true)
    const transactionRecords = await loadRecentPilotTransactions(club, id);

    if(transactionRecords.length < 1) return
    
    const transactions = transactionRecords.transactions.map((transaction) => {
      const amount = transaction.amount || 0;
      const balance = transaction.balance || 0;
      const date = DateTime.fromMillis(transaction.date, {zone: clubTimeZone}).toISODate()

      return({
        "id": transaction.id,
        "date": date,
        "description": transaction.description,
        "amount": amount.toFixed(2),
        "balance": balance.toFixed(2),
      })
    });

    setBalance(transactionRecords.balance.toFixed(2));

    setPilotTransactions(transactions.slice(1));
    setLoadingTransactions(false)
  }, [club, clubTimeZone])

  useEffect(() => {
    if(!pilot || !pilot.SK) return;

    setPilotID(pilot.SK)
    loadTransactionsForPilot(pilot.SK)
    setPilotFirst(pilot.first);
    setPilotLast(pilot.last);
    setIsInstructor("isInstructor" in pilot ? pilot.isInstructor : false);
    setIsTowPilot("isTowPilot" in pilot ? pilot.isTowPilot : false);
    setIsAdmin("isAdmin" in pilot ? pilot.isAdmin : false);
    setCanAddFunds("canAddFunds" in pilot ? pilot.canAddFunds : false);
    setCanUpdateGliders("canUpdateGliders" in pilot ? pilot.canUpdateGliders : false);
    setCanAddIntros("canAddIntros" in pilot ? pilot.canAddIntros : false);
    setCanEditLogs("canEditLogs" in pilot ? pilot.canEditLogs : false);
    setIsActive("isActive" in pilot ? pilot.isActive : false);
    setFlatRate("flatRate" in pilot ? pilot.flatRate : false);
    setFundAdd(0);
    setFundNote("");
    setTransferType(paymentMethods[0] || "")
    setFeeType(clubFeeList[0] || "")

    resetForm(transactionTypes.Deposit)
    resetForm(transactionTypes.Expense)

    const activeExpirationDate = DateTime.fromMillis(pilot.activeExpiration ?? Number.NaN, {zone: clubTimeZone})
    if(activeExpirationDate.isValid){
      setExpirationEnabled(true)
      setActiveExpiration(activeExpirationDate.toISODate())
    } else {
      setExpirationEnabled(false)
      if(clubMembershipYearEnd != null){
        const yearEndDate = DateTime.fromMillis(clubMembershipYearEnd, {zone: clubTimeZone})
        setActiveExpiration(yearEndDate.toISODate())
      } else {
        setActiveExpiration("")
      }
    }

    setIsLoading(false);
    setIsDeleting(false);
  }, [pilot, paymentMethods, clubFeeList, clubTimeZone, clubMembershipYearEnd, loadTransactionsForPilot])

  const hasChanged = useMemo(() => {
    return (isInstructor !== pilot.isInstructor) ||
      (isTowPilot !== pilot.isTowPilot) ||
      (isAdmin !== pilot.isAdmin) ||
      (canAddFunds !== pilot.canAddFunds) ||
      (canAddIntros !== pilot.canAddIntros) ||
      (canUpdateGliders !== pilot.canUpdateGliders) ||
      (canEditLogs !== pilot.canEditLogs) ||
      (isActive !== pilot.isActive) ||
      (flatRate !== pilot.flatRate)
  }, [pilot, isInstructor, isTowPilot, isAdmin, canAddFunds, canAddIntros, canUpdateGliders, canEditLogs, isActive, flatRate])

  const formCheckLabel = (title, id) => {
    return <label htmlFor={id}>{title}</label>
  }

  const feeList = useMemo(() => {
    return Array.isArray(clubFeeList) ? clubFeeList : []
  }, [clubFeeList])

  const taxOptions = useMemo(() => {
    return formatTaxOptions(clubTaxRates)
  }, [clubTaxRates])

  const taxSelectRenderer = (selected) => {
    if(selected.length < 1) return "None";
    let str = "";
    for(const i in selected) {
      str += selected[i].value
      if(i < selected.length - 1) {
        str += ", "
      }
    }
    return str
  }

  const renderAddFunds = () => {
    return (
      <Form onSubmit={(e) => submitTransaction(e, transactionTypes.Deposit)}>
        <FormGroup className="d-flex flex-column gap-3" controlId="fundAdd" size="lg">
          <div className="fs-5 fw-semibold">Add Funds</div>
          <InputGroup>
            <InputGroup.Text>Transaction type:</InputGroup.Text>
            <FormControl as="select" value={transferType} onChange={e => setTransferType(e.target.value)}>
              {(paymentMethods.length === 0) && <option key="none" value="none">None</option>}
                { paymentMethods.map((item) =>
                <option key={item} value={item}> {item} </option>
              )}
            </FormControl>
          </InputGroup>
          { (transferType === "expense") ? (
            <InputGroup>
            <InputGroup.Text>Expense type:</InputGroup.Text>
              <FormControl as="select" value={expenseType} onChange={e => setExpenseType(e.target.value)}>
                <option key="60510" value="60510">Field maintenance</option>
                <option key="60520" value="60520">Clubhouse supplies</option>
                <option key="60530" value="60530">Glider Maintenance</option>
                <option key="60900" value="60900">Other</option>
              </FormControl>
            </InputGroup>
            ) : (
            <InputGroup>
              <InputGroup.Text>Transaction reference:</InputGroup.Text>
              <FormControl
                type="text"
                value={transactionRef}
                onChange={e => setTransactionRef(e.target.value)}
              />
            </InputGroup>
            )
          }
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <FormControl
              type="text"
              inputMode="decimal"
              min="0"
              as="input"
              value={fundAdd}
              onChange={e => setFundAdd(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Note:</InputGroup.Text>
            <FormControl
              type="text"
              value={fundNote}
              onChange={e => setFundNote(e.target.value)}
            />
          </InputGroup>
          <LoaderButton 
            isLoading={submittingFunds}
            type="submit"
            disabled={Number.isNaN(parseFloat(fundAdd)) || parseFloat(fundAdd) === 0}
          >Submit</LoaderButton>
        </FormGroup>
      </Form>
    )
  }

  const renderRecordExpenses = () => {
    return (
      <Form onSubmit={(e) => submitTransaction(e, transactionTypes.Expense)}>
        <FormGroup className="d-flex flex-column gap-3" controlId="fundAdd" size="lg">
          <div className="fs-5 fw-semibold">Record Expenses</div>
          <InputGroup>
            <InputGroup.Text>Other charges:</InputGroup.Text>
            <FormControl as="select" value={feeType} onChange={e => setFeeType(e.target.value)}>
              {(feeList.length === 0) && <option key="none" value="none">None</option>}
                { feeList.map((item) =>
                <option key={item} value={item}> {item} </option>
              )}
            </FormControl>
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Fee:</InputGroup.Text>
            <InputGroup.Text>$</InputGroup.Text>
            <FormControl
              type="text"
              inputMode="decimal"
              min="0"
              as="input"
              value={expenseAdd}
              onChange={e => setExpenseAdd(e.target.value)}
            />
          </InputGroup>
          <ToggleButtonGroup  name="taxStatusCheck" className="flex-wrap" type="radio" value={taxStatus} onChange={(e) => setTaxStatus(e)}>
            <InputGroup.Text>Tax Status:</InputGroup.Text>
            <ToggleButton id="exempt" variant="outline-primary" name="taxStatusCheck" value="exempt">Tax Exempt</ToggleButton>
            <ToggleButton id="inclusive" variant="outline-primary" name="taxStatusCheck" value="inclusive">Tax Inclusive</ToggleButton>
            <ToggleButton id="exclusive" variant="outline-primary" name="taxStatusCheck" value="exclusive">Tax Exclusive</ToggleButton>
          </ToggleButtonGroup>
          {taxStatus !== "exempt" &&
            <InputGroup>
              <InputGroup.Text>Applicable Taxes:</InputGroup.Text>
              <MultiSelect
                className="flex-grow-1 flex-shrink-1 multi-select"
                disableSearch
                hasSelectAll={false}
                options={taxOptions}
                value={taxList}
                onChange={setTaxList}
                valueRenderer={taxSelectRenderer}
              />
            </InputGroup>
          }
          <InputGroup>
            <InputGroup.Text>Note:</InputGroup.Text>
            <FormControl
              type="text"
              value={expenseNote}
              onChange={e => setExpenseNote(e.target.value)}
            />
          </InputGroup>
          <LoaderButton 
            isLoading={submittingExpenses}
            type="submit"
            disabled={Number.isNaN(parseFloat(expenseAdd)) || parseFloat(expenseAdd) === 0}
          >Submit</LoaderButton>
        </FormGroup>
      </Form>
    )
  }

  const renderChangeQualifications = () => {
    return (
      <>
        <div className="fs-5 fw-semibold mb-2">Change Qualifications</div>
        <FormGroup>
              <FormCheck checked={isInstructor} onChange={() => setIsInstructor(!isInstructor)} id="qual1" label={formCheckLabel("Instructor", "qual1")}/>
              <FormCheck checked={isTowPilot} onChange={() => setIsTowPilot(!isTowPilot)} id="qual2" label={formCheckLabel("Tow Pilot", "qual2")}/>
              <FormCheck checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} id="qual3" label={formCheckLabel("Admin", "qual3")}/>
              <FormCheck checked={canAddFunds} onChange={() => setCanAddFunds(!canAddFunds)} id="qual4" label={formCheckLabel("Can Add User Funds", "qual4")}/>
              <FormCheck checked={canAddIntros} onChange={() => setCanAddIntros(!canAddIntros)} id="qual5" label={formCheckLabel("Can Add Intro Certificates", "qual5")}/>
              <FormCheck checked={canUpdateGliders} onChange={() => setCanUpdateGliders(!canUpdateGliders)} id="qual6" label={formCheckLabel("Can Update Gliders", "qual6")}/>
              <FormCheck checked={canEditLogs} onChange={() => setCanEditLogs(!canEditLogs)} id="qual7" label={formCheckLabel("Can Edit Flight Logs", "qual7")}/>
              <FormCheck checked={flatRate} onChange={() => setFlatRate(!flatRate)} id="qual9" label={formCheckLabel("Flat Rate Rental", "qual9")}/>
              <InputGroup className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center align-items-start">
                <FormCheck checked={isActive} onChange={() => setIsActive(!isActive)} id="qual8" label={formCheckLabel("Active", "qual8")}/>
                {isActive &&
                  <div className="w-sm-auto d-flex flex-sm-row align-items-sm-center align-items-start gap-2 flex-column justify-content-between px-4">
                    <span className="text-nowrap">Active status expires on:</span>
                    <div className="d-flex flex-row gap-2 w-100 align-items-center">
                      {expirationEnabled && <FormControl className={activationIsExpired ? "bg-warning" : ""} id="active-expiration-date" type="date" value={activeExpiration} onChange={(e) => setActiveExpiration(e.target.value)} />}
                      {!expirationEnabled && <FormControl id="active-expiration-placeholder" type="text" placeholder="Never" disabled={true} />}
                      <FormCheck
                        className="h-100"
                        type="switch"
                        checked={expirationEnabled}
                        onChange={() => setExpirationEnabled((ee) => !ee)}
                      />
                    </div>
                  </div>
                }
              </InputGroup>
            </FormGroup>
        <hr/>
      </>
    )
  }

  const activationIsExpired = useMemo(() => {
    const expirationDate = DateTime.fromISO(activeExpiration, {zone: clubTimeZone}).startOf("day")
    const today = DateTime.fromObject({}, {zone: clubTimeZone})
    return (today >= expirationDate)
  }, [activeExpiration, clubTimeZone])

  return (
    <Modal size="lg" show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {(userCanAddFunds || userIsAdmin) &&
          <>
            {renderAddFunds()}
            <hr/>
            {renderRecordExpenses()}
            <hr/>
          </>
        }
        {(userIsAdmin) && renderChangeQualifications()}
        {hasChanged &&
        <>
          <h4>Confirm the following changes to user {pilotFirst} {pilotLast}</h4>
          <ol className="mb-0">
            {(isInstructor !== pilot.isInstructor) && 
                <li>Changed Instructor rating to {isInstructor ? "true" : "false"}</li>}
            {(isTowPilot !== pilot.isTowPilot) && 
                <li>Changed Tow Pilot rating to {isTowPilot ? "true" : "false"}</li>}
            {(isAdmin !== pilot.isAdmin) && 
                <li>Changed Administrator rating to {isAdmin ? "true" : "false"}</li>}
            {(canAddFunds !== pilot.canAddFunds) && 
                <li>Changed permission to add funds to {canAddFunds ? "true" : "false"}</li>}
            {(canAddIntros !== pilot.canAddIntros) && 
                <li>Changed permission to add intro certificates {canAddIntros ? "true" : "false"}</li>}
            {(canUpdateGliders !== pilot.canUpdateGliders) && 
                <li>Changed permission to update gliders to {canUpdateGliders ? "true" : "false"}</li>}
            {(canEditLogs !== pilot.canEditLogs) && 
                <li>Changed permission to update flight logs to {canEditLogs ? "true" : "false"}</li>}
            {(isActive !== pilot.isActive) && 
                <li>Changed status at club to {isActive ? "active" : "inactive"}</li>}
            {(flatRate !== pilot.flatRate) && 
                <li>Changed flat rate rental to {flatRate ? "true" : "false"}</li>}
          </ol>
          <hr></hr>
        </>
        }
        <div className="mb-3 text-center fs-4 fw-semibold">Current balance: {!loadingTransactions && `$${balance}`} {loadingTransactions && <Spinner size="sm" />}</div>
        {loadingTransactions ?
          <div className="d-flex justify-content-center"><Spinner /></div> :
          <Table  headers={Object.values(accountsMemberSchema)} items={Object.keys(accountsMemberSchema)} rows={pilotTransactions} />
        }
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-row justify-content-between w-100">
          <div>
            { (pilotID !== "new") &&
              <LoaderButton type="button" variant="danger" isLoading={isDeleting} onClick={deleteRecord}>
                Delete Pilot
              </LoaderButton>
            }
          </div>
          <div className="d-flex flex-row gap-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <LoaderButton type="button" variant="primary" isLoading={isLoading} onClick={updateRecord}>
              Save Changes
            </LoaderButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}