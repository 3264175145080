import React, {useState, useEffect, useCallback} from "react";
import { FormControl, Button, InputGroup, FormGroup, FormSelect, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { loadPilots, getTugs, returnTug, RequestType, apiRequest} from "../libs/databaseAccess";
import { buildNumberString } from "../libs/utils";
import "./Fuel.css"; //some styling
import { DateTime } from "luxon";

export default function Fuel() {
    const { userID, club, isAuthenticated, clubTimeZone } = useAppContext();
    const [tug, setTug] = useState("");
    const [tugs, setTugs] = useState([]);
    const [fuel, setFuel] = useState(0.0);
    const [hobbsTime, setHobbsTime] = useState(0.0);
    const navigate = useNavigate();
    const [chooseChange, setChooseChange] = useState("Check In");
    const [towPilots, setTowPilots] = useState([])
    const [pilotChange, setPilotChange] = useState("None");
    const [lastOil, setLastOil] = useState(0)
    const [lastFuel, setLastFuel] = useState(0)
    const [lastPilotChange, setLastPilotChange] = useState(0)
    const [checkedIn, setCheckedIn] = useState("None");
    const [checkedInName, setCheckedInName] = useState("None");
    const [isLoading, setIsLoading] = useState(true);

    const refreshTugList = useCallback(async () => {
      const tugs = await getTugs(club);
      const sortedTugs = tugs.sort((a, b) => ((a.SK).localeCompare(b.SK)));
      setTugs(sortedTugs);
      setTug(sortedTugs[0].SK);
      const tug = returnTug(club, sortedTugs[0].SK, tugs);
      setHobbsTime(tug.lastHobbs);
      setLastOil(tug.oilHobbs);
      setLastFuel(tug.fuelHobbs);
      setLastPilotChange(tug.pilotHobbs);

      const pilotId = tug.pilot || "None";
      setCheckedIn(pilotId);
      const pilotName = tug.pilotName || "None";
      setCheckedInName(pilotName);
    }, [club])

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated || club === "" || userID === "") {
                return;
            }
            try {
              refreshTugList()

              const pilots = await loadPilots(club);
              const towPilots = pilots.filter(a => a.isTowPilot === true).sort((a, b) => ((a.first).localeCompare(b.first)));
              setTowPilots(towPilots);
              if (towPilots) {
                setPilotChange(towPilots[0].SK);
              }

            } catch (e) {
                onError(e);
            }
        }
        onLoad();
        setIsLoading(false);
    }, [isAuthenticated, club, userID, refreshTugList]);

    async function recordTow(){
        const recordTime = DateTime.fromObject({}, {zone: clubTimeZone});
        const body = {
            "club": club,
            data: {
                "time": recordTime.toMillis(),
                "by": userID,
                "hobbs": parseFloat(hobbsTime),
                "tug": tug,
            }
        }
        if (chooseChange === "Oil") {
            body.data.oil = true;
        }
        if (chooseChange === "Fuel") {
            body.data.fuel = parseFloat(fuel);
        }
        if (chooseChange === "Check In") {
            body.data.pilot = pilotChange;
            body.data.pilotLogOut = false;
        }
        if (chooseChange === "Check Out") {
            body.data.pilot = checkedIn;
            body.data.pilotLogOut = true;
        }

        refreshTugList()
        await apiRequest(RequestType.POST, {apiName: "flightline", path: "/create-tow/", options: {
            "body": body,
        }});
        navigate("/");
    }

    function recordCheckOut() {
        setPilotChange("None");
        recordTow();
    }

    function renderInput(){
        if(chooseChange === "Fuel"){
            return (
                <>
                    {(renderTugs((tugs)))}
                    <FormGroup id = "fuelInput">
                    <InputGroup size="lg" className="mb-3">
                        <InputGroup.Text>Hobbs: </InputGroup.Text>
                        <FormControl
                            type="text"
                            value = {hobbsTime}
                            inputMode="decimal"
                            onChange={(e) => setHobbsTime(buildNumberString(e.target.value, hobbsTime, 2))}
                        />
                        <InputGroup.Text>h</InputGroup.Text>
                    </InputGroup>
                    <InputGroup size="lg" className="mb-3">
                        <InputGroup.Text>Fuel: </InputGroup.Text>
                        <FormControl
                            type="text"
                            value = {fuel}
                            inputMode="decimal"
                            onChange={(e) => setFuel(buildNumberString(e.target.value, fuel, 2))}
                        />
                        <InputGroup.Text>L</InputGroup.Text>
                    </InputGroup>
                    <h4 className="mb-3">Last refuel was at {lastFuel} hours</h4>
                    </FormGroup>
                    <br></br>
                    <Button variant="primary" size="lg" onClick = {recordTow}>
                        Confirm
                    </Button>
            </>
            );
        } 
        if (chooseChange==="Oil"){
            return (
                <>
                    {(renderTugs((tugs)))}
                    <FormGroup id = "OilInput">
                    <InputGroup size="lg" className="mb-3">
                        <InputGroup.Text>Hobbs: </InputGroup.Text>
                        <FormControl
                            type='text'
                            value = {hobbsTime}
                            inputMode="decimal"
                            onChange={(e) => setHobbsTime(buildNumberString(e.target.value, hobbsTime, 2))}
                        />
                        <InputGroup.Text>h</InputGroup.Text>
                    </InputGroup>
                    <h4 className="mb-3">Last oil change was at {lastOil} hours</h4>
                    </FormGroup>
                    <br></br>
                    <Button variant="primary" size="lg" onClick = {recordTow}>
                        Confirm Oil Change
                    </Button>
                </>
            )
        }
        if (chooseChange === "Check In"){
            return(
                <>
                    {(renderTugs(tugs))}
                    <FormGroup id = "towPilotInput">
                    <InputGroup size="lg" className="mb-3">
                        <InputGroup.Text>Hobbs: </InputGroup.Text>
                        <FormControl
                            type='text'
                            value = {hobbsTime}
                            inputMode="decimal"
                            onChange={(e) => setHobbsTime(buildNumberString(e.target.value, hobbsTime, 2))}
                        />
                        <InputGroup.Text>h</InputGroup.Text>
                    </InputGroup>
                    <InputGroup size="lg" className="mb-3">
                        <InputGroup.Text>Change to: </InputGroup.Text>
                        <FormSelect onChange={(e) => setPilotChange(e.target.value)}>
                            {towPilots.map((item) =>
                                <option key={item.SK} value={item.SK}>{item.first + " " + item.last}</option>)}
                        </FormSelect>
                    </InputGroup>
                    <Button variant="primary" size="lg" onClick = {recordTow}>
                        Confirm Pilot Check In
                    </Button>
                    <h4 className="mb-3">Last pilot change was at {lastPilotChange} hours</h4>
                    </FormGroup>
                </>
            );
        }
        if (chooseChange === "Check Out"){
            return(
                <>
                    {(renderTugs(tugs))}
                    <FormGroup id = "towPilotInput">
                    <InputGroup size="lg" className="mb-3">
                        <InputGroup.Text>Hobbs: </InputGroup.Text>
                        <FormControl
                            type='text'
                            value = {hobbsTime}
                            inputMode="decimal"
                            onChange={(e) => setHobbsTime(buildNumberString(e.target.value, hobbsTime, 2))}
                        />
                        <InputGroup.Text>h</InputGroup.Text>
                    </InputGroup>
                    <InputGroup size="lg" className="mb-3">
                        <Button disabled={checkedIn === "None"} onClick = {recordCheckOut}>Check out {checkedInName}</Button>
                    </InputGroup>
                    <h4 className="mb-3">Last pilot change was at {lastPilotChange} hours</h4>
                    </FormGroup>
                </>
            );
        }
    }

    function setCurrentTug(eventKey){
        setTug(eventKey);
        const tug = returnTug(club, eventKey, tugs);
        setHobbsTime(tug.lastHobbs);
        setCheckedIn(tug.pilot || "None");
        setCheckedInName(tug.pilotName || "None");
        setLastOil(tug.oilHobbs);
        setLastFuel(tug.fuelHobbs);
        setLastPilotChange(tug.pilotHobbs);
    }

    function renderTugs(tugs){
        return (
            <>
            <InputGroup size="lg" className="mb-3">
            <InputGroup.Text>Tug: </InputGroup.Text>
                <FormControl as="select" onChange = {(e) => setCurrentTug(e.target.value)}>
                    { tugs.map((item) =>
                    <option key={item.SK} value={item.SK}> {item.SK} </option>
                    )}
                </FormControl>
            </InputGroup>
            </>
        );
    }    

    return (
        <> 
        { isLoading ? 
            <div className="spinner-border" role="status"></div>
            : 
            <>
            <ToggleButtonGroup className="mb-3" type="radio" name="options" value={chooseChange} onChange={e=>setChooseChange(e)}>
                <ToggleButton variant="outline-secondary" id="opt-1" value={"Check In"}>Pilot check in</ToggleButton>
                <ToggleButton variant="outline-secondary" id="opt-2" value={"Check Out"}>Pilot check out</ToggleButton>
                <ToggleButton variant="outline-secondary" id="opt-3" value={"Fuel"}>Refuel</ToggleButton>
                <ToggleButton variant="outline-secondary" id="opt-4" value={"Oil"}>Oil Change</ToggleButton>
            </ToggleButtonGroup>
            <div>
                {renderInput()}
            </div>
            </>
        }
        </>
    )
}