import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Container, Row, Col, FormControl, FormGroup, InputGroup, ToggleButton, ToggleButtonGroup, Form, FormLabel, Alert, Modal, Spinner, Accordion } from "react-bootstrap";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { returnPilot, returnIntro, RequestType, apiRequest, loadLogsPayload } from "../libs/databaseAccess";
import "./Logs.css";
import schema from "./schema.json"
import userSchema from "./user schema.json"
import towSchema from "./tow schema.json"
import SelectableTable from "../components/SelectableTable.js"
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib.js";
import { isUserActive, isoTimeToLocaleTime, nameFromPilotId } from "../libs/utils.js";
import RevisionsTable from "../components/RevisionsTable.js";
import { DateTime } from "luxon";
import LoaderButton from "../components/LoaderButton.js";

const flightTypes = {
  "Alone":"Alone", 
  "Instructor":"Instructor",
  "Member":"Member",
  "Intro":"Intro",
  "Friend":"Friend",
}

const ModalState = {
  "INFO": "info",
  "EDIT": "edit",
  "CONFIRM": "confirm"
}

export default function Logs() {
  const emptyTimes = {
    time: null,
    count: null,
    intros: null,
    instruction: null
  }

  function initDate() {
    const newDate = new Date()
    newDate.setTime(newDate.getTime() - (newDate.getTimezoneOffset()*60*1000))
    return newDate.toISOString().substring(0,10)
  }

  const {userID, club, isAuthenticated, tzoffset, userIsAdmin, userCanEditLogs, clubTimeZone, clubUnits} = useAppContext();
  const {sendAlert, sendToast} = useNotificationContext();
  const [pilotFlights, setPilotFlights] = useState(null);   // For filtered display
  const [allPilotFlights, setAllPilotFlights] = useState(null); // Full list
  const [allFlights, setAllFlights] = useState(null);  // Full List
  const [allGliderFlights, setAllGliderFlights] = useState(null); // For filtered display
  const [allTowFlights, setAllTowFlights] = useState(null);
  const [towPilots, setTowPilots] = useState(null);
  const [actionType, setActionType] = useState("by time");
  const [startDate, setStartDate] = useState(initDate());
  const [endDate, setEndDate] = useState(initDate());
  const [flightViewType, setFlightViewType] = useState("All");
  const [totalGliderTime, setTotalGliderTime] = useState({emptyTimes});
  const [totalPilotTime, setTotalPilotTime] = useState({emptyTimes});
  const [totalTowTime, setTotalTowTime] = useState({emptyTimes});
  const [gliders, setGliders] = useState(null);
  const [pilotGliders, setPilotGliders] = useState(null);
  const [currentFlightData, setCurrentFlightData] = useState(null);
  const [flightPilot, setFlightPilot] = useState(null);
  const [flightPassenger, setFlightPassenger] = useState(null);
  const [flightGlider, setFlightGlider] = useState(null);
  const [flightType, setFlightType] = useState(null);
  const [launchType, setLaunchType] = useState(null);
  const [flightTakeoff, setFlightTakeoff] = useState(null);
  const [flightLanding, setFlightLanding] = useState(null);
  const [releaseHeight, setReleaseHeight] = useState(null);
  const [pilotInCharge, setPilotInCharge] = useState(null);
  const [shareCost, setShareCost] = useState(null);
  const [towPilot, setTowPilot] = useState(null);
  const [tug, setTug] = useState(null);
  const [introSelected, setIntroSelected] = useState(null);
  const [rawFlights, setRawFlights] = useState([])

  const [allGliders, setAllGliders] = useState(null);
  const [allActivePilots, setAllActivePilots] = useState(null);
  const [allPilots, setAllPilots] = useState(null);
  const [launchTypes, setLaunchTypes] = useState(null);
  const [tugs, setTugs] = useState(null);
  const [intros, setIntros] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [errorMsg, setErrorMsg] = useState("")
  const [expandRevisions, setExpandRevisions] = useState(false)

  const [showInfoModal, setShowInfoModal] = useState(false)
  const [infoModalState, setInfoModalState] = useState(ModalState.INFO)
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Per render code

  let flightTimes = null;
  let flightSummary = "";
  if (actionType === "by time") {
    flightTimes = totalGliderTime;
  }
  if (actionType === "by tow") {
    flightTimes = totalTowTime;
  }
  if (actionType === "personal") {
    flightTimes = totalPilotTime;
  }

  if (flightTimes.time > 0) {
    let timeString = toHHMM(flightTimes.time);
    let flightName = (flightTimes.time === 1) ? "flight" : "flights total";
    flightSummary = `${timeString} in ${flightTimes.count} ${flightName}`;
    if (flightTimes.intros > 0) {
      flightName = (flightTimes.intros === 1) ? "intro" : "intros";
      timeString = toHHMM(flightTimes.introTime);
      flightSummary += `, ${timeString} in ${flightTimes.intros} ${flightName}`;
    }
    if (flightTimes.instruction > 0) {
      timeString = toHHMM(flightTimes.instructionalTime);
      flightSummary += `, ${timeString} in ${flightTimes.instruction} instructional`;
    }
  }

  //Methods
  const getFlightTime = useCallback((flights) => {
    let time = 0;
    let flightCount = 0;
    let introTime = 0;
    let introCount = 0;
    let instructionalTime = 0;
    let instructionalCount = 0;
    if(flights !== null && flights !== undefined) {
      for(var flight of flights){
        if(!isNaN(flight.duration)) {
          time += flight.duration;
          flightCount += 1;
          if(flight.flyWith === flightTypes.Intro) {
            introTime += flight.duration;
            introCount += 1;
          }
          if(flight.flyWith === flightTypes.Instructor) {
            instructionalTime += flight.duration;
            instructionalCount += 1;
          }
        }
      }
    }
    const flightTotals = {
      time: time,
      count: flightCount,
      introTime: introTime,
      intros: introCount,
      instructionalTime: instructionalTime,
      instruction: instructionalCount
    }
    return flightTotals;
  },[]);

  const loadFlights = useCallback(async (loadSetup) => {
    try {

      //for multiple dates
      let startDateTime = new Date(startDate)
      let endDateTime = new Date(endDate)

      // Set endDateTime to end of day
      endDateTime.setTime(endDateTime.getTime() + (24 * 60 * 60 * 1000) - 1)
      
      // offset the dates being interpreted as UTC
      startDateTime.setTime(startDateTime.getTime() + (tzoffset*60*1000))
      endDateTime.setTime(endDateTime.getTime() + (tzoffset*60*1000))

      const response = await loadLogsPayload(club, startDateTime.getTime(), endDateTime.getTime(), loadSetup)

      if(response.statusCode !== 200){
        setErrorMsg(`Could not load logs (${response.statusCode})`)
        return
      }

      const todaysFlights = response.body.flights
      const pilots = response.body.pilots
      
      if(loadSetup) {
        const gliders = response.body.gliders
        const launches = response.body.launches
        const tugList = response.body.tugs
        const intros = response.body.intros

        setAllPilots(pilots);
        setAllActivePilots(pilots.filter((a) => isUserActive(a, clubTimeZone)).sort((a, b) => (a.first+a.last).localeCompare(b.first+b.last)));
        setAllGliders(gliders.sort((a,b) => ((a.SK).localeCompare(b.SK))));
        setLaunchTypes(launches.sort((a,b) => ((a.SK).localeCompare(b.SK))));
        setTugs(tugList);
        setIntros(intros.sort((a,b)=>(a.SN.toString().localeCompare(b.SN.toString()))));
      }

      setRawFlights(todaysFlights)

      // Remap data into display form
      var flights = todaysFlights.reduce(function(list, item) {
        if(item.cancelledAt) return list

        var takeoffTime = functionalStart(item);
        var landingTime = item.landedAt;

        function toLocalTime(time){
          if(!time) return "Not recorded"
          const date = DateTime.fromMillis(time, {zone: clubTimeZone })
          return date.set({second: 0, millisecond: 0}).toISOTime({ suppressSeconds: true, includeOffset: false})
        }

        // Preload name strings to be used for P1 and P2 display
        const submitterName = item.first + " " + item.last;
        let otherName = "";
        if ((item.passengerFirst !== "") || (item.passengerLast !== "")) {
          // Generate a passenger name as long as there is either a first or a lintroast name recorded
          // This need not be a member pilot (no need to check SK)
          otherName = item.passengerFirst + " " + item.passengerLast
        };

        let towPilotName = "";
        if (item.towPilot !== "") {
          const towPilot = returnPilot(club, item.towPilot, pilots)
          if ((towPilot) && (towPilot.first !== "") && (towPilot.last !== "")) {
            towPilotName = towPilot.first + " " + towPilot.last
          }
        };

        let launchCost = "$0";
        let flightCost = "$0";
        if ((userID === item.user) && (item.flyWith !== "Intro")) {
          launchCost = item.launchCost ? (item.launchCost).toFixed(2) : 0
          flightCost = item.flightCost ? (item.flightCost).toFixed(2) : 0
        }
        var duration = 0;
        if(!takeoffTime || !landingTime){
          duration = NaN;
        }
        else{
          duration = Math.round((landingTime - takeoffTime)/1000/60,0); //convert to minutes
        }

        list.push({
          "user" : item.user,
          "passengerID" : item.passengerID,
          "glider": item.glider,
          "P1": item.pilotInCharge ? submitterName : otherName, 
          "P2": item.pilotInCharge ? otherName : submitterName,
          "takeoff": toLocalTime(takeoffTime),
          "landing": toLocalTime(landingTime),
          "takeoffMillis": takeoffTime,
          "landingMillis": landingTime,
          "duration": duration,
          "durationString": toHHMM(duration),
          "launchType": item.launchType,
          "flyWith": item.flyWith,
          "pilotInCharge":item.pilotInCharge,
          "shareCost": item.shareCost,
          "SK": item.SK,
          "launchCost": launchCost,
          "flightCost" : flightCost,
          "towPilot": item.towPilot,
          "towPilotName": towPilotName,
          "tug": item.tug,
          'launchTransaction': item.launchTransaction,
          'landingTransaction':item.landingTransaction,
          "dateString": item.PK.substring(item.PK.lastIndexOf("#")+1),
          'introRef': 'introRef' in item ? item.introRef : '',
          "history": item.corrections ?? [],
          "flightLocked": item.flightLocked,
          "releaseHeight": item.releaseHeight
        });
        return list
      }, []);

      //For multiple dates
      flights.sort((a,b) => (a.takeoff > b.takeoff) ? 1 : -1);
      setGliders([...new Set(flights.map( item => item.glider))].sort());
      let flightsWithDateEntries = [];
      let dateTracker = new Date(startDateTime.getTime());
      while(dateTracker < endDateTime){
        const dateStr = dateTracker.toISOString().slice(0,10)
        let dateEntry = {
          "isDateDivider": true,
          "dateString": dateStr,
        }
        let flightsOnDate = flights.filter((a)=>(a.dateString === dateStr));
        flightsOnDate.forEach((item, index) => {
          item.seq = index+1;
        });
        if(flightsOnDate.length > 0){
          flightsWithDateEntries.push(dateEntry);
        }
        flightsWithDateEntries = flightsWithDateEntries.concat(flightsOnDate);
        dateTracker.setTime(dateTracker.getTime() + 24*3600*1000);
      }
      flights = flightsWithDateEntries;
      
      //for multiple dates
      let personalFlightsWithAllDates = flights.filter(a => (("isDateDivider" in a) || (a.user === userID) || (a.passengerID === userID)));
      let personalFlights =  personalFlightsWithAllDates.filter(a => (!("isDateDivider" in a) ||  personalFlightsWithAllDates.filter((b)=>(b.dateString === a.dateString)).length>1));
      let filteredPersonalFlights = personalFlights.filter(a=>(!("isDateDivider" in a)));
      setPilotGliders([...new Set(filteredPersonalFlights.map( item => item.glider))].sort());
      setPilotFlights(personalFlights);
      setAllPilotFlights(personalFlights);
      setTotalPilotTime(getFlightTime(personalFlights));
      setAllFlights(flights);
      setAllGliderFlights(flights);
      setTotalGliderTime(getFlightTime(flights));
      setTotalTowTime(getFlightTime(flights));
      setAllTowFlights(flights);
      var allPilotEntry = {
        "first":"All",
        "last":"",
        "SK":"All",
      }
      setTowPilots([allPilotEntry].concat(pilots.filter((a)=>(a.isTowPilot)).sort((a, b) => (a.first+a.last).localeCompare(b.first+b.last))));
      setFlightViewType("All");

    } catch (e) {
      onError(e);        
    }
  }, [userID, club, tzoffset, startDate, endDate, getFlightTime, clubTimeZone])

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated || club === "" || userID === "") {
        return false;
      }
  
      loadFlights(true)

      return true;
    }

    onLoad();
    // if (onLoad()) {
    //   // Refresh flight status
    //   const interval = setInterval(loadFlights, 20000);
    //   return () => {
    //     clearInterval(interval);
    //   };
    // }
  }, [isAuthenticated, club, userID, loadFlights]);

  function functionalStart(flight){
    if (flight.acceptedAt && flight.launchedAt) {
      return ((flight.acceptedAt > flight.launchedAt) ? flight.acceptedAt : flight.launchedAt);
    }
    if (flight.acceptedAt) {
      return flight.acceptedAt;
    }
    return flight.launchedAt;
  }

  function toHHMM(delta) {
    let minutes = delta;
    let hours = Math.floor(minutes/60);
    const hourString = hours.toString();
    minutes -= hours*60;
    minutes = Math.round(minutes);
    const minuteString = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
    return  !isNaN(hours) ? hourString + ":" + minuteString : "tbd";
  }

  function changeList(listType){
    if (actionType === "by tow" && listType !== "by tow") {
      setGliderView("All");
    }
    if (actionType !== "by tow" && listType === "by tow") {
      setTowView("All");
    }
    if (listType === "personal") {
      // In case All Flights were filtered to a glider that was not flown by the pilot, when changing to the pilot view
      if (!(pilotGliders.includes(flightViewType))) {
        setFlightViewType("All");
        setGliderView("All");
      }
    }
    //loadFlights();
    setActionType(listType);
  }

  function setGliderView(glider) {
    let personalFlights = allPilotFlights.filter(a => (("isDateDivider" in a) || (a.user === userID) || (a.passengerID === userID)));
    let filteredFlights = allFlights;
    if (glider !== "All") {
      filteredFlights = filteredFlights.filter(a => ("isDateDivider" in a || a.glider === glider));
      filteredFlights = filteredFlights.filter(a => (!("isDateDivider" in a) ||  filteredFlights.filter((b)=>(b.dateString === a.dateString)).length>1));
      personalFlights = personalFlights.filter(a => ("isDateDivider" in a || a.glider === glider));
      personalFlights = personalFlights.filter(a => (!("isDateDivider" in a) ||  personalFlights.filter((b)=>(b.dateString === a.dateString)).length>1));
    }
    setAllGliderFlights(filteredFlights);
    setTotalGliderTime(getFlightTime(filteredFlights));
    setPilotFlights(personalFlights);
    setTotalPilotTime(getFlightTime(personalFlights));
    setFlightViewType(glider);
  }

  function setTowView(pilot){
    setFlightViewType(pilot);
    if (pilot === "All") {
      setAllTowFlights(allFlights);
      setTotalTowTime(getFlightTime(allFlights));
    } else {
      var filteredFlights = allFlights.filter(a => ("isDateDivider" in a || a.towPilot === pilot));
      var dateFiltered = filteredFlights.filter(a => (!("isDateDivider" in a) ||  filteredFlights.filter((b)=>(b.dateString === a.dateString)).length>1));
      setAllTowFlights(dateFiltered);
      setTotalTowTime(getFlightTime(dateFiltered));
    }
  }

  function selectRow(flight){
    var data;
    data = {
      'glider':flight.glider,
      'P1':flight.P1,
      'P2':flight.P2,
      'flyWith':flight.flyWith,
      'launchType':flight.launchType,
      'takeoff':flight.takeoff,
      'landing':flight.landing,
      "takeoffMillis": flight.takeoffMillis,
      "landingMillis": flight.landingMillis,
      'SK':flight.SK,
      'pilotInCharge':flight.pilotInCharge,
      'shareCost': flight.shareCost,
      'towPilot':flight.towPilot,
      'tug':flight.tug,
      'launchTransaction': flight.launchTransaction,
      'landingTransaction':flight.landingTransaction,
      'user':flight.user,
      'history': flight.history,
      'flightLocked': flight.flightLocked,
      "launchCost": flight.launchCost,
      "flightCost" : flight.flightCost,
      'passengerID':flight.passengerID,
      'dateString' :flight.dateString,
      'releaseHeight' :flight.releaseHeight,
    }
    setFlightGlider(flight.glider);
    setFlightPilot(flight.user);
    if(flight.flyWith !== 'Alone' && flight.passengerID === '')
    {
      if(flight.pilotInCharge){
        setFlightPassenger(flight.P2);
        data.passengerID = flight.P2;

      }
      else{
        setFlightPassenger(flight.P1);
        data.passengerID = flight.P1;
      }
    }
    else{
      setFlightPassenger(flight.passengerID);
    }
    setFlightType(flight.flyWith);
    setLaunchType(flight.launchType);
    setFlightTakeoff(flight.takeoff);
    setFlightLanding(flight.landing);
    setReleaseHeight(flight.releaseHeight?.toString());
    setPilotInCharge(flight.pilotInCharge);
    setTowPilot(flight.towPilot);
    setTug(flight.tug);
    setShareCost(flight.shareCost);
    if(!flight.shareCost){
      setShareCost(false);
      data.shareCost=false;
    }
    if('introRef' in flight && flight.introRef!==''){
      data["introRef"] = flight.introRef;
      setIntroSelected(flight.introRef);
    }
    setCurrentFlightData(data);
    setShowInfoModal(true)
  }

  function setFlightFlyWith(event){
    if(event === 'Alone'){
      setFlightPassenger('');
      setShareCost(false);
      setPilotInCharge(true);
      setIntroSelected(null);
    }
    else if(event === 'Member'||event === 'Instructor'){
      if(flightType === 'Intro'||flightType === 'Friend'){
        setFlightPassenger('');
      }
      if(event === 'Instructor'){
        let passengerList = allActivePilots.filter((a)=>(a.SK===flightPassenger));
        if(passengerList.length > 0 && !passengerList[0].isInstructor){
          setFlightPassenger('');
        }
        setShareCost(false);
        setPilotInCharge(false);
      }
      setIntroSelected(null);
    }
    else{
      let passengerList = allActivePilots.filter((a)=>(a.SK===flightPassenger));
      var passenger = passengerList.length > 0 ? passengerList[0].first+' '+passengerList[0].last: flightPassenger;
      if(event === 'Intro'){
        setFlightPassenger('');
        setLaunchType(undefined);
        setPilotInCharge(true);
      }
      else
      {
        setFlightPassenger(passenger);
        setIntroSelected(null);
      }
      setShareCost(false);
    }
    setFlightType(event);
  }

  function setStart(date){
    setAllFlights(null);
    setAllGliderFlights(null);
    setAllTowFlights(null);
    setPilotFlights(null);

    if(date>endDate){
      setEndDate(endDate);
    }
    setStartDate(date);
  }

  function setEnd(date){
    setAllFlights(null);
    setAllGliderFlights(null);
    setAllTowFlights(null);
    setPilotFlights(null);

    if(startDate>date){
      setStartDate(date);
    }
    setEndDate(date);
  }
  
  function selectIntro(item){
    const intro = returnIntro(club, item, intros);
    setIntroSelected(intro.SK);
    setLaunchType(intro.launchType);
    setFlightPassenger(intro.recipientFirst+' '+intro.recipientLast);
  }

  function filterIntro(filterText, intro) {
    const lowerFilter = filterText.toLowerCase();
    let match = (filterText === "") ? true : false;
    if (!match) {
      match = intro.SN.toLowerCase().includes(lowerFilter)
    }
    if (!match) {
      const fullName = `${intro.recipientFirst} ${intro.recipientLast}`.toLowerCase();
      match = fullName.includes(lowerFilter)
    }
    if (!match) {
      match = intro.buyerEmail.toLowerCase().includes(lowerFilter)
    }
    return (match)
  }

  function updateLaunchType(launchId) {
    setLaunchType(launchId)
    const launch = launchTypes.find((t) => t.SK === launchId)
    if(launch) {
      if(launch.heightRanges != null && releaseHeight == null) {
        setReleaseHeight(0)
      } else if(launch.heightRanges == null && releaseHeight != null) {
        setReleaseHeight(null)
      }
    }
  }

  function renderFlightInfoForm() {
    var pilotList = allActivePilots.filter((a)=>(a.SK===currentFlightData.user));
    var passengerList = allActivePilots.filter((a)=>(a.SK===currentFlightData.passengerID));
    var pilot = pilotList.length > 0 ? pilotList[0] : '';
    var passenger = passengerList.length > 0 ? passengerList[0] : currentFlightData.passengerID;
    var newPilotList = allActivePilots.filter((a)=>(a.SK===flightPilot));
    var newPassengerList = allActivePilots.filter((a)=>(a.SK===flightPassenger));
    var newPilot = newPilotList.length > 0 ? newPilotList[0] : '';
    var newPassenger = newPassengerList.length > 0 ? newPassengerList[0] : '';
    var oldTowPilotList = allActivePilots.filter((a)=>(a.SK===currentFlightData.towPilot));
    var oldTowPilot = oldTowPilotList.length > 0 ? oldTowPilotList[0] : '';
    const flightTypes = ["Alone", "Instructor","Member","Intro","Friend"];

    return (
      <div className="d-flex flex-column gap-3">
        <FormGroup>
          <InputGroup>
            <InputGroup.Text>Select Glider:</InputGroup.Text>
            <FormControl as="select" value = {flightGlider} onChange = {(e) => setFlightGlider(e.target.value)}>
              {<option key="current" value= {currentFlightData.glider}>{currentFlightData.glider}</option>}
              {allGliders.filter((a) => (a.SK!==currentFlightData.glider)).map((item, i) =>
                <option key={i} value={item.SK}> {item.SK} </option>
                )}
            </FormControl>
          </InputGroup>
          {currentFlightData.glider !== flightGlider && <Form.Text>Original: {currentFlightData.glider}</Form.Text>}
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroup.Text>Select Pilot:</InputGroup.Text>
            <FormControl as="select" value = {flightPilot} onChange = {(e) => setFlightPilot(e.target.value)}>
              {<option key="current" value= {pilot.SK}>{pilot.first} {pilot.last}</option>}
              {allActivePilots.filter((a) => (a.SK!==pilot)).map((item, i) =>
                <option key={i} value={item.SK}> {item.first} {item.last} </option>
                )}
            </FormControl>
          </InputGroup>
          {pilot.SK !== flightPilot && <Form.Text>Original: {pilot.first} {pilot.last}</Form.Text>}
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroup.Text>Flying With:</InputGroup.Text>
            <FormControl as="select" value = {flightType} onChange = {(e) => setFlightFlyWith(e.target.value)}>
              {<option key="current" value={currentFlightData.flyWith}>{currentFlightData.flyWith}</option>}
              {flightTypes.filter((a) => (a!==currentFlightData.flyWith)).map((item, i) =>
                <option key={i} value={item}> {item} </option>
                )}
            </FormControl>
          </InputGroup>
          {currentFlightData.flyWith !== flightType && <Form.Text>Original: {currentFlightData.flyWith}</Form.Text>}
        </FormGroup>
        { flightType === 'Intro' && 
            <>
            <FormGroup controlId="introSearch" size="lg">
            <InputGroup>
            <InputGroup.Text>Search for intro: </InputGroup.Text>
            <FormControl
                type="text"
                placeholder="Start typing reference number, name, or email"
                value = {searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </InputGroup>
            </FormGroup>
            { intros.filter((a) => (filterIntro(searchText, a))).slice(0,5).map((item, i) =>
                <Button key={i} value={item.SK} onClick = {(e) => selectIntro(e.target.value)}>{item.SN}, {item.recipientFirst} {item.recipientLast}, {item.buyerEmail}</Button>
                )
            }
            </>
        }
        {(flightType==='Member') &&
        <div>
          <FormGroup>
            <InputGroup>
              <InputGroup.Text>Select Passenger:</InputGroup.Text>
              <FormControl as="select" value = {flightPassenger} onChange = {(e) => setFlightPassenger(e.target.value)}>
                {flightPassenger!==''&&passenger!==""&&<option key="current" value={passenger.SK}>{passenger.first} {passenger.last}</option>}
                {flightPassenger===""&&<option key="current" value={''}>Member</option>}
                {allActivePilots.filter((a) => (a.SK!==passenger)).map((item, i) =>
                  <option key={i} value={item.SK}> {item.first} {item.last} </option>
                )}
              </FormControl>
            </InputGroup>
            {typeof passenger !== 'string' && flightPassenger !== passenger.SK && <Form.Text>Original: {passenger.first} {passenger.last}</Form.Text>}
            {typeof passenger === 'string' && flightPassenger !== passenger && <Form.Text>Original: {passenger === '' ? "None" : passenger}</Form.Text>}
          </FormGroup>
        </div>
        }
        {(flightType==='Instructor') &&
        <div>
          <FormGroup>
            <InputGroup>
              <InputGroup.Text>Select Instructor:</InputGroup.Text>
              <FormControl as="select" value={flightPassenger} onChange = {(e) => setFlightPassenger(e.target.value)}>
                {allActivePilots.filter((a) => (a.SK===passenger.SK && a.isInstructor)).length===1&&<option key="current" value={passenger.SK}>{passenger.first} {passenger.last}</option>}
                {allActivePilots.filter((a) => (a.SK===passenger.SK && a.isInstructor)).length===0&&<option key="current" value={''}>Instructor</option>}
                {allActivePilots.filter((a) => (a.SK!==passenger.SK)&&(a.isInstructor === true)).map((item, i) =>
                  <option key={i} value={item.SK}> {item.first} {item.last} </option>
                )}
              </FormControl>
            </InputGroup>
            {typeof passenger !== 'string' && flightPassenger !== passenger.SK && <Form.Text>Original: {passenger.first} {passenger.last}</Form.Text>}
            {typeof passenger === 'string' && flightPassenger !== passenger && <Form.Text>Original: {passenger === '' ? "None" : passenger}</Form.Text>}
          </FormGroup>
        </div>
        }
        { (flightType === 'Intro' || flightType === 'Friend') && 
        <div>
          <FormGroup controlId="passengerFirst" size="lg">
            <InputGroup>
            {<InputGroup.Text>Passenger Name: </InputGroup.Text>}
              <FormControl
                type="text"
                value={flightPassenger}
                disabled = {flightType === 'Intro'}
                onChange={e => setFlightPassenger(e.target.value)}
                />
            </InputGroup>
            {typeof passenger !== 'string' && flightPassenger !== passenger.SK && <Form.Text>Original: {passenger.first} {passenger.last}</Form.Text>}
            {typeof passenger === 'string' && flightPassenger !== passenger && <Form.Text>Original: {passenger === '' ? "None" : passenger}</Form.Text>}
          </FormGroup>
        </div>
        }          
        { ((flightType === "Instructor") || (flightType === "Member") ||  (flightType === "Friend")) &&
          <>
          <FormGroup controlId="pilotInCharge" size="lg"><h4 style={{display: "inline"}}>Pilot in Command: </h4>
            <ToggleButtonGroup name="pilotFormCheck" type="radio" value={pilotInCharge} onChange={setPilotInCharge}>
              <ToggleButton id="Instructor" name="pilotFormCheck" value={true} disabled={flightType === "Instructor"}>{newPilot.first} {newPilot.last}</ToggleButton>
              {newPassenger !== '' && <ToggleButton id="passengerName" name="pilotFormCheck" value={false} disabled={flightType === "Instructor"}>
                {(newPassenger === "") ? flightType : newPassenger.first+' '+newPassenger.last}
              </ToggleButton>}
              {newPassenger === '' && <ToggleButton id="unnamed" name="pilotFormCheck" value={false} disabled={flightType === "Instructor"}>
                {(flightPassenger === "") ? flightType : flightPassenger}
              </ToggleButton>}
            </ToggleButtonGroup>
          </FormGroup>
          </>
        }
        {flightType === 'Member' && 
        <FormGroup controlId="splitCost" size="lg"><h4 style={{display: "inline"}}>Share Flight Cost? </h4>
          <ToggleButtonGroup name="splitFormCheck" type="radio" value={shareCost} onChange={setShareCost}>
            <ToggleButton id="No" name="splitFormCheck" value={false}>No</ToggleButton>
            <ToggleButton id="Yes" name="splitFormCheck" value={true}>Yes</ToggleButton>
          </ToggleButtonGroup>
        </FormGroup>
        }
        <FormGroup>
          <InputGroup>
            <InputGroup.Text>Select Launch Type:</InputGroup.Text>
            <FormControl as="select" value={launchType} onChange={(e) => updateLaunchType(e.target.value)} disabled={flightType === 'Intro'}>
              {launchType === '' && <option key="current" value={''}>Launch Type</option>}
              {launchTypes.map((item, i) =>
                <option key={i} value={item.SK} > {item.SK} </option>
              )}
            </FormControl>
          </InputGroup>
          {currentFlightData.launchType !== launchType && <Form.Text>Original: {currentFlightData.launchType}</Form.Text>}
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroup.Text>Select Tow Pilot:</InputGroup.Text>
            <FormControl as="select" value = {towPilot} onChange = {(e) => setTowPilot(e.target.value)}>
              {!towPilot && <option key="current" value= {currentFlightData.towPilot}>{"None"}</option>}
              {towPilot && allActivePilots.filter((a) => (a.SK === currentFlightData.towPilot)).map((item, i) =>
                <option key={i} value={item.SK}> {item.first} {item.last} </option>
                )}
              {allActivePilots.filter((a) => (a.SK !== currentFlightData.towPilot && a.isTowPilot)).map((item, i) =>
                <option key={i+1} value={item.SK}> {item.first} {item.last} </option>
                )}
            </FormControl>
          </InputGroup>
          {currentFlightData.towPilot !== towPilot && <Form.Text>Original: {typeof oldTowPilot !== 'string' ? oldTowPilot.first+' '+oldTowPilot.last : "None"}</Form.Text>}
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroup.Text>Select Tug:</InputGroup.Text>
            <FormControl as="select" value = {tug} onChange = {(e) => setTug(e.target.value)}>
              {!tug && <option key="current" value= {currentFlightData.tug}>{"None"}</option>}
              {currentFlightData.tug && <option key="current" value= {currentFlightData.tug}>{currentFlightData.tug}</option>}
              {tugs.filter((a) => (a.SK!==currentFlightData.tug)).map((item, i) =>
                <option key={i} value={item.SK}> {item.SK} </option>
                )}
            </FormControl>
          </InputGroup>
          {currentFlightData.tug !== tug && <Form.Text>Original: {currentFlightData.tug ? currentFlightData.tug : "None"}</Form.Text>}
        </FormGroup>
        <FormGroup controlId="takeoffTime" size="lg">
          <InputGroup>
          <InputGroup.Text>Launch Time:</InputGroup.Text>
            <FormControl
              type="time"
              step="60000"
              defaultValue={flightTakeoff}
              onChange={e => setFlightTakeoff(e.target.value)}
              />
          </InputGroup>
          {currentFlightData.takeoff !== flightTakeoff && <Form.Text muted>Original: {isoTimeToLocaleTime(currentFlightData.takeoff)}</Form.Text>}
        </FormGroup>
        <FormGroup controlId="landingTime" size="lg">
          <InputGroup>
          <InputGroup.Text>Landing Time:</InputGroup.Text>
            <FormControl as = 'input'
              type="time"
              step="60000"
              defaultValue={flightLanding}
              onChange={e => setFlightLanding(e.target.value)}
              />
          </InputGroup>
          {currentFlightData.landing !== flightLanding && <Form.Text muted>Original: {isoTimeToLocaleTime(currentFlightData.landing)}</Form.Text>}
        </FormGroup>
        {releaseHeight != null && 
          <FormGroup controlId="releaseHeight" size="lg">
            <InputGroup>
            <InputGroup.Text>Release Height:</InputGroup.Text>
            <FormControl
              as = 'input'
              type="number"
              min="0"
              defaultValue={releaseHeight}
              onChange={e => setReleaseHeight(e.target.value)}
            />
            <InputGroup.Text>{heightUnit}</InputGroup.Text>
            </InputGroup>
            {currentFlightData.releaseHeight?.toString() !== releaseHeight && <Form.Text muted>Original: {releaseHeightToString(currentFlightData.releaseHeight)}</Form.Text>}
          </FormGroup>
        }
      </div>
    )
  }

  const heightUnit = useMemo(() => {
    if(!clubUnits) return "ft"
    return clubUnits.height ? clubUnits.height.S : "ft"
  }, [clubUnits])

  function releaseHeightToString(releaseHeight) {
    const height = parseInt(releaseHeight)
    if(releaseHeight == null) {
      return "N/a"
    } else if(height === 0 || Number.isNaN(height)) {
      return "Not Recorded"
    } else {
      return `${height} ${heightUnit}`
    }
  }

  function renderFlightInfo() {
    const infoEntries = []

    const releaseHeightStr = releaseHeightToString(currentFlightData.releaseHeight)

    infoEntries.push({label: "Glider", value: currentFlightData.glider})
    infoEntries.push({label: "Pilot", value: nameFromPilotId(currentFlightData.user, allPilots)})
    infoEntries.push({label: "Flying with", value: currentFlightData.flyWith})
    if(flightType === 'Member') {
      infoEntries.push({label: "Passenger", value: nameFromPilotId(currentFlightData.passengerID, allPilots)})
      infoEntries.push({label: "Cost Shared", value: currentFlightData.shareCost ? "Yes" : "No"})
    }
    if(flightType === 'Intro' || flightType === 'Friend') {
      infoEntries.push({label: "Passenger", value: currentFlightData.passengerID})
    }
    if(flightType === 'Instructor') {
      infoEntries.push({label: "Instructor", value: nameFromPilotId(currentFlightData.passengerID, allPilots)})
    }
    if(flightType === "Instructor" || flightType === "Member" ||  flightType === "Friend" || flightType === "Intro"){
      infoEntries.push({label: "Pilot in Charge", value: currentFlightData.P1})
    }
    infoEntries.push({label: "Launch Type", value: currentFlightData.launchType})
    infoEntries.push({label: "Tow Pilot", value: nameFromPilotId(currentFlightData.towPilot, allPilots)})
    infoEntries.push({label: "Tug", value: currentFlightData.tug ? currentFlightData.tug : "None"})
    infoEntries.push({label: "Date", value: currentFlightData.dateString})
    infoEntries.push({label: "Takeoff", value: isoTimeToLocaleTime(currentFlightData.takeoff)})
    infoEntries.push({label: "Landing", value: isoTimeToLocaleTime(currentFlightData.landing)})
    infoEntries.push({label: "Release Height", value: releaseHeightStr})
    if ((userID === currentFlightData.user) && (currentFlightData.flyWith !== "Intro")) {
      infoEntries.push({label: "Launch Cost", value: `$${currentFlightData.launchCost ?? 0}`})
      infoEntries.push({label: "Flight Cost", value: `$${currentFlightData.flightCost ?? 0}`})
    }
    infoEntries.push({label: "Flight ID", value: currentFlightData.SK})
    return(
      <div className="d-flex flex-column gap-3">
        {infoEntries.map((entry, i) =>
          <span key={i}>
            <strong>{entry.label}:&nbsp;</strong>{entry.value}
          </span>
        )}
        {(userIsAdmin || userCanEditLogs) &&  
        <Button onClick={() => setInfoModalState(ModalState.EDIT)} disabled={currentFlightData.flightLocked}>
          {currentFlightData.flightLocked ? "Flight Locked" : "Edit Flight"}
        </Button>}

        {renderFlightRevisions()}
      </div>
    )
  }

  function renderFlightRevisions() {
    return (
      <section>
        <Accordion defaultActiveKey={expandRevisions ? '0' : ''} onSelect={(e) => setExpandRevisions(e !== null)}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Revisions</Accordion.Header>
            <Accordion.Body className="overflow-x-scroll">
              {(currentFlightData.history && currentFlightData.history.length > 0) || currentFlightData.flightLocked ?
                <RevisionsTable currentFlightData={currentFlightData} allPilots={allPilots} rawFlights={rawFlights}  /> :
                <span>This flight record has not been modified</span>
              }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    )
  }

  function renderModalContent(){
    switch(infoModalState){
      default:
      case ModalState.INFO:
        return(<>
          <Modal.Header closeButton>
            <Modal.Title>Flight Info</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {(!allGliders || !allActivePilots || !launchTypes || !tugs) ?
              <div className="d-flex align-items-center justify-content-center my-2">
                <Spinner/>
              </div>
              : (showInfoModal && renderFlightInfo())
            }
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={() => resetFlightInfo()}>Close</Button>
          </Modal.Footer>
        </>)
      case ModalState.EDIT:
        return(<>
          <Modal.Header closeButton>
            <Modal.Title>Editing Flight</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {(!allGliders || !allActivePilots || !launchTypes || !tugs) ?
              <div className="d-flex align-items-center justify-content-center my-2">
                <Spinner/>
              </div>
              : (showInfoModal && renderFlightInfoForm())
            }
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setInfoModalState(ModalState.INFO)}>Cancel</Button>
            <Button onClick={() => setInfoModalState(ModalState.CONFIRM)}>Submit Changes</Button>
          </Modal.Footer>
        </>)
      case ModalState.CONFIRM:
        return(<>
          <Modal.Header>
            <Modal.Title>Confirm Changes</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {renderFlightChanges()}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setInfoModalState(ModalState.EDIT)}>Back</Button>
            <LoaderButton isLoading={isSubmitting} onClick={() => submitAPICall()}>Confirm</LoaderButton>
          </Modal.Footer>
        </>)
    }
  }

  function closeModal() {
    setInfoModalState(ModalState.INFO)
    setShowInfoModal(false)
  }

  function renderFlightInfoModal(){
    return (
      <>
        <Modal show={showInfoModal} fullscreen="sm-down" onHide={closeModal} background="static" animation={false}
          dialogClassName="modal-dialog-max-width"
        >
          {renderModalContent()}
        </Modal>
      </>
    )
  }

  //displays new flight info, with old flight info crossed out
  function renderFlightChanges(){
    var pilotList = allActivePilots.filter((a)=>(a.SK===flightPilot));
    var passengerList = allActivePilots.filter((a)=>(a.SK===flightPassenger));
    var pilot = pilotList.length > 0 ? pilotList[0] : null;
    var passenger = passengerList.length > 0 ? passengerList[0] : null;
    var oldTowPilotList = allActivePilots.filter((a)=>(a.SK===currentFlightData.towPilot));
    var oldTowPilot = oldTowPilotList.length > 0 ? oldTowPilotList[0] : null;
    var newTowPilotList = allActivePilots.filter((a)=>(a.SK===towPilot));
    var newTowPilot = newTowPilotList.length > 0 ? newTowPilotList[0] : null;
    return (
      <>
        <div className="fs-4">
          <span className="fw-semibold">Glider:</span>
          {currentFlightData.glider !== flightGlider && <span style = {{"textDecoration":"line-through"}}> {currentFlightData.glider}</span>}
          <span> {flightGlider}</span>
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Pilot:</span>
          {currentFlightData.user !== flightPilot && 
          <span style = {{"textDecoration":"line-through"}}> {currentFlightData.pilotInCharge ? currentFlightData.P1 : currentFlightData.P2}</span>}
          <span> {pilot ? pilot.first+' '+pilot.last : 'None'}</span>
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Flying With:</span>
          {currentFlightData.flyWith !== flightType && <span style = {{"textDecoration":"line-through"}}> {currentFlightData.flyWith}</span>}
          <span> {flightType}</span>
        </div>
        {currentFlightData.flyWith !== 'Alone' && flightType!=='Alone'&&
          <div className="fs-4">
            <span className="fw-semibold">Passenger:</span> 
            {currentFlightData.passengerID !== flightPassenger && 
            <span style = {{"textDecoration":"line-through"}}> {currentFlightData.pilotInCharge ? currentFlightData.P2 : currentFlightData.P1}</span>}
            <span> {passenger ? passenger.first+' '+passenger.last : flightPassenger}</span>
          </div>
        }
        {currentFlightData.flyWith === 'Alone' && flightType!=='Alone'&&
          <div className="fs-4">
            <span className="fw-semibold">Passenger:</span>
            <span style = {{"textDecoration":"line-through"}}> None</span>
            <span> {passenger ? passenger.first+' '+passenger.last : flightPassenger}</span>
          </div>
        }
        {currentFlightData.flyWith !== 'Alone' && flightType==='Alone'&&
          <div className="fs-4">
            <span className="fw-semibold">Passenger:</span> 
            <span style = {{"textDecoration":"line-through"}}> {currentFlightData.pilotInCharge ? currentFlightData.P2 : currentFlightData.P1}</span>
            <span> None</span>
          </div>
        }
        {(currentFlightData.flyWith==='Member' || flightType==='Member') && 
          <div className="fs-4">
            <span className="fw-semibold">Sharing Cost?:</span> 
            {currentFlightData.shareCost !== shareCost && 
              <span style = {{"textDecoration":"line-through"}}> {currentFlightData.shareCost.toString()}</span>}
            <span> {shareCost.toString()}</span>
          </div>
        }
        <div className="fs-4">
          <span className="fw-semibold">Pilot in Command:</span>
          {currentFlightData.pilotInCharge !== pilotInCharge && <span style = {{"textDecoration":"line-through"}}> {currentFlightData.P1}</span>} 
          {passenger && <span> {pilotInCharge ? pilot.first+' '+pilot.last : passenger.first+' '+passenger.last}</span>}
          {!passenger && <span> {pilotInCharge ? pilot.first+' '+pilot.last : flightPassenger}</span>}
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Launch:</span>
          {currentFlightData.launchType !== launchType && <span style = {{"textDecoration":"line-through"}}> {currentFlightData.launchType}</span>}
          <span> {launchType}</span>
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Takeoff Time:</span>
          {currentFlightData.takeoff !== flightTakeoff && <span style = {{"textDecoration":"line-through"}}> {isoTimeToLocaleTime(currentFlightData.takeoff)}</span>}
          <span> {isoTimeToLocaleTime(flightTakeoff)}</span>
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Landing Time:</span>
          {currentFlightData.landing !== flightLanding && <span style = {{"textDecoration":"line-through"}}> {isoTimeToLocaleTime(currentFlightData.landing)}</span>}
          <span> {isoTimeToLocaleTime(flightLanding)}</span>
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Release Height:</span>
            {releaseHeightToString(currentFlightData.releaseHeight) !== releaseHeightToString(releaseHeight) && 
            <span style = {{"textDecoration":"line-through"}}> {releaseHeightToString(currentFlightData.releaseHeight)}</span>}
            <span> {releaseHeightToString(releaseHeight)}</span>
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Tow Pilot:</span>
          {currentFlightData.towPilot && currentFlightData.towPilot !== towPilot && <span style = {{"textDecoration":"line-through"}}> {oldTowPilot ? oldTowPilot.first+' '+oldTowPilot.last : "None"}</span>}
          {!currentFlightData.towPilot && towPilot && <span style = {{"textDecoration":"line-through"}}> None</span>}
          <span> {newTowPilot ? newTowPilot.first+' '+newTowPilot.last : "None"}</span>
        </div>
        <div className="fs-4">
          <span className="fw-semibold">Tug:</span>
          {currentFlightData.tug && currentFlightData.tug !== tug && <span style = {{"textDecoration":"line-through"}}> {currentFlightData.tug}</span>}
          {!currentFlightData.tug && tug && <span style = {{"textDecoration":"line-through"}}> None</span>}
          <span> {tug ? tug : "None"}</span>
        </div>
      </>
    );
  }

  async function submitAPICall(){
    setIsSubmitting(true)
    var pilot = allActivePilots.filter((a)=>(a.SK===flightPilot));
    var passenger = allActivePilots.filter((a)=>(a.SK===flightPassenger));
    const launchDate = DateTime.fromISO(`${currentFlightData.dateString}T${flightTakeoff}`, {zone: clubTimeZone})
    const landDate = DateTime.fromISO(`${currentFlightData.dateString}T${flightLanding}`, {zone: clubTimeZone})

    // Since the time inputs stripped seconds / milliseconds we check if the new date is equivalent to the old date
    const ogTakeoff = DateTime.fromMillis(currentFlightData.takeoffMillis ?? 0)
    const ogLanding = DateTime.fromMillis(currentFlightData.landingMillis ?? 0)
    const takeoffChanged = ogTakeoff.set({second: 0, millisecond: 0}).toMillis() !== launchDate.toMillis()
    const landingChanged = ogLanding.set({second: 0, millisecond: 0}).toMillis() !== landDate.toMillis()
    const newReleaseHeight = parseInt(releaseHeight)

    if(!Number.isNaN(newReleaseHeight) && newReleaseHeight < 0) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Release height must be positive"
      })
      setIsSubmitting(false)
      return
    }

    const data = {
      "action":"ChangeFlight",
      "by":userID,
      "club":club,
      "date":currentFlightData.dateString,//localDateString,
      "id":currentFlightData.SK,
      "glider":flightGlider,
      "first":pilot.length > 0 ? pilot[0].first : '',
      "last":pilot.length > 0 ? pilot[0].last : '',
      "user":pilot.length > 0 ? pilot[0].SK : '',
      "passengerFirst":passenger.length > 0 ? passenger[0].first : flightPassenger.substring(0,flightPassenger.indexOf(" ")),
      "passengerLast":passenger.length > 0  ? passenger[0].last : flightPassenger.substring(flightPassenger.indexOf(" ")+1),
      "passengerID":passenger.length > 0  ? passenger[0].SK : '',
      "pilotInCharge":pilotInCharge,
      "towPilot":towPilot,
      "tug":tug,
      "launchType":launchType,
      "shareCost":flightType === "Member" ? shareCost : false,
      "flyWith":flightType,
      "launchedAt": takeoffChanged ? launchDate.toMillis() : currentFlightData.takeoffMillis,
      "landedAt": landingChanged ? landDate.toMillis() : currentFlightData.landingMillis,
      'launchTransaction': currentFlightData.launchTransaction,
      'landingTransaction':currentFlightData.landingTransaction,
      "releaseHeight": !Number.isNaN(newReleaseHeight) ? newReleaseHeight : releaseHeight == null ? null : 0
    }
    if(introSelected){
      data["introRef"] = introSelected;
    } 
    else if(currentFlightData.flyWith === 'Intro' && flightType !== 'Intro'){
      data["introRef"] = '';
      const oldIntroBody = {
        'type':'INTRO',
        'id':currentFlightData.introRef,
        'club':club,
        'data':{'flightRef':''},
      }
      await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-item/", options: {'body':oldIntroBody}});
    }
    if(flightType === 'Intro'){
      const newIntroBody = {
        'type':'INTRO',
        'id':introSelected,
        'club':club,
        'data':{'flightRef':currentFlightData.SK},
      };
      await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-item/", options: {'body':newIntroBody}});
    }
    const response = await apiRequest(RequestType.POST, {apiName: "flightline", path:  "/update-flight/", options: {"body": data}});
    if(response.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: response.body.message ?? "Could not update flight",
      })
    } else {
      await loadFlights()
      resetFlightInfo();
      sendToast({
        status: NotificationStatus.Success,
        message: `Sucessfully updated flight ${data.id}`
      })
    }
    setIsSubmitting(false)
  }

  function resetFlightInfo(){
    setShowInfoModal(false)
    setInfoModalState(ModalState.INFO)
    setCurrentFlightData({});
    setFlightGlider(null);
    setFlightPilot(null);
    setFlightPassenger(null);
    setFlightType(null);
    setLaunchType(null);
    setFlightTakeoff(null);
    setFlightLanding(null);
    setReleaseHeight(null);
    setPilotInCharge(null);
    setTowPilot(null);
    setTug(null);
  }

  function localeDateString(dateStr) {
    const date = new Date(dateStr)
    // offset the date being interpreted as UTC
    date.setTime(date.getTime() + (tzoffset*60*1000))
    return date.toLocaleDateString(window.navigator.language, {
      year: "numeric",
      month: "short",
      day: "numeric"
    }) 
  }

  return (
    <>
      {renderFlightInfoModal()}
      <h1 className="text-center my-3">
        {startDate !== endDate && `Flight Logs for ${localeDateString(startDate)} to ${localeDateString(endDate)}`}
        {startDate === endDate && `Flight Logs for ${localeDateString(startDate)}`}
      </h1>
        <Form>
        <div className="d-flex flex-sm-row flex-column gap-3 justify-content-between text-sm-center text-start">
          <div>
            <FormGroup controlId="startDate" size="lg">
              <FormLabel>Start Date:</FormLabel>
              <FormControl
                autoFocus
                type="date"
                value={startDate}
                disabled={startDate === ""}
                onChange={e => setStart(e.target.value)}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup controlId="endDate" size="lg">
              <FormLabel>End Date:</FormLabel>
              <FormControl
                type="date"
                value={endDate}
                disabled={endDate === ""}
                onChange={e => setEnd(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        </Form>
      <hr></hr>
      <Container>
        <Row>
          <Col xs={6}>
            <Button variant="outline-secondary" type="button" disabled={false} active={actionType === "by time"} onClick = {() => changeList("by time")}>
              All flights
            </Button>
            <Button variant="outline-secondary" type="button" disabled={false} active={actionType === "personal"} onClick = {() => changeList("personal")}>
              My flights
            </Button>
            <Button variant="outline-secondary" type="button" disabled={false} active={actionType === "by tow"} onClick = {() => changeList("by tow")}>
              Tows
            </Button>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <InputGroup>
                <InputGroup.Text>Filter by: </InputGroup.Text>
                {(actionType === "by time") &&
                  <FormControl title="glider filter" as="select" value={flightViewType} onChange = {(e) => setGliderView(e.target.value)}>
                    {["All"].concat(gliders).map((item) =>
                      <option key={item + "1"} value={item}> {item} </option>
                    )}
                  </FormControl>
                }
                {(actionType === "personal") &&
                  <FormControl title="glider filter" as="select" value={flightViewType} onChange = {(e) => setGliderView(e.target.value)}>
                    {["All"].concat(pilotGliders).map((item) =>
                      <option key={item + "2"} value={item}> {item} </option>
                    )}
                  </FormControl>
                }
                {(actionType === "by tow") &&
                  <FormControl title="tow pilot filter" as="select" value={flightViewType} onChange = {(e) => setTowView(e.target.value)}>
                    {towPilots.map((item) =>
                      <option key={item.SK} value={item.SK}>{item.first} {item.last}</option>
                    )}
                  </FormControl>
                }
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Container>

      <div>
        <p>{flightSummary}</p>
      </div>

      <div className="container p-2">
        <Alert show={errorMsg.length > 0} variant="danger" onClose={() => setErrorMsg("")} dismissible>{errorMsg}</Alert>
        <div className="row overflow-x-scroll">
          <div className="col">
            { actionType === "personal" &&
              <SelectableTable handler = {selectRow} headers={Object.values(userSchema)} items={Object.keys(userSchema)} rows={pilotFlights} />
            }
            { (actionType === "by time") &&
              <SelectableTable handler = {selectRow} headers={Object.values(schema)} items={Object.keys(schema)} rows={allGliderFlights} />
            }
            { (actionType === "by tow") &&
              <SelectableTable handler = {selectRow} headers={Object.values(towSchema)} items={Object.keys(towSchema)} rows={allTowFlights} />
            }
          </div>
        </div>
      </div>
    </>
  );
}
