import React, {useState, useEffect} from "react";
import { FormGroup, FormControl, Row, Col, Container, InputGroup, Button} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { loadPilots, getUserData } from "../libs/databaseAccess";
import { isUserActive } from "../libs/utils";

export default function Emergency() {
    const { userID, club, clubName, isAuthenticated, clubTimeZone } = useAppContext();
    const [pilots, setPilots] = useState([]);
    const [pilotID, setPilotID] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [pilotRecord, setPilotRecord] = useState({});

     useEffect(() => {
        if (!isAuthenticated || userID === "" || club === "") {
          return;
        }
         async function onLoad() {
            try {
                const pilots = await loadPilots(club);
                setPilots(pilots.filter((a) => isUserActive(a, clubTimeZone)).sort((a, b) => (a.first+a.last).localeCompare(b.first+b.last)));
                setPilotID(userID);
            } catch (e) {
                onError(e);        
            }
          }
      
        onLoad();
        setIsLoading(false);
    }, [isAuthenticated, club, userID, clubTimeZone]);

    useEffect(() => {
      if (pilotID === "" || club === "") {
        return;
      }

      async function updatePilotInfo() {
        const pilot = await getUserData(club, pilotID);
        setPilotRecord(pilot);
      }

      updatePilotInfo();
    }, [club, pilotID]);

    async function setValues(id){
        setPilotID(id);
    }
      
    return(
        <>
        <h1 className = "centerHeader">{clubName}</h1>
        <h3>Emergency Contacts &amp; Member Directory</h3>
        { !isLoading &&
        <FormGroup>
        <InputGroup>
        <InputGroup.Text>Select Pilot: </InputGroup.Text> 
        <FormControl as="select" value={pilotID} onChange = {(e) => setValues(e.target.value)} title={"Pilots"}>
          { pilots.map((item) =>
            <option key={item.SK} value={item.SK}> {item.first} {item.last} </option>
          )}
        </FormControl>
        </InputGroup>
        </FormGroup>
        }
        { (pilotID === userID) &&
          <LinkContainer key = "profile" to = "/settings/profile">
            <Button>Update my personal info</Button>
          </LinkContainer>
        }
        {(pilotID === "") ? "" : (
            <Container>
              { (pilotRecord.emLink || pilotRecord.emNote) &&
                <>
                  <Row>
                      <h3>Tracking Info:</h3>
                  </Row>
                  <h4>
                    { pilotRecord.emLink &&
                    <Row>
                      <Col xs={6} className="text-right"> Tracking link: </Col>
                      <Col xs={6} className="text-left"> <a href = {pilotRecord.emLink} target="_blank" rel="noopener noreferrer"> {pilotRecord.emLink}</a> </Col>
                    </Row>
                    }
                    { pilotRecord.emNote &&
                    <Row>
                      <Col xs={6} className="text-right"> Tracking instructions: </Col>
                      <Col xs={6} className="text-left"> {pilotRecord.emNote} </Col>
                    </Row>
                    }
                  </h4><br></br>
                </>
              }
              <Row>
                  <h3>Emergency Contact:</h3>
              </Row>
              <h4>
              <Row>
                <Col xs={6} className="text-right"> Name: </Col>
                <Col xs={6} className="text-left"> {pilotRecord.emContact} </Col>
              </Row>
              <Row>
                <Col xs={6} className="text-right"> Phone: </Col>
                <Col xs={6} className="text-left"> <a href={`tel:${pilotRecord.emPhone}`}> {pilotRecord.emPhone} </a></Col>
              </Row>
              <Row>
                <Col xs={6} className="text-right"> Relation: </Col>
                <Col xs={6} className="text-left"> {pilotRecord.emRelation} </Col>
              </Row>
              </h4><br></br>
              {(pilotRecord.shareEmail || pilotRecord.sharePhone || pilotRecord.shareAddress) &&
              <>
                <Row></Row>
                <Row>
                  <h3> Personal Info: </h3>
                </Row>
                <h4>
                { pilotRecord.shareEmail &&
                <Row>
                  <Col xs={6} className="text-right"> Email: </Col>
                  <Col xs={6} className="text-left"> <a href={`mailto:${pilotRecord.email}`}> {pilotRecord.email} </a></Col>
                </Row> }
                { pilotRecord.sharePhone &&
                <Row>
                  <Col xs={6} className="text-right"> Phone: </Col>
                  <Col xs={6} className="text-left"> <a href={`tel:${pilotRecord.phone}`}> {pilotRecord.phone} </a></Col>
                </Row> }
                { pilotRecord.shareAddress &&
                <Row>
                  <Col xs={6} className="text-right"> Address: </Col>
                  <Col xs={6} className="text-left"> {pilotRecord.address} </Col>
                </Row> }
              </h4>
              </>
              }  
            </Container>
          )
      } 
      </>
    )
}