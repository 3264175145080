import React, { useMemo, useState, useEffect } from "react";
import { Form, FormGroup, Button, Modal } from "react-bootstrap";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "./LoaderButton";
import {addClubFee, removeClubFee } from "../libs/databaseAccess";

export default function ClubFeeAdmin() {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const { club, clubFeeList, setClubFeeList } = useAppContext()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [selectedFee, setSelectedFee] = useState("")
  const [newFeeName, setNewFeeName] = useState("")
  const [showNewFeeModal, setShowNewFeeModal] = useState(false)
  
  async function submitNewFee(e) {
    e.preventDefault()
    setIsSubmitting(true)
    const response = await addClubFee(club, newFeeName);
    if(response.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Failed to add new fee class"
      })
    } else {
      sendToast({
        status: NotificationStatus.Success,
        message: "New fee class added"
      })
      setClubFeeList(response.body.feeList ?? [])
      setShowNewFeeModal(false)
    }
    setIsSubmitting(false)
  }

  async function addFee() {
    setNewFeeName("")
    setShowNewFeeModal(true)
  }

  useEffect(() => {
    if(selectedFee === "" && clubFeeList.length > 0){
      setSelectedFee(clubFeeList[0])
    }
  }, [clubFeeList, selectedFee])

  async function submitRemoveFee() {
    setIsRemoving(true)
    const response = await removeClubFee(club, selectedFee);
    if(response.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Failed to remove fee class"
      })
    } else {
      sendToast({
        status: NotificationStatus.Success,
        message: "Fee class removed"
      })
      setClubFeeList(response.body.feeList ?? [])
      setSelectedFee(response.body.feeList[0] ?? "")
    }
    setIsRemoving(false)
  }

  async function removeFee() {
    sendConfirmModal({
      heading: "Remove Fee class",
      body: `Are you sure you want to remove the fee class ${selectedFee}? This won't remove this fee class from existing transactions, but you will not be able to record expenses with this fee class after removing it.`,
      confirmText: "Remove",
      confirmColor: "danger",
      rejectText: "Cancel",
      rejectColor: "secondary",
      confirm: submitRemoveFee
    })
  }

  const feeOptions = useMemo(() => {
    return Array.isArray(clubFeeList) ? clubFeeList : []
  }, [clubFeeList])

  const renderNewFeeModal = () => {
    return (
      <Modal size="lg" show={showNewFeeModal} onHide={() => setShowNewFeeModal(false)}>
        <Modal.Header closeButton>New Fee Class Name</Modal.Header>
        <Modal.Body>
          <Form className="d-flex flex-column gap-3" onSubmit={submitNewFee}>
            <Form.Control
              value={newFeeName}
              autoFocus
              onChange={(e) => setNewFeeName(e.target.value)}
            />
            <LoaderButton isLoading={isSubmitting} type="submit">Add New Fee Class</LoaderButton>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <section>
      <h4>Club Fee Classes</h4>
      {renderNewFeeModal()}
      <FormGroup className="d-flex flex-column gap-3">
        <select className="w-100 py-2 border border-secondary-subtle" value={selectedFee} onChange={(e) => setSelectedFee(e.target.value)} size="5">
          {feeOptions.map(o => 
            <option className="py-1 px-3" key={o} value={o}>{o}</option>
          )}
        </select>
        <Button onClick={addFee} variant="success">Add New Fee Class</Button>
        {selectedFee !== ""&&
          <LoaderButton onClick={removeFee} isLoading={isRemoving} variant="danger">Remove Fee Class: {selectedFee}</LoaderButton>
        }
      </FormGroup>
    </section>

  );
}