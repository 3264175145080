import React, { useEffect, useMemo, useState } from "react";
import { Button, FormControl, FormGroup, InputGroup, Modal, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { RequestType, apiRequest, deleteIntroLaunch } from "../libs/databaseAccess";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { MultiSelect } from "react-multi-select-component";
import { useAppContext } from "../libs/contextLib";
import "./EditModal.css"
import { isEqual } from "lodash";
import { formatTaxOptions } from "../libs/utils";


export default function EditIntroLaunchModal({showModal, closeModal, intro, launches, refreshIntroList}) {
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();
  const {club, clubTaxRates} = useAppContext()

  const [introID, setIntroID] = useState("");
  const [introPrice, setIntroPrice] = useState(0);
  const [introName, setIntroName] = useState("");
  const [introLaunchType, setIntroLaunchType] = useState("");
  const [isDeleting, setIsDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [taxStatus, setTaxStatus] = useState("exempt")
  const [taxList, setTaxList] = useState([])

  const modalTitle = useMemo(() => {
    if(!intro) return "Edit Intro Launch";
    if(intro.SK === "new") return "Create New Intro Launch"
    return `Edit ${intro.SK}`
  }, [intro])

  const taxOptions = useMemo(() => {
    return formatTaxOptions(clubTaxRates)
  }, [clubTaxRates])
  
  const taxSelectRenderer = (selected) => {
    if(selected.length < 1) return "None";
    let str = "";
    for(const i in selected) {
      str += selected[i].value
      if(i < selected.length - 1) {
        str += ", "
      }
    }
    return str
  }

  const confirmDelete = async () => {
    const result = await deleteIntroLaunch(club, introID);

    if(result.statusCode !== 200) {
      await refreshIntroList()
      sendToast({
        status: NotificationStatus.Success,
        message: "Intro launch deleted successfully"
      })
      closeModal()
    } else {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not delete intro launch"
      })
    }

    setIsDeleting(false); 
  }

  async function deleteRecord() {
    setIsDeleting(true);     // Start button spinner
    sendConfirmModal({
      heading: "Delete Intro Launch?",
      body: "Last chance - Are you sure you want to delete this record?",
      confirmText: "Delete",
      rejectText: "Cancel",
      confirmColor: "danger",
      confirm: confirmDelete,
      reject: () => setIsDeleting(false)
    })
  }

  async function updateIntroRecord() {
    setIsLoading(true);
    // Build up body for API Call
    const parsedFloat = !isNaN(parseFloat(introPrice)) ? parseFloat(introPrice) : intro.price;
    const data = {
      price: parsedFloat,
      launch: introLaunchType,
    }

    if(taxStatus !== intro.taxStatus) {
      data.taxStatus = taxStatus
    }
    const taxRates = taxList.map((e) => e.value)
    if(!isEqual(taxRates, intro.taxRates)) {
      data.taxRates = taxRates
    }

    const body = {
      club: club,
      type: "INTRO_LAUNCH",
      id: (introID !== "new" ? introID : introName),
      data: data,
    };

    const result = await apiRequest(
      RequestType.POST,
      {apiName: "flightline", path: "/update-item/", options: {"body": body}}
    )
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not update intro launch"
      })
    } else {
      await refreshIntroList()
      sendToast({
        status: NotificationStatus.Success,
        message: introID !== "new" ? "Intro launch updated successfully" : "Intro launch created successfully"
      })
      closeModal()
    }
  }

  useEffect(() => {
    if(!intro || !intro.SK) return;

    setIntroID(intro.SK)
    if (intro.SK !== "new") {
      setIntroPrice(("price" in intro) ? intro.price : 0);
      setIntroLaunchType(("launch" in intro) ? intro.launch : "");
      setTaxStatus(intro.taxStatus ?? "exempt");
      const taxRates = intro.taxRates ?? []
      setTaxList(taxRates.map((r) => 
        taxOptions.find((o) => o.value === r) ?? {label: "Unknown", value: r}
      ))
    } else {
      setIntroPrice(0);
      setIntroLaunchType(launches[0]?.SK ?? "");
      setTaxStatus("exempt")
    }

    setIsLoading(false);
    setIsDeleting(false);
  }, [intro, taxOptions, launches])


  const taxStatusChanged = useMemo(() => taxStatus !== intro.taxStatus, [taxStatus, intro])
  const taxRatesChanged = useMemo(() => {
    const taxRates = taxList.map((e) => e.value)
    const launchTaxRates = intro.taxRates ?? []
    return !isEqual(taxRates, launchTaxRates)
  }, [taxList, intro])

  const introUpdated = useMemo(() => {
    return ((!isNaN(parseFloat(introPrice)) && (parseFloat(introPrice) !== intro.price)) || 
    (introID === "new") || 
    (introLaunchType !== intro.launch)) ||
    taxStatusChanged || taxRatesChanged
  }, [intro, introID, introLaunchType, introPrice, taxStatusChanged, taxRatesChanged])

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormGroup className="d-flex flex-column gap-3" controlId="introInfo" size="lg">
          { introID === "new" && 
            <>
            <h4><b>WARNING:</b> Launch label cannot be changed once entered</h4>
            <InputGroup>
            <InputGroup.Text>Intro Launch label</InputGroup.Text>
            <FormControl
              type="text"
              value={introName}
              onChange={e => setIntroName(e.target.value)}
            />
            </InputGroup>
            </>
          }
          <InputGroup>
            <InputGroup.Text>Price per launch $</InputGroup.Text>
            <FormControl
              type="number"
              inputMode="decimal"
              as="input"
              value={introPrice}
              onChange={e => setIntroPrice(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Launch Type</InputGroup.Text>
            <FormControl as="select" value={introLaunchType} onChange={e => setIntroLaunchType(e.target.value)}>
            {launches.map((item, i) => 
              <option key={i} value={item.SK}>{item.SK}</option>
            )}
            </FormControl>
          </InputGroup>
          <ToggleButtonGroup  name="taxStatusCheck" className="flex-wrap" type="radio" value={taxStatus} onChange={(e) => setTaxStatus(e)}>
            <InputGroup.Text>Tax Status:</InputGroup.Text>
            <ToggleButton id="exempt" variant="outline-primary" name="taxStatusCheck" value="exempt">Tax Exempt</ToggleButton>
            <ToggleButton id="inclusive" variant="outline-primary" name="taxStatusCheck" value="inclusive">Tax Inclusive</ToggleButton>
          </ToggleButtonGroup>
          {taxStatus !== "exempt" && taxStatus !== undefined &&
            <InputGroup>
              <InputGroup.Text>Applicable Taxes:</InputGroup.Text>
              <MultiSelect
                className="flex-grow-1 flex-shrink-1 multi-select"
                disableSearch
                hasSelectAll={false}
                options={taxOptions}
                value={taxList}
                onChange={setTaxList}
                valueRenderer={taxSelectRenderer}
              />
            </InputGroup>
          }
        </FormGroup> 
        {introUpdated && 
          <>
            <hr></hr>
            <div className="fs-5 mb-2 fw-semibold">Confirm the following changes to intro launch: {introID}</div>
            <ol>
              {(!isNaN(parseFloat(introPrice)) && (parseFloat(introPrice)  !== intro.price)) && 
                  <li>Price changed to {(!isNaN(parseFloat(introPrice)) ? parseFloat(introPrice) : intro.price)}</li>}
              {(introID === "new") && <li>New intro launch record</li>}
              {(introLaunchType !== intro.launch) && <li>Launch Type changed to {introLaunchType}</li>}
              {(taxStatusChanged) && <li>Tax status changed to {taxStatus}</li>}
              {(taxRatesChanged) && <li>Applicable taxes changed to {taxSelectRenderer(taxList)}</li>}
            </ol>
          </>
        }
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-row justify-content-between w-100">
          <div>
            { (introID !== "new") &&
              <LoaderButton type="button" variant="danger" isLoading={isDeleting} onClick={deleteRecord}>
                Delete Launch
              </LoaderButton>
            }
          </div>
          <div className="d-flex flex-row gap-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <LoaderButton type="button" variant="primary" isLoading={isLoading} onClick={updateIntroRecord}>
              Save Changes
            </LoaderButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}