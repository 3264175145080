import { useEffect, useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { updateDefaultIntroStalePeriod } from "../libs/databaseAccess";

export default function IntroExpirationAdmin() {
  const {club, clubDefaultIntroStalePeriod, setClubDefaultIntroStalePeriod} = useAppContext()
  const {sendToast, sendAlert, clearAlerts} = useNotificationContext()

  const [introStaleDays, setIntroStaleDays] = useState(0)

  async function updateIntroStaleDays() {
    const response = await updateDefaultIntroStalePeriod(club, introStaleDays)
    if(response.statusCode !== 200 || (response.body && !response.body.status)) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: "Could not update default intro expiration period",
        autohide: false
      })
      setIntroStaleDays(clubDefaultIntroStalePeriod)
    } else {
      clearAlerts()
      setClubDefaultIntroStalePeriod(response.body.period)
      sendToast({
        status: NotificationStatus.Success,
        message: `Default intro expiration period updated`,
      })
    }
  }

  useEffect(() => {
    if(clubDefaultIntroStalePeriod != null){
      setIntroStaleDays(clubDefaultIntroStalePeriod)
    }
  }, [clubDefaultIntroStalePeriod])

  return (
    <section>
      <h4>Default Intro Expiration Period</h4>
      <Form.Group controlId="introStaleDays" className="d-flex flex-row gap-3">
        <InputGroup>
          <Form.Control
            type="number"
            value={introStaleDays}
            onChange={e => setIntroStaleDays(e.target.value)}
            />
          <InputGroup.Text>days</InputGroup.Text>
        </InputGroup>
        <Button type="submit" size="md" onClick={updateIntroStaleDays} className="text-nowrap">
          Set Expiration
        </Button>
      </Form.Group>
      <Form.Text className="d-block text-start">New intros will be set to expire in this many days by default</Form.Text>
    </section>
  )
}